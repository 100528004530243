import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  geography: any;
  geometry: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  timestamp: any;
  timestamptz: any;
  timetz: any;
  uuid: any;
};


export type AccountStatus = {
  __typename?: 'AccountStatus';
  completed: Scalars['Boolean'];
  id: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
};

export type AddBankAccountOutput = {
  __typename?: 'AddBankAccountOutput';
  availablePaymentMethods?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName: Scalars['String'];
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  last4: Scalars['String'];
};

export type AddCreditCardOutput = {
  __typename?: 'AddCreditCardOutput';
  brand: Scalars['String'];
  expMonth: Scalars['String'];
  expYear: Scalars['String'];
  id: Scalars['String'];
  last4: Scalars['String'];
};

export type AgreeToTermsOutput = {
  __typename?: 'AgreeToTermsOutput';
  agreed: Scalars['Boolean'];
};

export type BillingSourcesOutput = {
  __typename?: 'BillingSourcesOutput';
  bankAccounts?: Maybe<Array<Maybe<Scalars['jsonb']>>>;
  cards?: Maybe<Array<Maybe<Scalars['jsonb']>>>;
};

export type BookClassInput = {
  cardToken?: Maybe<Scalars['String']>;
  classDateTimeID: Scalars['uuid'];
  donationAmount?: Maybe<Scalars['Int']>;
  paymentMethodID?: Maybe<Scalars['String']>;
  savePaymentMethod?: Maybe<Scalars['Boolean']>;
};

export type BookClassIntentInput = {
  classDateTimeID: Scalars['uuid'];
  classEventID: Scalars['uuid'];
};

export type BookClassIntentOutput = {
  __typename?: 'BookClassIntentOutput';
  clientSecret: Scalars['String'];
  intentID: Scalars['String'];
};

export type BookClassOutput = {
  __typename?: 'BookClassOutput';
  id: Scalars['String'];
  paymentStatus: Scalars['String'];
};

export type BookClassUpdateIntentInput = {
  amount: Scalars['Int'];
  intentID: Scalars['String'];
};

export type BookClassUpdateIntentOutput = {
  __typename?: 'BookClassUpdateIntentOutput';
  clientSecret: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CancelBookOutput = {
  __typename?: 'CancelBookOutput';
  message?: Maybe<Scalars['String']>;
};

export type ClassAttending = {
  __typename?: 'ClassAttending';
  total: Scalars['Int'];
};

export type ClassEventByWebIdOutput = {
  __typename?: 'ClassEventByWebIDOutput';
  class_name: Scalars['String'];
  end_timestamp: Scalars['String'];
  meeting_link?: Maybe<Scalars['String']>;
  start_timestamp: Scalars['String'];
  teacher_name: Scalars['String'];
};

export type CompanyAddress = {
  city: Scalars['String'];
  primary: Scalars['String'];
  secondary?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CreateCompanyInfoOutput = {
  __typename?: 'CreateCompanyInfoOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateIndividualAccountOutput = {
  __typename?: 'CreateIndividualAccountOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteBillingSourceOutput = {
  __typename?: 'DeleteBillingSourceOutput';
  id: Scalars['String'];
};

export type FindLocationOutput = {
  __typename?: 'FindLocationOutput';
  description: Scalars['String'];
  placeID: Scalars['String'];
  terms?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IndividualAddress = {
  city: Scalars['String'];
  primary: Scalars['String'];
  secondary?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type LegalInformationOutput = {
  __typename?: 'LegalInformationOutput';
  address?: Maybe<Scalars['jsonb']>;
  company?: Maybe<Scalars['jsonb']>;
  individual?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  expiresIn: Scalars['Int'];
};

export type LoginVerifyOutput = {
  __typename?: 'LoginVerifyOutput';
  account: Scalars['jsonb'];
  expiresAt: Scalars['Int'];
  token: Scalars['String'];
};

export type PlaceCoordinateOutput = {
  __typename?: 'PlaceCoordinateOutput';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type PublicProfile = {
  __typename?: 'PublicProfile';
  about?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  certifications?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalLinks?: Maybe<Array<Maybe<Scalars['jsonb']>>>;
  id: Scalars['uuid'];
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  socialMedia?: Maybe<Array<Maybe<Scalars['jsonb']>>>;
  teaches?: Maybe<Array<Maybe<Scalars['String']>>>;
  teachingHours?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type SearchSellerOutput = {
  __typename?: 'SearchSellerOutput';
  success: Scalars['Boolean'];
};

export type SignupPreferences = {
  levels: Scalars['String'];
  settings: Scalars['String'];
  yogaTypes: Scalars['String'];
};

export type SignupStudentOutput = {
  __typename?: 'SignupStudentOutput';
  account: Scalars['jsonb'];
  expiresAt: Scalars['Int'];
  token: Scalars['String'];
};

export type SignupStudentProfile = {
  birthday?: Maybe<Scalars['date']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
};

export type SignupStudioOutput = {
  __typename?: 'SignupStudioOutput';
  account: Scalars['jsonb'];
  expiresAt: Scalars['Int'];
  token: Scalars['String'];
};

export type SignupStudioProfile = {
  bio?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
};

export type SignupTeacherOutput = {
  __typename?: 'SignupTeacherOutput';
  account: Scalars['jsonb'];
  expiresAt: Scalars['Int'];
  token: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type Terms = {
  __typename?: 'Terms';
  agreed: Scalars['Boolean'];
};

export type UpdateClassEventMeetingLinkByWebIdOutput = {
  __typename?: 'UpdateClassEventMeetingLinkByWebIDOutput';
  id: Scalars['uuid'];
};

export type UpdateDefaultSourceOutput = {
  __typename?: 'UpdateDefaultSourceOutput';
  id: Scalars['String'];
};

export type UploadInsuranceDocumentOutput = {
  __typename?: 'UploadInsuranceDocumentOutput';
  expires_at?: Maybe<Scalars['timestamp']>;
  file_url: Scalars['String'];
  id: Scalars['uuid'];
  invalid_reason?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  verified: Scalars['Boolean'];
};

export type UploadPictureOutput = {
  __typename?: 'UploadPictureOutput';
  url: Scalars['String'];
};

/** columns and relationships of "account" */
export type Account = {
  __typename?: 'account';
  /** An array relationship */
  account_certifications: Array<Account_Certification>;
  /** An aggregate relationship */
  account_certifications_aggregate: Account_Certification_Aggregate;
  /** An array relationship */
  account_links: Array<Account_Link>;
  /** An aggregate relationship */
  account_links_aggregate: Account_Link_Aggregate;
  /** An array relationship */
  account_social_media: Array<Account_Social_Media>;
  /** An aggregate relationship */
  account_social_media_aggregate: Account_Social_Media_Aggregate;
  /** An array relationship */
  account_yoga_types: Array<Account_Yoga_Type>;
  /** An aggregate relationship */
  account_yoga_types_aggregate: Account_Yoga_Type_Aggregate;
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  entity?: Maybe<Profile_Entity>;
  extra?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  invalid_reason?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  /** An object relationship */
  person?: Maybe<Profile_Person>;
  /** An object relationship */
  preferences?: Maybe<Preferences>;
  push_token?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  studio_pictures: Array<Studio_Picture>;
  /** An aggregate relationship */
  studio_pictures_aggregate: Studio_Picture_Aggregate;
  terms_accepted: Scalars['Boolean'];
  timezone?: Maybe<Scalars['String']>;
  type: Account_Role_Enum;
  updated_at: Scalars['timestamptz'];
  username?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};


/** columns and relationships of "account" */
export type AccountAccount_CertificationsArgs = {
  distinct_on?: Maybe<Array<Account_Certification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Certification_Order_By>>;
  where?: Maybe<Account_Certification_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountAccount_Certifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Certification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Certification_Order_By>>;
  where?: Maybe<Account_Certification_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountAccount_LinksArgs = {
  distinct_on?: Maybe<Array<Account_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Link_Order_By>>;
  where?: Maybe<Account_Link_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountAccount_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Link_Order_By>>;
  where?: Maybe<Account_Link_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountAccount_Social_MediaArgs = {
  distinct_on?: Maybe<Array<Account_Social_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Social_Media_Order_By>>;
  where?: Maybe<Account_Social_Media_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountAccount_Social_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Social_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Social_Media_Order_By>>;
  where?: Maybe<Account_Social_Media_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountAccount_Yoga_TypesArgs = {
  distinct_on?: Maybe<Array<Account_Yoga_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Yoga_Type_Order_By>>;
  where?: Maybe<Account_Yoga_Type_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountAccount_Yoga_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Yoga_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Yoga_Type_Order_By>>;
  where?: Maybe<Account_Yoga_Type_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountExtraArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "account" */
export type AccountStudio_PicturesArgs = {
  distinct_on?: Maybe<Array<Studio_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Studio_Picture_Order_By>>;
  where?: Maybe<Studio_Picture_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountStudio_Pictures_AggregateArgs = {
  distinct_on?: Maybe<Array<Studio_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Studio_Picture_Order_By>>;
  where?: Maybe<Studio_Picture_Bool_Exp>;
};

/** aggregated selection of "account" */
export type Account_Aggregate = {
  __typename?: 'account_aggregate';
  aggregate?: Maybe<Account_Aggregate_Fields>;
  nodes: Array<Account>;
};

/** aggregate fields of "account" */
export type Account_Aggregate_Fields = {
  __typename?: 'account_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Max_Fields>;
  min?: Maybe<Account_Min_Fields>;
};


/** aggregate fields of "account" */
export type Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Account_Append_Input = {
  extra?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export type Account_Bool_Exp = {
  _and?: Maybe<Array<Account_Bool_Exp>>;
  _not?: Maybe<Account_Bool_Exp>;
  _or?: Maybe<Array<Account_Bool_Exp>>;
  account_certifications?: Maybe<Account_Certification_Bool_Exp>;
  account_links?: Maybe<Account_Link_Bool_Exp>;
  account_social_media?: Maybe<Account_Social_Media_Bool_Exp>;
  account_yoga_types?: Maybe<Account_Yoga_Type_Bool_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  entity?: Maybe<Profile_Entity_Bool_Exp>;
  extra?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invalid_reason?: Maybe<String_Comparison_Exp>;
  languages?: Maybe<String_Comparison_Exp>;
  person?: Maybe<Profile_Person_Bool_Exp>;
  preferences?: Maybe<Preferences_Bool_Exp>;
  push_token?: Maybe<String_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  stripe_account_id?: Maybe<String_Comparison_Exp>;
  stripe_customer_id?: Maybe<String_Comparison_Exp>;
  studio_pictures?: Maybe<Studio_Picture_Bool_Exp>;
  terms_accepted?: Maybe<Boolean_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Account_Role_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  username?: Maybe<String_Comparison_Exp>;
  verified?: Maybe<Boolean_Comparison_Exp>;
};

/** columns and relationships of "account_certification" */
export type Account_Certification = {
  __typename?: 'account_certification';
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "account_certification" */
export type Account_Certification_Aggregate = {
  __typename?: 'account_certification_aggregate';
  aggregate?: Maybe<Account_Certification_Aggregate_Fields>;
  nodes: Array<Account_Certification>;
};

/** aggregate fields of "account_certification" */
export type Account_Certification_Aggregate_Fields = {
  __typename?: 'account_certification_aggregate_fields';
  avg?: Maybe<Account_Certification_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Certification_Max_Fields>;
  min?: Maybe<Account_Certification_Min_Fields>;
  stddev?: Maybe<Account_Certification_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Certification_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Certification_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Certification_Sum_Fields>;
  var_pop?: Maybe<Account_Certification_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Certification_Var_Samp_Fields>;
  variance?: Maybe<Account_Certification_Variance_Fields>;
};


/** aggregate fields of "account_certification" */
export type Account_Certification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Certification_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_certification" */
export type Account_Certification_Aggregate_Order_By = {
  avg?: Maybe<Account_Certification_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Account_Certification_Max_Order_By>;
  min?: Maybe<Account_Certification_Min_Order_By>;
  stddev?: Maybe<Account_Certification_Stddev_Order_By>;
  stddev_pop?: Maybe<Account_Certification_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Account_Certification_Stddev_Samp_Order_By>;
  sum?: Maybe<Account_Certification_Sum_Order_By>;
  var_pop?: Maybe<Account_Certification_Var_Pop_Order_By>;
  var_samp?: Maybe<Account_Certification_Var_Samp_Order_By>;
  variance?: Maybe<Account_Certification_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "account_certification" */
export type Account_Certification_Arr_Rel_Insert_Input = {
  data: Array<Account_Certification_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Account_Certification_On_Conflict>;
};

/** aggregate avg on columns */
export type Account_Certification_Avg_Fields = {
  __typename?: 'account_certification_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account_certification" */
export type Account_Certification_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "account_certification". All fields are combined with a logical 'AND'. */
export type Account_Certification_Bool_Exp = {
  _and?: Maybe<Array<Account_Certification_Bool_Exp>>;
  _not?: Maybe<Account_Certification_Bool_Exp>;
  _or?: Maybe<Array<Account_Certification_Bool_Exp>>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_certification" */
export enum Account_Certification_Constraint {
  /** unique or primary key constraint */
  AccountCertificationAccountIdNameKey = 'account_certification_account_id_name_key',
  /** unique or primary key constraint */
  AccountCertificationPkey = 'account_certification_pkey'
}

/** input type for incrementing numeric columns in table "account_certification" */
export type Account_Certification_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "account_certification" */
export type Account_Certification_Insert_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Account_Certification_Max_Fields = {
  __typename?: 'account_certification_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "account_certification" */
export type Account_Certification_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Certification_Min_Fields = {
  __typename?: 'account_certification_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "account_certification" */
export type Account_Certification_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "account_certification" */
export type Account_Certification_Mutation_Response = {
  __typename?: 'account_certification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Certification>;
};

/** on conflict condition type for table "account_certification" */
export type Account_Certification_On_Conflict = {
  constraint: Account_Certification_Constraint;
  update_columns?: Array<Account_Certification_Update_Column>;
  where?: Maybe<Account_Certification_Bool_Exp>;
};

/** Ordering options when selecting data from "account_certification". */
export type Account_Certification_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: account_certification */
export type Account_Certification_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "account_certification" */
export enum Account_Certification_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account_certification" */
export type Account_Certification_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Account_Certification_Stddev_Fields = {
  __typename?: 'account_certification_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account_certification" */
export type Account_Certification_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Account_Certification_Stddev_Pop_Fields = {
  __typename?: 'account_certification_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account_certification" */
export type Account_Certification_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Account_Certification_Stddev_Samp_Fields = {
  __typename?: 'account_certification_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account_certification" */
export type Account_Certification_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Account_Certification_Sum_Fields = {
  __typename?: 'account_certification_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "account_certification" */
export type Account_Certification_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "account_certification" */
export enum Account_Certification_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Account_Certification_Var_Pop_Fields = {
  __typename?: 'account_certification_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account_certification" */
export type Account_Certification_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Account_Certification_Var_Samp_Fields = {
  __typename?: 'account_certification_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account_certification" */
export type Account_Certification_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Account_Certification_Variance_Fields = {
  __typename?: 'account_certification_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account_certification" */
export type Account_Certification_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "account" */
export enum Account_Constraint {
  /** unique or primary key constraint */
  AccountCustomerIdKey = 'account_customer_id_key',
  /** unique or primary key constraint */
  AccountPkey = 'account_pkey',
  /** unique or primary key constraint */
  AccountStripeAccountIdKey = 'account_stripe_account_id_key',
  /** unique or primary key constraint */
  AccountUsernameKey = 'account_username_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Account_Delete_At_Path_Input = {
  extra?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Account_Delete_Elem_Input = {
  extra?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Account_Delete_Key_Input = {
  extra?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "account" */
export type Account_Insert_Input = {
  account_certifications?: Maybe<Account_Certification_Arr_Rel_Insert_Input>;
  account_links?: Maybe<Account_Link_Arr_Rel_Insert_Input>;
  account_social_media?: Maybe<Account_Social_Media_Arr_Rel_Insert_Input>;
  account_yoga_types?: Maybe<Account_Yoga_Type_Arr_Rel_Insert_Input>;
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  entity?: Maybe<Profile_Entity_Obj_Rel_Insert_Input>;
  extra?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  invalid_reason?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  person?: Maybe<Profile_Person_Obj_Rel_Insert_Input>;
  preferences?: Maybe<Preferences_Obj_Rel_Insert_Input>;
  push_token?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  studio_pictures?: Maybe<Studio_Picture_Arr_Rel_Insert_Input>;
  terms_accepted?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Account_Role_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "account_link" */
export type Account_Link = {
  __typename?: 'account_link';
  account_id: Scalars['uuid'];
  id: Scalars['bigint'];
  text: Scalars['String'];
  url: Scalars['String'];
};

/** aggregated selection of "account_link" */
export type Account_Link_Aggregate = {
  __typename?: 'account_link_aggregate';
  aggregate?: Maybe<Account_Link_Aggregate_Fields>;
  nodes: Array<Account_Link>;
};

/** aggregate fields of "account_link" */
export type Account_Link_Aggregate_Fields = {
  __typename?: 'account_link_aggregate_fields';
  avg?: Maybe<Account_Link_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Link_Max_Fields>;
  min?: Maybe<Account_Link_Min_Fields>;
  stddev?: Maybe<Account_Link_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Link_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Link_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Link_Sum_Fields>;
  var_pop?: Maybe<Account_Link_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Link_Var_Samp_Fields>;
  variance?: Maybe<Account_Link_Variance_Fields>;
};


/** aggregate fields of "account_link" */
export type Account_Link_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Link_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_link" */
export type Account_Link_Aggregate_Order_By = {
  avg?: Maybe<Account_Link_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Account_Link_Max_Order_By>;
  min?: Maybe<Account_Link_Min_Order_By>;
  stddev?: Maybe<Account_Link_Stddev_Order_By>;
  stddev_pop?: Maybe<Account_Link_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Account_Link_Stddev_Samp_Order_By>;
  sum?: Maybe<Account_Link_Sum_Order_By>;
  var_pop?: Maybe<Account_Link_Var_Pop_Order_By>;
  var_samp?: Maybe<Account_Link_Var_Samp_Order_By>;
  variance?: Maybe<Account_Link_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "account_link" */
export type Account_Link_Arr_Rel_Insert_Input = {
  data: Array<Account_Link_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Account_Link_On_Conflict>;
};

/** aggregate avg on columns */
export type Account_Link_Avg_Fields = {
  __typename?: 'account_link_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account_link" */
export type Account_Link_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "account_link". All fields are combined with a logical 'AND'. */
export type Account_Link_Bool_Exp = {
  _and?: Maybe<Array<Account_Link_Bool_Exp>>;
  _not?: Maybe<Account_Link_Bool_Exp>;
  _or?: Maybe<Array<Account_Link_Bool_Exp>>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_link" */
export enum Account_Link_Constraint {
  /** unique or primary key constraint */
  AccountLinkPkey = 'account_link_pkey'
}

/** input type for incrementing numeric columns in table "account_link" */
export type Account_Link_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "account_link" */
export type Account_Link_Insert_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Account_Link_Max_Fields = {
  __typename?: 'account_link_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "account_link" */
export type Account_Link_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Link_Min_Fields = {
  __typename?: 'account_link_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "account_link" */
export type Account_Link_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "account_link" */
export type Account_Link_Mutation_Response = {
  __typename?: 'account_link_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Link>;
};

/** on conflict condition type for table "account_link" */
export type Account_Link_On_Conflict = {
  constraint: Account_Link_Constraint;
  update_columns?: Array<Account_Link_Update_Column>;
  where?: Maybe<Account_Link_Bool_Exp>;
};

/** Ordering options when selecting data from "account_link". */
export type Account_Link_Order_By = {
  account_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: account_link */
export type Account_Link_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "account_link" */
export enum Account_Link_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "account_link" */
export type Account_Link_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Account_Link_Stddev_Fields = {
  __typename?: 'account_link_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account_link" */
export type Account_Link_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Account_Link_Stddev_Pop_Fields = {
  __typename?: 'account_link_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account_link" */
export type Account_Link_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Account_Link_Stddev_Samp_Fields = {
  __typename?: 'account_link_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account_link" */
export type Account_Link_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Account_Link_Sum_Fields = {
  __typename?: 'account_link_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "account_link" */
export type Account_Link_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "account_link" */
export enum Account_Link_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type Account_Link_Var_Pop_Fields = {
  __typename?: 'account_link_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account_link" */
export type Account_Link_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Account_Link_Var_Samp_Fields = {
  __typename?: 'account_link_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account_link" */
export type Account_Link_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Account_Link_Variance_Fields = {
  __typename?: 'account_link_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account_link" */
export type Account_Link_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Account_Max_Fields = {
  __typename?: 'account_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invalid_reason?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  push_token?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Account_Min_Fields = {
  __typename?: 'account_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invalid_reason?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  push_token?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "account" */
export type Account_Mutation_Response = {
  __typename?: 'account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account>;
};

/** input type for inserting object relation for remote table "account" */
export type Account_Obj_Rel_Insert_Input = {
  data: Account_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Account_On_Conflict>;
};

/** on conflict condition type for table "account" */
export type Account_On_Conflict = {
  constraint: Account_Constraint;
  update_columns?: Array<Account_Update_Column>;
  where?: Maybe<Account_Bool_Exp>;
};

/** Ordering options when selecting data from "account". */
export type Account_Order_By = {
  account_certifications_aggregate?: Maybe<Account_Certification_Aggregate_Order_By>;
  account_links_aggregate?: Maybe<Account_Link_Aggregate_Order_By>;
  account_social_media_aggregate?: Maybe<Account_Social_Media_Aggregate_Order_By>;
  account_yoga_types_aggregate?: Maybe<Account_Yoga_Type_Aggregate_Order_By>;
  active?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  entity?: Maybe<Profile_Entity_Order_By>;
  extra?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invalid_reason?: Maybe<Order_By>;
  languages?: Maybe<Order_By>;
  person?: Maybe<Profile_Person_Order_By>;
  preferences?: Maybe<Preferences_Order_By>;
  push_token?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  stripe_account_id?: Maybe<Order_By>;
  stripe_customer_id?: Maybe<Order_By>;
  studio_pictures_aggregate?: Maybe<Studio_Picture_Aggregate_Order_By>;
  terms_accepted?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
  verified?: Maybe<Order_By>;
};

/** primary key columns input for table: account */
export type Account_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Account_Prepend_Input = {
  extra?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "account_role" */
export type Account_Role = {
  __typename?: 'account_role';
  value: Scalars['String'];
};

/** aggregated selection of "account_role" */
export type Account_Role_Aggregate = {
  __typename?: 'account_role_aggregate';
  aggregate?: Maybe<Account_Role_Aggregate_Fields>;
  nodes: Array<Account_Role>;
};

/** aggregate fields of "account_role" */
export type Account_Role_Aggregate_Fields = {
  __typename?: 'account_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Role_Max_Fields>;
  min?: Maybe<Account_Role_Min_Fields>;
};


/** aggregate fields of "account_role" */
export type Account_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "account_role". All fields are combined with a logical 'AND'. */
export type Account_Role_Bool_Exp = {
  _and?: Maybe<Array<Account_Role_Bool_Exp>>;
  _not?: Maybe<Account_Role_Bool_Exp>;
  _or?: Maybe<Array<Account_Role_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_role" */
export enum Account_Role_Constraint {
  /** unique or primary key constraint */
  AccountRolePkey = 'account_role_pkey'
}

export enum Account_Role_Enum {
  Anonymous = 'anonymous',
  Controller = 'controller',
  Student = 'student',
  Studio = 'studio',
  Teacher = 'teacher',
  Unknown = 'unknown'
}

/** Boolean expression to compare columns of type "account_role_enum". All fields are combined with logical 'AND'. */
export type Account_Role_Enum_Comparison_Exp = {
  _eq?: Maybe<Account_Role_Enum>;
  _in?: Maybe<Array<Account_Role_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Account_Role_Enum>;
  _nin?: Maybe<Array<Account_Role_Enum>>;
};

/** input type for inserting data into table "account_role" */
export type Account_Role_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Account_Role_Max_Fields = {
  __typename?: 'account_role_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Account_Role_Min_Fields = {
  __typename?: 'account_role_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "account_role" */
export type Account_Role_Mutation_Response = {
  __typename?: 'account_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Role>;
};

/** on conflict condition type for table "account_role" */
export type Account_Role_On_Conflict = {
  constraint: Account_Role_Constraint;
  update_columns?: Array<Account_Role_Update_Column>;
  where?: Maybe<Account_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "account_role". */
export type Account_Role_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: account_role */
export type Account_Role_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "account_role" */
export enum Account_Role_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "account_role" */
export type Account_Role_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "account_role" */
export enum Account_Role_Update_Column {
  /** column name */
  Value = 'value'
}

/** select columns of table "account" */
export enum Account_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Extra = 'extra',
  /** column name */
  Id = 'id',
  /** column name */
  InvalidReason = 'invalid_reason',
  /** column name */
  Languages = 'languages',
  /** column name */
  PushToken = 'push_token',
  /** column name */
  Source = 'source',
  /** column name */
  StripeAccountId = 'stripe_account_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  TermsAccepted = 'terms_accepted',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "account" */
export type Account_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  extra?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  invalid_reason?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  push_token?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  stripe_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  terms_accepted?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['String']>;
  type?: Maybe<Account_Role_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "account_social_media" */
export type Account_Social_Media = {
  __typename?: 'account_social_media';
  account_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['bigint'];
  type: Social_Media_Type_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "account_social_media" */
export type Account_Social_Media_Aggregate = {
  __typename?: 'account_social_media_aggregate';
  aggregate?: Maybe<Account_Social_Media_Aggregate_Fields>;
  nodes: Array<Account_Social_Media>;
};

/** aggregate fields of "account_social_media" */
export type Account_Social_Media_Aggregate_Fields = {
  __typename?: 'account_social_media_aggregate_fields';
  avg?: Maybe<Account_Social_Media_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Social_Media_Max_Fields>;
  min?: Maybe<Account_Social_Media_Min_Fields>;
  stddev?: Maybe<Account_Social_Media_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Social_Media_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Social_Media_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Social_Media_Sum_Fields>;
  var_pop?: Maybe<Account_Social_Media_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Social_Media_Var_Samp_Fields>;
  variance?: Maybe<Account_Social_Media_Variance_Fields>;
};


/** aggregate fields of "account_social_media" */
export type Account_Social_Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Social_Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_social_media" */
export type Account_Social_Media_Aggregate_Order_By = {
  avg?: Maybe<Account_Social_Media_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Account_Social_Media_Max_Order_By>;
  min?: Maybe<Account_Social_Media_Min_Order_By>;
  stddev?: Maybe<Account_Social_Media_Stddev_Order_By>;
  stddev_pop?: Maybe<Account_Social_Media_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Account_Social_Media_Stddev_Samp_Order_By>;
  sum?: Maybe<Account_Social_Media_Sum_Order_By>;
  var_pop?: Maybe<Account_Social_Media_Var_Pop_Order_By>;
  var_samp?: Maybe<Account_Social_Media_Var_Samp_Order_By>;
  variance?: Maybe<Account_Social_Media_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "account_social_media" */
export type Account_Social_Media_Arr_Rel_Insert_Input = {
  data: Array<Account_Social_Media_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Account_Social_Media_On_Conflict>;
};

/** aggregate avg on columns */
export type Account_Social_Media_Avg_Fields = {
  __typename?: 'account_social_media_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account_social_media" */
export type Account_Social_Media_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "account_social_media". All fields are combined with a logical 'AND'. */
export type Account_Social_Media_Bool_Exp = {
  _and?: Maybe<Array<Account_Social_Media_Bool_Exp>>;
  _not?: Maybe<Account_Social_Media_Bool_Exp>;
  _or?: Maybe<Array<Account_Social_Media_Bool_Exp>>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<Social_Media_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_social_media" */
export enum Account_Social_Media_Constraint {
  /** unique or primary key constraint */
  AccountSocialMediaAccountIdTypeKey = 'account_social_media_account_id_type_key',
  /** unique or primary key constraint */
  AccountSocialMediaPkey = 'account_social_media_pkey'
}

/** input type for incrementing numeric columns in table "account_social_media" */
export type Account_Social_Media_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "account_social_media" */
export type Account_Social_Media_Insert_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Social_Media_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Account_Social_Media_Max_Fields = {
  __typename?: 'account_social_media_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "account_social_media" */
export type Account_Social_Media_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Social_Media_Min_Fields = {
  __typename?: 'account_social_media_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "account_social_media" */
export type Account_Social_Media_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "account_social_media" */
export type Account_Social_Media_Mutation_Response = {
  __typename?: 'account_social_media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Social_Media>;
};

/** on conflict condition type for table "account_social_media" */
export type Account_Social_Media_On_Conflict = {
  constraint: Account_Social_Media_Constraint;
  update_columns?: Array<Account_Social_Media_Update_Column>;
  where?: Maybe<Account_Social_Media_Bool_Exp>;
};

/** Ordering options when selecting data from "account_social_media". */
export type Account_Social_Media_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: account_social_media */
export type Account_Social_Media_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "account_social_media" */
export enum Account_Social_Media_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "account_social_media" */
export type Account_Social_Media_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Social_Media_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Account_Social_Media_Stddev_Fields = {
  __typename?: 'account_social_media_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account_social_media" */
export type Account_Social_Media_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Account_Social_Media_Stddev_Pop_Fields = {
  __typename?: 'account_social_media_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account_social_media" */
export type Account_Social_Media_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Account_Social_Media_Stddev_Samp_Fields = {
  __typename?: 'account_social_media_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account_social_media" */
export type Account_Social_Media_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Account_Social_Media_Sum_Fields = {
  __typename?: 'account_social_media_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "account_social_media" */
export type Account_Social_Media_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "account_social_media" */
export enum Account_Social_Media_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type Account_Social_Media_Var_Pop_Fields = {
  __typename?: 'account_social_media_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account_social_media" */
export type Account_Social_Media_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Account_Social_Media_Var_Samp_Fields = {
  __typename?: 'account_social_media_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account_social_media" */
export type Account_Social_Media_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Account_Social_Media_Variance_Fields = {
  __typename?: 'account_social_media_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account_social_media" */
export type Account_Social_Media_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "account" */
export enum Account_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Extra = 'extra',
  /** column name */
  Id = 'id',
  /** column name */
  InvalidReason = 'invalid_reason',
  /** column name */
  Languages = 'languages',
  /** column name */
  PushToken = 'push_token',
  /** column name */
  Source = 'source',
  /** column name */
  StripeAccountId = 'stripe_account_id',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  TermsAccepted = 'terms_accepted',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username',
  /** column name */
  Verified = 'verified'
}

/** columns and relationships of "account_yoga_type" */
export type Account_Yoga_Type = {
  __typename?: 'account_yoga_type';
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  fallback?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  type: Yoga_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "account_yoga_type" */
export type Account_Yoga_Type_Aggregate = {
  __typename?: 'account_yoga_type_aggregate';
  aggregate?: Maybe<Account_Yoga_Type_Aggregate_Fields>;
  nodes: Array<Account_Yoga_Type>;
};

/** aggregate fields of "account_yoga_type" */
export type Account_Yoga_Type_Aggregate_Fields = {
  __typename?: 'account_yoga_type_aggregate_fields';
  avg?: Maybe<Account_Yoga_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Yoga_Type_Max_Fields>;
  min?: Maybe<Account_Yoga_Type_Min_Fields>;
  stddev?: Maybe<Account_Yoga_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Yoga_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Yoga_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Yoga_Type_Sum_Fields>;
  var_pop?: Maybe<Account_Yoga_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Yoga_Type_Var_Samp_Fields>;
  variance?: Maybe<Account_Yoga_Type_Variance_Fields>;
};


/** aggregate fields of "account_yoga_type" */
export type Account_Yoga_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Yoga_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_yoga_type" */
export type Account_Yoga_Type_Aggregate_Order_By = {
  avg?: Maybe<Account_Yoga_Type_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Account_Yoga_Type_Max_Order_By>;
  min?: Maybe<Account_Yoga_Type_Min_Order_By>;
  stddev?: Maybe<Account_Yoga_Type_Stddev_Order_By>;
  stddev_pop?: Maybe<Account_Yoga_Type_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Account_Yoga_Type_Stddev_Samp_Order_By>;
  sum?: Maybe<Account_Yoga_Type_Sum_Order_By>;
  var_pop?: Maybe<Account_Yoga_Type_Var_Pop_Order_By>;
  var_samp?: Maybe<Account_Yoga_Type_Var_Samp_Order_By>;
  variance?: Maybe<Account_Yoga_Type_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "account_yoga_type" */
export type Account_Yoga_Type_Arr_Rel_Insert_Input = {
  data: Array<Account_Yoga_Type_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Account_Yoga_Type_On_Conflict>;
};

/** aggregate avg on columns */
export type Account_Yoga_Type_Avg_Fields = {
  __typename?: 'account_yoga_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "account_yoga_type" */
export type Account_Yoga_Type_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "account_yoga_type". All fields are combined with a logical 'AND'. */
export type Account_Yoga_Type_Bool_Exp = {
  _and?: Maybe<Array<Account_Yoga_Type_Bool_Exp>>;
  _not?: Maybe<Account_Yoga_Type_Bool_Exp>;
  _or?: Maybe<Array<Account_Yoga_Type_Bool_Exp>>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  fallback?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<Yoga_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_yoga_type" */
export enum Account_Yoga_Type_Constraint {
  /** unique or primary key constraint */
  AccountYogaTypePkey = 'account_yoga_type_pkey'
}

/** input type for incrementing numeric columns in table "account_yoga_type" */
export type Account_Yoga_Type_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "account_yoga_type" */
export type Account_Yoga_Type_Insert_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fallback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Yoga_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Account_Yoga_Type_Max_Fields = {
  __typename?: 'account_yoga_type_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fallback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "account_yoga_type" */
export type Account_Yoga_Type_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  fallback?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Yoga_Type_Min_Fields = {
  __typename?: 'account_yoga_type_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fallback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "account_yoga_type" */
export type Account_Yoga_Type_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  fallback?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "account_yoga_type" */
export type Account_Yoga_Type_Mutation_Response = {
  __typename?: 'account_yoga_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Yoga_Type>;
};

/** on conflict condition type for table "account_yoga_type" */
export type Account_Yoga_Type_On_Conflict = {
  constraint: Account_Yoga_Type_Constraint;
  update_columns?: Array<Account_Yoga_Type_Update_Column>;
  where?: Maybe<Account_Yoga_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "account_yoga_type". */
export type Account_Yoga_Type_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  fallback?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: account_yoga_type */
export type Account_Yoga_Type_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "account_yoga_type" */
export enum Account_Yoga_Type_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fallback = 'fallback',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account_yoga_type" */
export type Account_Yoga_Type_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fallback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Yoga_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Account_Yoga_Type_Stddev_Fields = {
  __typename?: 'account_yoga_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "account_yoga_type" */
export type Account_Yoga_Type_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Account_Yoga_Type_Stddev_Pop_Fields = {
  __typename?: 'account_yoga_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "account_yoga_type" */
export type Account_Yoga_Type_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Account_Yoga_Type_Stddev_Samp_Fields = {
  __typename?: 'account_yoga_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "account_yoga_type" */
export type Account_Yoga_Type_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Account_Yoga_Type_Sum_Fields = {
  __typename?: 'account_yoga_type_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "account_yoga_type" */
export type Account_Yoga_Type_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "account_yoga_type" */
export enum Account_Yoga_Type_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Fallback = 'fallback',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Account_Yoga_Type_Var_Pop_Fields = {
  __typename?: 'account_yoga_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "account_yoga_type" */
export type Account_Yoga_Type_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Account_Yoga_Type_Var_Samp_Fields = {
  __typename?: 'account_yoga_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "account_yoga_type" */
export type Account_Yoga_Type_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Account_Yoga_Type_Variance_Fields = {
  __typename?: 'account_yoga_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "account_yoga_type" */
export type Account_Yoga_Type_Variance_Order_By = {
  id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "blog" */
export type Blog = {
  __typename?: 'blog';
  authorName: Scalars['String'];
  authorPicture?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  external_id: Scalars['String'];
  id: Scalars['uuid'];
  minsToRead: Scalars['numeric'];
  not_published_reason?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  published_at: Scalars['timestamptz'];
  source: Scalars['String'];
  title: Scalars['String'];
  type: Blog_Type_Enum;
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
  verified: Scalars['Boolean'];
};

/** aggregated selection of "blog" */
export type Blog_Aggregate = {
  __typename?: 'blog_aggregate';
  aggregate?: Maybe<Blog_Aggregate_Fields>;
  nodes: Array<Blog>;
};

/** aggregate fields of "blog" */
export type Blog_Aggregate_Fields = {
  __typename?: 'blog_aggregate_fields';
  avg?: Maybe<Blog_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Blog_Max_Fields>;
  min?: Maybe<Blog_Min_Fields>;
  stddev?: Maybe<Blog_Stddev_Fields>;
  stddev_pop?: Maybe<Blog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blog_Stddev_Samp_Fields>;
  sum?: Maybe<Blog_Sum_Fields>;
  var_pop?: Maybe<Blog_Var_Pop_Fields>;
  var_samp?: Maybe<Blog_Var_Samp_Fields>;
  variance?: Maybe<Blog_Variance_Fields>;
};


/** aggregate fields of "blog" */
export type Blog_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Blog_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Blog_Avg_Fields = {
  __typename?: 'blog_avg_fields';
  minsToRead?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "blog". All fields are combined with a logical 'AND'. */
export type Blog_Bool_Exp = {
  _and?: Maybe<Array<Blog_Bool_Exp>>;
  _not?: Maybe<Blog_Bool_Exp>;
  _or?: Maybe<Array<Blog_Bool_Exp>>;
  authorName?: Maybe<String_Comparison_Exp>;
  authorPicture?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  minsToRead?: Maybe<Numeric_Comparison_Exp>;
  not_published_reason?: Maybe<String_Comparison_Exp>;
  picture?: Maybe<String_Comparison_Exp>;
  published?: Maybe<Boolean_Comparison_Exp>;
  published_at?: Maybe<Timestamptz_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Blog_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
  verified?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "blog" */
export enum Blog_Constraint {
  /** unique or primary key constraint */
  BlogExternalIdKey = 'blog_external_id_key',
  /** unique or primary key constraint */
  BlogPkey = 'blog_pkey'
}

/** input type for incrementing numeric columns in table "blog" */
export type Blog_Inc_Input = {
  minsToRead?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "blog" */
export type Blog_Insert_Input = {
  authorName?: Maybe<Scalars['String']>;
  authorPicture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  minsToRead?: Maybe<Scalars['numeric']>;
  not_published_reason?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Blog_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Blog_Max_Fields = {
  __typename?: 'blog_max_fields';
  authorName?: Maybe<Scalars['String']>;
  authorPicture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  minsToRead?: Maybe<Scalars['numeric']>;
  not_published_reason?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Blog_Min_Fields = {
  __typename?: 'blog_min_fields';
  authorName?: Maybe<Scalars['String']>;
  authorPicture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  minsToRead?: Maybe<Scalars['numeric']>;
  not_published_reason?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "blog" */
export type Blog_Mutation_Response = {
  __typename?: 'blog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blog>;
};

/** on conflict condition type for table "blog" */
export type Blog_On_Conflict = {
  constraint: Blog_Constraint;
  update_columns?: Array<Blog_Update_Column>;
  where?: Maybe<Blog_Bool_Exp>;
};

/** Ordering options when selecting data from "blog". */
export type Blog_Order_By = {
  authorName?: Maybe<Order_By>;
  authorPicture?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  minsToRead?: Maybe<Order_By>;
  not_published_reason?: Maybe<Order_By>;
  picture?: Maybe<Order_By>;
  published?: Maybe<Order_By>;
  published_at?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
  verified?: Maybe<Order_By>;
};

/** primary key columns input for table: blog */
export type Blog_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "blog" */
export enum Blog_Select_Column {
  /** column name */
  AuthorName = 'authorName',
  /** column name */
  AuthorPicture = 'authorPicture',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  MinsToRead = 'minsToRead',
  /** column name */
  NotPublishedReason = 'not_published_reason',
  /** column name */
  Picture = 'picture',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Source = 'source',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "blog" */
export type Blog_Set_Input = {
  authorName?: Maybe<Scalars['String']>;
  authorPicture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  minsToRead?: Maybe<Scalars['numeric']>;
  not_published_reason?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  source?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Blog_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Blog_Stddev_Fields = {
  __typename?: 'blog_stddev_fields';
  minsToRead?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Blog_Stddev_Pop_Fields = {
  __typename?: 'blog_stddev_pop_fields';
  minsToRead?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Blog_Stddev_Samp_Fields = {
  __typename?: 'blog_stddev_samp_fields';
  minsToRead?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Blog_Sum_Fields = {
  __typename?: 'blog_sum_fields';
  minsToRead?: Maybe<Scalars['numeric']>;
};

/** columns and relationships of "blog_type" */
export type Blog_Type = {
  __typename?: 'blog_type';
  value: Scalars['String'];
};

/** aggregated selection of "blog_type" */
export type Blog_Type_Aggregate = {
  __typename?: 'blog_type_aggregate';
  aggregate?: Maybe<Blog_Type_Aggregate_Fields>;
  nodes: Array<Blog_Type>;
};

/** aggregate fields of "blog_type" */
export type Blog_Type_Aggregate_Fields = {
  __typename?: 'blog_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Blog_Type_Max_Fields>;
  min?: Maybe<Blog_Type_Min_Fields>;
};


/** aggregate fields of "blog_type" */
export type Blog_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Blog_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "blog_type". All fields are combined with a logical 'AND'. */
export type Blog_Type_Bool_Exp = {
  _and?: Maybe<Array<Blog_Type_Bool_Exp>>;
  _not?: Maybe<Blog_Type_Bool_Exp>;
  _or?: Maybe<Array<Blog_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "blog_type" */
export enum Blog_Type_Constraint {
  /** unique or primary key constraint */
  BlogTypePkey = 'blog_type_pkey'
}

export enum Blog_Type_Enum {
  Inspirational = 'inspirational'
}

/** Boolean expression to compare columns of type "blog_type_enum". All fields are combined with logical 'AND'. */
export type Blog_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Blog_Type_Enum>;
  _in?: Maybe<Array<Blog_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Blog_Type_Enum>;
  _nin?: Maybe<Array<Blog_Type_Enum>>;
};

/** input type for inserting data into table "blog_type" */
export type Blog_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Blog_Type_Max_Fields = {
  __typename?: 'blog_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Blog_Type_Min_Fields = {
  __typename?: 'blog_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "blog_type" */
export type Blog_Type_Mutation_Response = {
  __typename?: 'blog_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blog_Type>;
};

/** on conflict condition type for table "blog_type" */
export type Blog_Type_On_Conflict = {
  constraint: Blog_Type_Constraint;
  update_columns?: Array<Blog_Type_Update_Column>;
  where?: Maybe<Blog_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "blog_type". */
export type Blog_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: blog_type */
export type Blog_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "blog_type" */
export enum Blog_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "blog_type" */
export type Blog_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "blog_type" */
export enum Blog_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** update columns of table "blog" */
export enum Blog_Update_Column {
  /** column name */
  AuthorName = 'authorName',
  /** column name */
  AuthorPicture = 'authorPicture',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  MinsToRead = 'minsToRead',
  /** column name */
  NotPublishedReason = 'not_published_reason',
  /** column name */
  Picture = 'picture',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Source = 'source',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  Verified = 'verified'
}

/** aggregate var_pop on columns */
export type Blog_Var_Pop_Fields = {
  __typename?: 'blog_var_pop_fields';
  minsToRead?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Blog_Var_Samp_Fields = {
  __typename?: 'blog_var_samp_fields';
  minsToRead?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Blog_Variance_Fields = {
  __typename?: 'blog_variance_fields';
  minsToRead?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "class" */
export type Class = {
  __typename?: 'class';
  account_id: Scalars['uuid'];
  costType: Class_Cost_Type_Enum;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  dates_times: Array<Class_Date_Time>;
  /** An aggregate relationship */
  dates_times_aggregate: Class_Date_Time_Aggregate;
  description: Scalars['String'];
  id: Scalars['uuid'];
  level: Class_Level_Enum;
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['uuid']>;
  maxCapacity: Scalars['numeric'];
  name: Scalars['String'];
  online: Scalars['Boolean'];
  /** An object relationship */
  owner: Account;
  picture?: Maybe<Scalars['String']>;
  /** An array relationship */
  pictures: Array<Class_Picture>;
  /** An aggregate relationship */
  pictures_aggregate: Class_Picture_Aggregate;
  price: Scalars['Int'];
  recurrence: Class_Recurrence_Enum;
  /** An array relationship */
  reviews: Array<Class_Review>;
  /** An aggregate relationship */
  reviews_aggregate: Class_Review_Aggregate;
  /** An array relationship */
  safetyGuidelines: Array<Class_Safety_Guideline>;
  /** An aggregate relationship */
  safetyGuidelines_aggregate: Class_Safety_Guideline_Aggregate;
  setting?: Maybe<Class_Setting_Enum>;
  updated_at: Scalars['timestamptz'];
  yogaType: Yoga_Type_Enum;
};


/** columns and relationships of "class" */
export type ClassDates_TimesArgs = {
  distinct_on?: Maybe<Array<Class_Date_Time_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Date_Time_Order_By>>;
  where?: Maybe<Class_Date_Time_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassDates_Times_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Date_Time_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Date_Time_Order_By>>;
  where?: Maybe<Class_Date_Time_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassPicturesArgs = {
  distinct_on?: Maybe<Array<Class_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Picture_Order_By>>;
  where?: Maybe<Class_Picture_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassPictures_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Picture_Order_By>>;
  where?: Maybe<Class_Picture_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassReviewsArgs = {
  distinct_on?: Maybe<Array<Class_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Review_Order_By>>;
  where?: Maybe<Class_Review_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassReviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Review_Order_By>>;
  where?: Maybe<Class_Review_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassSafetyGuidelinesArgs = {
  distinct_on?: Maybe<Array<Class_Safety_Guideline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Safety_Guideline_Order_By>>;
  where?: Maybe<Class_Safety_Guideline_Bool_Exp>;
};


/** columns and relationships of "class" */
export type ClassSafetyGuidelines_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Safety_Guideline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Safety_Guideline_Order_By>>;
  where?: Maybe<Class_Safety_Guideline_Bool_Exp>;
};

/** aggregated selection of "class" */
export type Class_Aggregate = {
  __typename?: 'class_aggregate';
  aggregate?: Maybe<Class_Aggregate_Fields>;
  nodes: Array<Class>;
};

/** aggregate fields of "class" */
export type Class_Aggregate_Fields = {
  __typename?: 'class_aggregate_fields';
  avg?: Maybe<Class_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Max_Fields>;
  min?: Maybe<Class_Min_Fields>;
  stddev?: Maybe<Class_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Sum_Fields>;
  var_pop?: Maybe<Class_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Var_Samp_Fields>;
  variance?: Maybe<Class_Variance_Fields>;
};


/** aggregate fields of "class" */
export type Class_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Class_Avg_Fields = {
  __typename?: 'class_avg_fields';
  maxCapacity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "class_book" */
export type Class_Book = {
  __typename?: 'class_book';
  amount_paid?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  attendant: Account;
  buyer_account_id: Scalars['uuid'];
  /** An object relationship */
  class?: Maybe<Class>;
  /** An object relationship */
  class_book_cancellation?: Maybe<Class_Book_Cancellation>;
  /** An object relationship */
  class_date_time?: Maybe<Class_Date_Time>;
  class_date_time_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  class_event?: Maybe<Class_Event>;
  class_event_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  meeting_link?: Maybe<Scalars['String']>;
  payment_status: Scalars['String'];
  recorded_data: Scalars['jsonb'];
  stripe_payment_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "class_book" */
export type Class_BookRecorded_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "class_book" */
export type Class_Book_Aggregate = {
  __typename?: 'class_book_aggregate';
  aggregate?: Maybe<Class_Book_Aggregate_Fields>;
  nodes: Array<Class_Book>;
};

/** aggregate fields of "class_book" */
export type Class_Book_Aggregate_Fields = {
  __typename?: 'class_book_aggregate_fields';
  avg?: Maybe<Class_Book_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Book_Max_Fields>;
  min?: Maybe<Class_Book_Min_Fields>;
  stddev?: Maybe<Class_Book_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Book_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Book_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Book_Sum_Fields>;
  var_pop?: Maybe<Class_Book_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Book_Var_Samp_Fields>;
  variance?: Maybe<Class_Book_Variance_Fields>;
};


/** aggregate fields of "class_book" */
export type Class_Book_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Book_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_book" */
export type Class_Book_Aggregate_Order_By = {
  avg?: Maybe<Class_Book_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Book_Max_Order_By>;
  min?: Maybe<Class_Book_Min_Order_By>;
  stddev?: Maybe<Class_Book_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Book_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Book_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Book_Sum_Order_By>;
  var_pop?: Maybe<Class_Book_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Book_Var_Samp_Order_By>;
  variance?: Maybe<Class_Book_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Class_Book_Append_Input = {
  recorded_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "class_book" */
export type Class_Book_Arr_Rel_Insert_Input = {
  data: Array<Class_Book_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Class_Book_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Book_Avg_Fields = {
  __typename?: 'class_book_avg_fields';
  amount_paid?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_book" */
export type Class_Book_Avg_Order_By = {
  amount_paid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_book". All fields are combined with a logical 'AND'. */
export type Class_Book_Bool_Exp = {
  _and?: Maybe<Array<Class_Book_Bool_Exp>>;
  _not?: Maybe<Class_Book_Bool_Exp>;
  _or?: Maybe<Array<Class_Book_Bool_Exp>>;
  amount_paid?: Maybe<Numeric_Comparison_Exp>;
  attendant?: Maybe<Account_Bool_Exp>;
  buyer_account_id?: Maybe<Uuid_Comparison_Exp>;
  class?: Maybe<Class_Bool_Exp>;
  class_book_cancellation?: Maybe<Class_Book_Cancellation_Bool_Exp>;
  class_date_time?: Maybe<Class_Date_Time_Bool_Exp>;
  class_date_time_id?: Maybe<Uuid_Comparison_Exp>;
  class_event?: Maybe<Class_Event_Bool_Exp>;
  class_event_id?: Maybe<Uuid_Comparison_Exp>;
  class_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  meeting_link?: Maybe<String_Comparison_Exp>;
  payment_status?: Maybe<String_Comparison_Exp>;
  recorded_data?: Maybe<Jsonb_Comparison_Exp>;
  stripe_payment_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "class_book_cancellation" */
export type Class_Book_Cancellation = {
  __typename?: 'class_book_cancellation';
  /** An object relationship */
  class_book: Class_Book;
  class_book_id: Scalars['uuid'];
  comments: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  reason: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "class_book_cancellation" */
export type Class_Book_Cancellation_Aggregate = {
  __typename?: 'class_book_cancellation_aggregate';
  aggregate?: Maybe<Class_Book_Cancellation_Aggregate_Fields>;
  nodes: Array<Class_Book_Cancellation>;
};

/** aggregate fields of "class_book_cancellation" */
export type Class_Book_Cancellation_Aggregate_Fields = {
  __typename?: 'class_book_cancellation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Class_Book_Cancellation_Max_Fields>;
  min?: Maybe<Class_Book_Cancellation_Min_Fields>;
};


/** aggregate fields of "class_book_cancellation" */
export type Class_Book_Cancellation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Book_Cancellation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "class_book_cancellation". All fields are combined with a logical 'AND'. */
export type Class_Book_Cancellation_Bool_Exp = {
  _and?: Maybe<Array<Class_Book_Cancellation_Bool_Exp>>;
  _not?: Maybe<Class_Book_Cancellation_Bool_Exp>;
  _or?: Maybe<Array<Class_Book_Cancellation_Bool_Exp>>;
  class_book?: Maybe<Class_Book_Bool_Exp>;
  class_book_id?: Maybe<Uuid_Comparison_Exp>;
  comments?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  reason?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_book_cancellation" */
export enum Class_Book_Cancellation_Constraint {
  /** unique or primary key constraint */
  ClassBookCancellationPkey = 'class_book_cancellation_pkey'
}

/** input type for inserting data into table "class_book_cancellation" */
export type Class_Book_Cancellation_Insert_Input = {
  class_book?: Maybe<Class_Book_Obj_Rel_Insert_Input>;
  class_book_id?: Maybe<Scalars['uuid']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Book_Cancellation_Max_Fields = {
  __typename?: 'class_book_cancellation_max_fields';
  class_book_id?: Maybe<Scalars['uuid']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Class_Book_Cancellation_Min_Fields = {
  __typename?: 'class_book_cancellation_min_fields';
  class_book_id?: Maybe<Scalars['uuid']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "class_book_cancellation" */
export type Class_Book_Cancellation_Mutation_Response = {
  __typename?: 'class_book_cancellation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Book_Cancellation>;
};

/** input type for inserting object relation for remote table "class_book_cancellation" */
export type Class_Book_Cancellation_Obj_Rel_Insert_Input = {
  data: Class_Book_Cancellation_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Class_Book_Cancellation_On_Conflict>;
};

/** on conflict condition type for table "class_book_cancellation" */
export type Class_Book_Cancellation_On_Conflict = {
  constraint: Class_Book_Cancellation_Constraint;
  update_columns?: Array<Class_Book_Cancellation_Update_Column>;
  where?: Maybe<Class_Book_Cancellation_Bool_Exp>;
};

/** Ordering options when selecting data from "class_book_cancellation". */
export type Class_Book_Cancellation_Order_By = {
  class_book?: Maybe<Class_Book_Order_By>;
  class_book_id?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_book_cancellation */
export type Class_Book_Cancellation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "class_book_cancellation" */
export enum Class_Book_Cancellation_Select_Column {
  /** column name */
  ClassBookId = 'class_book_id',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_book_cancellation" */
export type Class_Book_Cancellation_Set_Input = {
  class_book_id?: Maybe<Scalars['uuid']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "class_book_cancellation" */
export enum Class_Book_Cancellation_Update_Column {
  /** column name */
  ClassBookId = 'class_book_id',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** unique or primary key constraints on table "class_book" */
export enum Class_Book_Constraint {
  /** unique or primary key constraint */
  BookPkey = 'book_pkey',
  /** unique or primary key constraint */
  ClassBookBuyerAccountIdClassEventIdKey = 'class_book_buyer_account_id_class_event_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Class_Book_Delete_At_Path_Input = {
  recorded_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Class_Book_Delete_Elem_Input = {
  recorded_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Class_Book_Delete_Key_Input = {
  recorded_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "class_book" */
export type Class_Book_Inc_Input = {
  amount_paid?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "class_book" */
export type Class_Book_Insert_Input = {
  amount_paid?: Maybe<Scalars['numeric']>;
  attendant?: Maybe<Account_Obj_Rel_Insert_Input>;
  buyer_account_id?: Maybe<Scalars['uuid']>;
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_book_cancellation?: Maybe<Class_Book_Cancellation_Obj_Rel_Insert_Input>;
  class_date_time?: Maybe<Class_Date_Time_Obj_Rel_Insert_Input>;
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_event?: Maybe<Class_Event_Obj_Rel_Insert_Input>;
  class_event_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_link?: Maybe<Scalars['String']>;
  payment_status?: Maybe<Scalars['String']>;
  recorded_data?: Maybe<Scalars['jsonb']>;
  stripe_payment_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Book_Max_Fields = {
  __typename?: 'class_book_max_fields';
  amount_paid?: Maybe<Scalars['numeric']>;
  buyer_account_id?: Maybe<Scalars['uuid']>;
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_event_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_link?: Maybe<Scalars['String']>;
  payment_status?: Maybe<Scalars['String']>;
  stripe_payment_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class_book" */
export type Class_Book_Max_Order_By = {
  amount_paid?: Maybe<Order_By>;
  buyer_account_id?: Maybe<Order_By>;
  class_date_time_id?: Maybe<Order_By>;
  class_event_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_link?: Maybe<Order_By>;
  payment_status?: Maybe<Order_By>;
  stripe_payment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Book_Min_Fields = {
  __typename?: 'class_book_min_fields';
  amount_paid?: Maybe<Scalars['numeric']>;
  buyer_account_id?: Maybe<Scalars['uuid']>;
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_event_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_link?: Maybe<Scalars['String']>;
  payment_status?: Maybe<Scalars['String']>;
  stripe_payment_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class_book" */
export type Class_Book_Min_Order_By = {
  amount_paid?: Maybe<Order_By>;
  buyer_account_id?: Maybe<Order_By>;
  class_date_time_id?: Maybe<Order_By>;
  class_event_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_link?: Maybe<Order_By>;
  payment_status?: Maybe<Order_By>;
  stripe_payment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_book" */
export type Class_Book_Mutation_Response = {
  __typename?: 'class_book_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Book>;
};

/** input type for inserting object relation for remote table "class_book" */
export type Class_Book_Obj_Rel_Insert_Input = {
  data: Class_Book_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Class_Book_On_Conflict>;
};

/** on conflict condition type for table "class_book" */
export type Class_Book_On_Conflict = {
  constraint: Class_Book_Constraint;
  update_columns?: Array<Class_Book_Update_Column>;
  where?: Maybe<Class_Book_Bool_Exp>;
};

/** Ordering options when selecting data from "class_book". */
export type Class_Book_Order_By = {
  amount_paid?: Maybe<Order_By>;
  attendant?: Maybe<Account_Order_By>;
  buyer_account_id?: Maybe<Order_By>;
  class?: Maybe<Class_Order_By>;
  class_book_cancellation?: Maybe<Class_Book_Cancellation_Order_By>;
  class_date_time?: Maybe<Class_Date_Time_Order_By>;
  class_date_time_id?: Maybe<Order_By>;
  class_event?: Maybe<Class_Event_Order_By>;
  class_event_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_link?: Maybe<Order_By>;
  payment_status?: Maybe<Order_By>;
  recorded_data?: Maybe<Order_By>;
  stripe_payment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_book */
export type Class_Book_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Class_Book_Prepend_Input = {
  recorded_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "class_book" */
export enum Class_Book_Select_Column {
  /** column name */
  AmountPaid = 'amount_paid',
  /** column name */
  BuyerAccountId = 'buyer_account_id',
  /** column name */
  ClassDateTimeId = 'class_date_time_id',
  /** column name */
  ClassEventId = 'class_event_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingLink = 'meeting_link',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  RecordedData = 'recorded_data',
  /** column name */
  StripePaymentId = 'stripe_payment_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_book" */
export type Class_Book_Set_Input = {
  amount_paid?: Maybe<Scalars['numeric']>;
  buyer_account_id?: Maybe<Scalars['uuid']>;
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_event_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_link?: Maybe<Scalars['String']>;
  payment_status?: Maybe<Scalars['String']>;
  recorded_data?: Maybe<Scalars['jsonb']>;
  stripe_payment_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Book_Stddev_Fields = {
  __typename?: 'class_book_stddev_fields';
  amount_paid?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_book" */
export type Class_Book_Stddev_Order_By = {
  amount_paid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Book_Stddev_Pop_Fields = {
  __typename?: 'class_book_stddev_pop_fields';
  amount_paid?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_book" */
export type Class_Book_Stddev_Pop_Order_By = {
  amount_paid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Book_Stddev_Samp_Fields = {
  __typename?: 'class_book_stddev_samp_fields';
  amount_paid?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_book" */
export type Class_Book_Stddev_Samp_Order_By = {
  amount_paid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Book_Sum_Fields = {
  __typename?: 'class_book_sum_fields';
  amount_paid?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "class_book" */
export type Class_Book_Sum_Order_By = {
  amount_paid?: Maybe<Order_By>;
};

/** update columns of table "class_book" */
export enum Class_Book_Update_Column {
  /** column name */
  AmountPaid = 'amount_paid',
  /** column name */
  BuyerAccountId = 'buyer_account_id',
  /** column name */
  ClassDateTimeId = 'class_date_time_id',
  /** column name */
  ClassEventId = 'class_event_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingLink = 'meeting_link',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  RecordedData = 'recorded_data',
  /** column name */
  StripePaymentId = 'stripe_payment_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Book_Var_Pop_Fields = {
  __typename?: 'class_book_var_pop_fields';
  amount_paid?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_book" */
export type Class_Book_Var_Pop_Order_By = {
  amount_paid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Book_Var_Samp_Fields = {
  __typename?: 'class_book_var_samp_fields';
  amount_paid?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_book" */
export type Class_Book_Var_Samp_Order_By = {
  amount_paid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Book_Variance_Fields = {
  __typename?: 'class_book_variance_fields';
  amount_paid?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_book" */
export type Class_Book_Variance_Order_By = {
  amount_paid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class". All fields are combined with a logical 'AND'. */
export type Class_Bool_Exp = {
  _and?: Maybe<Array<Class_Bool_Exp>>;
  _not?: Maybe<Class_Bool_Exp>;
  _or?: Maybe<Array<Class_Bool_Exp>>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  costType?: Maybe<Class_Cost_Type_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dates_times?: Maybe<Class_Date_Time_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  level?: Maybe<Class_Level_Enum_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  maxCapacity?: Maybe<Numeric_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  online?: Maybe<Boolean_Comparison_Exp>;
  owner?: Maybe<Account_Bool_Exp>;
  picture?: Maybe<String_Comparison_Exp>;
  pictures?: Maybe<Class_Picture_Bool_Exp>;
  price?: Maybe<Int_Comparison_Exp>;
  recurrence?: Maybe<Class_Recurrence_Enum_Comparison_Exp>;
  reviews?: Maybe<Class_Review_Bool_Exp>;
  safetyGuidelines?: Maybe<Class_Safety_Guideline_Bool_Exp>;
  setting?: Maybe<Class_Setting_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  yogaType?: Maybe<Yoga_Type_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "class" */
export enum Class_Constraint {
  /** unique or primary key constraint */
  ClassPkey = 'class_pkey'
}

/** columns and relationships of "class_cost_type" */
export type Class_Cost_Type = {
  __typename?: 'class_cost_type';
  value: Scalars['String'];
};

/** aggregated selection of "class_cost_type" */
export type Class_Cost_Type_Aggregate = {
  __typename?: 'class_cost_type_aggregate';
  aggregate?: Maybe<Class_Cost_Type_Aggregate_Fields>;
  nodes: Array<Class_Cost_Type>;
};

/** aggregate fields of "class_cost_type" */
export type Class_Cost_Type_Aggregate_Fields = {
  __typename?: 'class_cost_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Class_Cost_Type_Max_Fields>;
  min?: Maybe<Class_Cost_Type_Min_Fields>;
};


/** aggregate fields of "class_cost_type" */
export type Class_Cost_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Cost_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "class_cost_type". All fields are combined with a logical 'AND'. */
export type Class_Cost_Type_Bool_Exp = {
  _and?: Maybe<Array<Class_Cost_Type_Bool_Exp>>;
  _not?: Maybe<Class_Cost_Type_Bool_Exp>;
  _or?: Maybe<Array<Class_Cost_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_cost_type" */
export enum Class_Cost_Type_Constraint {
  /** unique or primary key constraint */
  CostTypePkey = 'cost_type_pkey'
}

export enum Class_Cost_Type_Enum {
  Donation = 'donation',
  Price = 'price'
}

/** Boolean expression to compare columns of type "class_cost_type_enum". All fields are combined with logical 'AND'. */
export type Class_Cost_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Class_Cost_Type_Enum>;
  _in?: Maybe<Array<Class_Cost_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Class_Cost_Type_Enum>;
  _nin?: Maybe<Array<Class_Cost_Type_Enum>>;
};

/** input type for inserting data into table "class_cost_type" */
export type Class_Cost_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Class_Cost_Type_Max_Fields = {
  __typename?: 'class_cost_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Class_Cost_Type_Min_Fields = {
  __typename?: 'class_cost_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "class_cost_type" */
export type Class_Cost_Type_Mutation_Response = {
  __typename?: 'class_cost_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Cost_Type>;
};

/** on conflict condition type for table "class_cost_type" */
export type Class_Cost_Type_On_Conflict = {
  constraint: Class_Cost_Type_Constraint;
  update_columns?: Array<Class_Cost_Type_Update_Column>;
  where?: Maybe<Class_Cost_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "class_cost_type". */
export type Class_Cost_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: class_cost_type */
export type Class_Cost_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "class_cost_type" */
export enum Class_Cost_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "class_cost_type" */
export type Class_Cost_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "class_cost_type" */
export enum Class_Cost_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "class_date_time" */
export type Class_Date_Time = {
  __typename?: 'class_date_time';
  class_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  date?: Maybe<Scalars['date']>;
  day?: Maybe<Scalars['Int']>;
  end: Scalars['timetz'];
  /** An object relationship */
  favorite_class?: Maybe<Favorite_Class>;
  id: Scalars['uuid'];
  owner_id: Scalars['uuid'];
  start: Scalars['timetz'];
  /** An object relationship */
  teacher: Teacher;
  teacher_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "class_date_time" */
export type Class_Date_Time_Aggregate = {
  __typename?: 'class_date_time_aggregate';
  aggregate?: Maybe<Class_Date_Time_Aggregate_Fields>;
  nodes: Array<Class_Date_Time>;
};

/** aggregate fields of "class_date_time" */
export type Class_Date_Time_Aggregate_Fields = {
  __typename?: 'class_date_time_aggregate_fields';
  avg?: Maybe<Class_Date_Time_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Date_Time_Max_Fields>;
  min?: Maybe<Class_Date_Time_Min_Fields>;
  stddev?: Maybe<Class_Date_Time_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Date_Time_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Date_Time_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Date_Time_Sum_Fields>;
  var_pop?: Maybe<Class_Date_Time_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Date_Time_Var_Samp_Fields>;
  variance?: Maybe<Class_Date_Time_Variance_Fields>;
};


/** aggregate fields of "class_date_time" */
export type Class_Date_Time_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Date_Time_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_date_time" */
export type Class_Date_Time_Aggregate_Order_By = {
  avg?: Maybe<Class_Date_Time_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Date_Time_Max_Order_By>;
  min?: Maybe<Class_Date_Time_Min_Order_By>;
  stddev?: Maybe<Class_Date_Time_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Date_Time_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Date_Time_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Date_Time_Sum_Order_By>;
  var_pop?: Maybe<Class_Date_Time_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Date_Time_Var_Samp_Order_By>;
  variance?: Maybe<Class_Date_Time_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_date_time" */
export type Class_Date_Time_Arr_Rel_Insert_Input = {
  data: Array<Class_Date_Time_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Class_Date_Time_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Date_Time_Avg_Fields = {
  __typename?: 'class_date_time_avg_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_date_time" */
export type Class_Date_Time_Avg_Order_By = {
  day?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_date_time". All fields are combined with a logical 'AND'. */
export type Class_Date_Time_Bool_Exp = {
  _and?: Maybe<Array<Class_Date_Time_Bool_Exp>>;
  _not?: Maybe<Class_Date_Time_Bool_Exp>;
  _or?: Maybe<Array<Class_Date_Time_Bool_Exp>>;
  class_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  day?: Maybe<Int_Comparison_Exp>;
  end?: Maybe<Timetz_Comparison_Exp>;
  favorite_class?: Maybe<Favorite_Class_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  start?: Maybe<Timetz_Comparison_Exp>;
  teacher?: Maybe<Teacher_Bool_Exp>;
  teacher_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_date_time" */
export enum Class_Date_Time_Constraint {
  /** unique or primary key constraint */
  ClassDateTimePkey = 'class_date_time_pkey'
}

/** input type for incrementing numeric columns in table "class_date_time" */
export type Class_Date_Time_Inc_Input = {
  day?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_date_time" */
export type Class_Date_Time_Insert_Input = {
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  day?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['timetz']>;
  favorite_class?: Maybe<Favorite_Class_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  start?: Maybe<Scalars['timetz']>;
  teacher?: Maybe<Teacher_Obj_Rel_Insert_Input>;
  teacher_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Class_Date_Time_Max_Fields = {
  __typename?: 'class_date_time_max_fields';
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  day?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  start?: Maybe<Scalars['timetz']>;
  teacher_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "class_date_time" */
export type Class_Date_Time_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Date_Time_Min_Fields = {
  __typename?: 'class_date_time_min_fields';
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  day?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  start?: Maybe<Scalars['timetz']>;
  teacher_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "class_date_time" */
export type Class_Date_Time_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_date_time" */
export type Class_Date_Time_Mutation_Response = {
  __typename?: 'class_date_time_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Date_Time>;
};

/** input type for inserting object relation for remote table "class_date_time" */
export type Class_Date_Time_Obj_Rel_Insert_Input = {
  data: Class_Date_Time_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Class_Date_Time_On_Conflict>;
};

/** on conflict condition type for table "class_date_time" */
export type Class_Date_Time_On_Conflict = {
  constraint: Class_Date_Time_Constraint;
  update_columns?: Array<Class_Date_Time_Update_Column>;
  where?: Maybe<Class_Date_Time_Bool_Exp>;
};

/** Ordering options when selecting data from "class_date_time". */
export type Class_Date_Time_Order_By = {
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  day?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  favorite_class?: Maybe<Favorite_Class_Order_By>;
  id?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  teacher?: Maybe<Teacher_Order_By>;
  teacher_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: class_date_time */
export type Class_Date_Time_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "class_date_time" */
export enum Class_Date_Time_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Day = 'day',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Start = 'start',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "class_date_time" */
export type Class_Date_Time_Set_Input = {
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  day?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['timetz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  start?: Maybe<Scalars['timetz']>;
  teacher_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Class_Date_Time_Stddev_Fields = {
  __typename?: 'class_date_time_stddev_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_date_time" */
export type Class_Date_Time_Stddev_Order_By = {
  day?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Date_Time_Stddev_Pop_Fields = {
  __typename?: 'class_date_time_stddev_pop_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_date_time" */
export type Class_Date_Time_Stddev_Pop_Order_By = {
  day?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Date_Time_Stddev_Samp_Fields = {
  __typename?: 'class_date_time_stddev_samp_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_date_time" */
export type Class_Date_Time_Stddev_Samp_Order_By = {
  day?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Date_Time_Sum_Fields = {
  __typename?: 'class_date_time_sum_fields';
  day?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_date_time" */
export type Class_Date_Time_Sum_Order_By = {
  day?: Maybe<Order_By>;
};

/** update columns of table "class_date_time" */
export enum Class_Date_Time_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Day = 'day',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Start = 'start',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Class_Date_Time_Var_Pop_Fields = {
  __typename?: 'class_date_time_var_pop_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_date_time" */
export type Class_Date_Time_Var_Pop_Order_By = {
  day?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Date_Time_Var_Samp_Fields = {
  __typename?: 'class_date_time_var_samp_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_date_time" */
export type Class_Date_Time_Var_Samp_Order_By = {
  day?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Date_Time_Variance_Fields = {
  __typename?: 'class_date_time_variance_fields';
  day?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_date_time" */
export type Class_Date_Time_Variance_Order_By = {
  day?: Maybe<Order_By>;
};

/** columns and relationships of "class_event" */
export type Class_Event = {
  __typename?: 'class_event';
  /** An object relationship */
  class: Class;
  /** An array relationship */
  class_books: Array<Class_Book>;
  /** An aggregate relationship */
  class_books_aggregate: Class_Book_Aggregate;
  /** An object relationship */
  class_date_time: Class_Date_Time;
  class_date_time_id: Scalars['uuid'];
  class_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  end_timestamp: Scalars['timestamptz'];
  id: Scalars['uuid'];
  meeting_link?: Maybe<Scalars['String']>;
  /** A computed field, executes function "dow_tz_from_start" */
  start_dow?: Maybe<Scalars['smallint']>;
  start_timestamp: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  web_id: Scalars['uuid'];
};


/** columns and relationships of "class_event" */
export type Class_EventClass_BooksArgs = {
  distinct_on?: Maybe<Array<Class_Book_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Book_Order_By>>;
  where?: Maybe<Class_Book_Bool_Exp>;
};


/** columns and relationships of "class_event" */
export type Class_EventClass_Books_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Book_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Book_Order_By>>;
  where?: Maybe<Class_Book_Bool_Exp>;
};


/** columns and relationships of "class_event" */
export type Class_EventStart_DowArgs = {
  args: Start_Dow_Class_Event_Args;
};

/** aggregated selection of "class_event" */
export type Class_Event_Aggregate = {
  __typename?: 'class_event_aggregate';
  aggregate?: Maybe<Class_Event_Aggregate_Fields>;
  nodes: Array<Class_Event>;
};

/** aggregate fields of "class_event" */
export type Class_Event_Aggregate_Fields = {
  __typename?: 'class_event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Class_Event_Max_Fields>;
  min?: Maybe<Class_Event_Min_Fields>;
};


/** aggregate fields of "class_event" */
export type Class_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Event_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "class_event". All fields are combined with a logical 'AND'. */
export type Class_Event_Bool_Exp = {
  _and?: Maybe<Array<Class_Event_Bool_Exp>>;
  _not?: Maybe<Class_Event_Bool_Exp>;
  _or?: Maybe<Array<Class_Event_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_books?: Maybe<Class_Book_Bool_Exp>;
  class_date_time?: Maybe<Class_Date_Time_Bool_Exp>;
  class_date_time_id?: Maybe<Uuid_Comparison_Exp>;
  class_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  end_timestamp?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  meeting_link?: Maybe<String_Comparison_Exp>;
  start_timestamp?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  web_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_event" */
export enum Class_Event_Constraint {
  /** unique or primary key constraint */
  ClassEventClassIdClassDateTimeIdStartTimestampKey = 'class_event_class_id_class_date_time_id_start_timestamp_key',
  /** unique or primary key constraint */
  ClassEventPkey = 'class_event_pkey'
}

/** input type for inserting data into table "class_event" */
export type Class_Event_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_books?: Maybe<Class_Book_Arr_Rel_Insert_Input>;
  class_date_time?: Maybe<Class_Date_Time_Obj_Rel_Insert_Input>;
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_timestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_link?: Maybe<Scalars['String']>;
  start_timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  web_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Class_Event_Max_Fields = {
  __typename?: 'class_event_max_fields';
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_timestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_link?: Maybe<Scalars['String']>;
  start_timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  web_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Class_Event_Min_Fields = {
  __typename?: 'class_event_min_fields';
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_timestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_link?: Maybe<Scalars['String']>;
  start_timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  web_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "class_event" */
export type Class_Event_Mutation_Response = {
  __typename?: 'class_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Event>;
};

/** input type for inserting object relation for remote table "class_event" */
export type Class_Event_Obj_Rel_Insert_Input = {
  data: Class_Event_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Class_Event_On_Conflict>;
};

/** on conflict condition type for table "class_event" */
export type Class_Event_On_Conflict = {
  constraint: Class_Event_Constraint;
  update_columns?: Array<Class_Event_Update_Column>;
  where?: Maybe<Class_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "class_event". */
export type Class_Event_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_books_aggregate?: Maybe<Class_Book_Aggregate_Order_By>;
  class_date_time?: Maybe<Class_Date_Time_Order_By>;
  class_date_time_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  end_timestamp?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_link?: Maybe<Order_By>;
  start_timestamp?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  web_id?: Maybe<Order_By>;
};

/** primary key columns input for table: class_event */
export type Class_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "class_event" */
export enum Class_Event_Select_Column {
  /** column name */
  ClassDateTimeId = 'class_date_time_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndTimestamp = 'end_timestamp',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingLink = 'meeting_link',
  /** column name */
  StartTimestamp = 'start_timestamp',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebId = 'web_id'
}

/** input type for updating data in table "class_event" */
export type Class_Event_Set_Input = {
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_timestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  meeting_link?: Maybe<Scalars['String']>;
  start_timestamp?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  web_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "class_event" */
export enum Class_Event_Update_Column {
  /** column name */
  ClassDateTimeId = 'class_date_time_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndTimestamp = 'end_timestamp',
  /** column name */
  Id = 'id',
  /** column name */
  MeetingLink = 'meeting_link',
  /** column name */
  StartTimestamp = 'start_timestamp',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebId = 'web_id'
}

/** input type for incrementing numeric columns in table "class" */
export type Class_Inc_Input = {
  maxCapacity?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class" */
export type Class_Insert_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  costType?: Maybe<Class_Cost_Type_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dates_times?: Maybe<Class_Date_Time_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Class_Level_Enum>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  maxCapacity?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Account_Obj_Rel_Insert_Input>;
  picture?: Maybe<Scalars['String']>;
  pictures?: Maybe<Class_Picture_Arr_Rel_Insert_Input>;
  price?: Maybe<Scalars['Int']>;
  recurrence?: Maybe<Class_Recurrence_Enum>;
  reviews?: Maybe<Class_Review_Arr_Rel_Insert_Input>;
  safetyGuidelines?: Maybe<Class_Safety_Guideline_Arr_Rel_Insert_Input>;
  setting?: Maybe<Class_Setting_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  yogaType?: Maybe<Yoga_Type_Enum>;
};

/** columns and relationships of "class_level" */
export type Class_Level = {
  __typename?: 'class_level';
  value: Scalars['String'];
};

/** aggregated selection of "class_level" */
export type Class_Level_Aggregate = {
  __typename?: 'class_level_aggregate';
  aggregate?: Maybe<Class_Level_Aggregate_Fields>;
  nodes: Array<Class_Level>;
};

/** aggregate fields of "class_level" */
export type Class_Level_Aggregate_Fields = {
  __typename?: 'class_level_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Class_Level_Max_Fields>;
  min?: Maybe<Class_Level_Min_Fields>;
};


/** aggregate fields of "class_level" */
export type Class_Level_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Level_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "class_level". All fields are combined with a logical 'AND'. */
export type Class_Level_Bool_Exp = {
  _and?: Maybe<Array<Class_Level_Bool_Exp>>;
  _not?: Maybe<Class_Level_Bool_Exp>;
  _or?: Maybe<Array<Class_Level_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_level" */
export enum Class_Level_Constraint {
  /** unique or primary key constraint */
  LevelPkey = 'level_pkey'
}

export enum Class_Level_Enum {
  Advanced = 'advanced',
  All = 'all',
  Beginner = 'beginner',
  Intermediate = 'intermediate'
}

/** Boolean expression to compare columns of type "class_level_enum". All fields are combined with logical 'AND'. */
export type Class_Level_Enum_Comparison_Exp = {
  _eq?: Maybe<Class_Level_Enum>;
  _in?: Maybe<Array<Class_Level_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Class_Level_Enum>;
  _nin?: Maybe<Array<Class_Level_Enum>>;
};

/** input type for inserting data into table "class_level" */
export type Class_Level_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Class_Level_Max_Fields = {
  __typename?: 'class_level_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Class_Level_Min_Fields = {
  __typename?: 'class_level_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "class_level" */
export type Class_Level_Mutation_Response = {
  __typename?: 'class_level_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Level>;
};

/** on conflict condition type for table "class_level" */
export type Class_Level_On_Conflict = {
  constraint: Class_Level_Constraint;
  update_columns?: Array<Class_Level_Update_Column>;
  where?: Maybe<Class_Level_Bool_Exp>;
};

/** Ordering options when selecting data from "class_level". */
export type Class_Level_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: class_level */
export type Class_Level_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "class_level" */
export enum Class_Level_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "class_level" */
export type Class_Level_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "class_level" */
export enum Class_Level_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregate max on columns */
export type Class_Max_Fields = {
  __typename?: 'class_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  maxCapacity?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Class_Min_Fields = {
  __typename?: 'class_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  maxCapacity?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "class" */
export type Class_Mutation_Response = {
  __typename?: 'class_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class>;
};

/** input type for inserting object relation for remote table "class" */
export type Class_Obj_Rel_Insert_Input = {
  data: Class_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Class_On_Conflict>;
};

/** on conflict condition type for table "class" */
export type Class_On_Conflict = {
  constraint: Class_Constraint;
  update_columns?: Array<Class_Update_Column>;
  where?: Maybe<Class_Bool_Exp>;
};

/** Ordering options when selecting data from "class". */
export type Class_Order_By = {
  account_id?: Maybe<Order_By>;
  costType?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dates_times_aggregate?: Maybe<Class_Date_Time_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  location_id?: Maybe<Order_By>;
  maxCapacity?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  online?: Maybe<Order_By>;
  owner?: Maybe<Account_Order_By>;
  picture?: Maybe<Order_By>;
  pictures_aggregate?: Maybe<Class_Picture_Aggregate_Order_By>;
  price?: Maybe<Order_By>;
  recurrence?: Maybe<Order_By>;
  reviews_aggregate?: Maybe<Class_Review_Aggregate_Order_By>;
  safetyGuidelines_aggregate?: Maybe<Class_Safety_Guideline_Aggregate_Order_By>;
  setting?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  yogaType?: Maybe<Order_By>;
};

/** columns and relationships of "class_picture" */
export type Class_Picture = {
  __typename?: 'class_picture';
  /** An object relationship */
  class: Class;
  class_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "class_picture" */
export type Class_Picture_Aggregate = {
  __typename?: 'class_picture_aggregate';
  aggregate?: Maybe<Class_Picture_Aggregate_Fields>;
  nodes: Array<Class_Picture>;
};

/** aggregate fields of "class_picture" */
export type Class_Picture_Aggregate_Fields = {
  __typename?: 'class_picture_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Class_Picture_Max_Fields>;
  min?: Maybe<Class_Picture_Min_Fields>;
};


/** aggregate fields of "class_picture" */
export type Class_Picture_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Picture_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_picture" */
export type Class_Picture_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Picture_Max_Order_By>;
  min?: Maybe<Class_Picture_Min_Order_By>;
};

/** input type for inserting array relation for remote table "class_picture" */
export type Class_Picture_Arr_Rel_Insert_Input = {
  data: Array<Class_Picture_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Class_Picture_On_Conflict>;
};

/** Boolean expression to filter rows from the table "class_picture". All fields are combined with a logical 'AND'. */
export type Class_Picture_Bool_Exp = {
  _and?: Maybe<Array<Class_Picture_Bool_Exp>>;
  _not?: Maybe<Class_Picture_Bool_Exp>;
  _or?: Maybe<Array<Class_Picture_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_picture" */
export enum Class_Picture_Constraint {
  /** unique or primary key constraint */
  ClassPicturePkey = 'class_picture_pkey'
}

/** input type for inserting data into table "class_picture" */
export type Class_Picture_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Class_Picture_Max_Fields = {
  __typename?: 'class_picture_max_fields';
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "class_picture" */
export type Class_Picture_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Picture_Min_Fields = {
  __typename?: 'class_picture_min_fields';
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "class_picture" */
export type Class_Picture_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_picture" */
export type Class_Picture_Mutation_Response = {
  __typename?: 'class_picture_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Picture>;
};

/** on conflict condition type for table "class_picture" */
export type Class_Picture_On_Conflict = {
  constraint: Class_Picture_Constraint;
  update_columns?: Array<Class_Picture_Update_Column>;
  where?: Maybe<Class_Picture_Bool_Exp>;
};

/** Ordering options when selecting data from "class_picture". */
export type Class_Picture_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: class_picture */
export type Class_Picture_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "class_picture" */
export enum Class_Picture_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "class_picture" */
export type Class_Picture_Set_Input = {
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** update columns of table "class_picture" */
export enum Class_Picture_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** primary key columns input for table: class */
export type Class_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "class_recurrence" */
export type Class_Recurrence = {
  __typename?: 'class_recurrence';
  value: Scalars['String'];
};

/** aggregated selection of "class_recurrence" */
export type Class_Recurrence_Aggregate = {
  __typename?: 'class_recurrence_aggregate';
  aggregate?: Maybe<Class_Recurrence_Aggregate_Fields>;
  nodes: Array<Class_Recurrence>;
};

/** aggregate fields of "class_recurrence" */
export type Class_Recurrence_Aggregate_Fields = {
  __typename?: 'class_recurrence_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Class_Recurrence_Max_Fields>;
  min?: Maybe<Class_Recurrence_Min_Fields>;
};


/** aggregate fields of "class_recurrence" */
export type Class_Recurrence_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Recurrence_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "class_recurrence". All fields are combined with a logical 'AND'. */
export type Class_Recurrence_Bool_Exp = {
  _and?: Maybe<Array<Class_Recurrence_Bool_Exp>>;
  _not?: Maybe<Class_Recurrence_Bool_Exp>;
  _or?: Maybe<Array<Class_Recurrence_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_recurrence" */
export enum Class_Recurrence_Constraint {
  /** unique or primary key constraint */
  RecurrencePkey = 'recurrence_pkey'
}

export enum Class_Recurrence_Enum {
  OneTime = 'oneTime',
  Recurring = 'recurring'
}

/** Boolean expression to compare columns of type "class_recurrence_enum". All fields are combined with logical 'AND'. */
export type Class_Recurrence_Enum_Comparison_Exp = {
  _eq?: Maybe<Class_Recurrence_Enum>;
  _in?: Maybe<Array<Class_Recurrence_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Class_Recurrence_Enum>;
  _nin?: Maybe<Array<Class_Recurrence_Enum>>;
};

/** input type for inserting data into table "class_recurrence" */
export type Class_Recurrence_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Class_Recurrence_Max_Fields = {
  __typename?: 'class_recurrence_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Class_Recurrence_Min_Fields = {
  __typename?: 'class_recurrence_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "class_recurrence" */
export type Class_Recurrence_Mutation_Response = {
  __typename?: 'class_recurrence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Recurrence>;
};

/** on conflict condition type for table "class_recurrence" */
export type Class_Recurrence_On_Conflict = {
  constraint: Class_Recurrence_Constraint;
  update_columns?: Array<Class_Recurrence_Update_Column>;
  where?: Maybe<Class_Recurrence_Bool_Exp>;
};

/** Ordering options when selecting data from "class_recurrence". */
export type Class_Recurrence_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: class_recurrence */
export type Class_Recurrence_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "class_recurrence" */
export enum Class_Recurrence_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "class_recurrence" */
export type Class_Recurrence_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "class_recurrence" */
export enum Class_Recurrence_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "class_review" */
export type Class_Review = {
  __typename?: 'class_review';
  /** An object relationship */
  account: Account;
  account_id: Scalars['uuid'];
  class_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  value: Scalars['Int'];
};

/** aggregated selection of "class_review" */
export type Class_Review_Aggregate = {
  __typename?: 'class_review_aggregate';
  aggregate?: Maybe<Class_Review_Aggregate_Fields>;
  nodes: Array<Class_Review>;
};

/** aggregate fields of "class_review" */
export type Class_Review_Aggregate_Fields = {
  __typename?: 'class_review_aggregate_fields';
  avg?: Maybe<Class_Review_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Review_Max_Fields>;
  min?: Maybe<Class_Review_Min_Fields>;
  stddev?: Maybe<Class_Review_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Review_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Review_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Review_Sum_Fields>;
  var_pop?: Maybe<Class_Review_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Review_Var_Samp_Fields>;
  variance?: Maybe<Class_Review_Variance_Fields>;
};


/** aggregate fields of "class_review" */
export type Class_Review_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Review_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_review" */
export type Class_Review_Aggregate_Order_By = {
  avg?: Maybe<Class_Review_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Review_Max_Order_By>;
  min?: Maybe<Class_Review_Min_Order_By>;
  stddev?: Maybe<Class_Review_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Review_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Review_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Review_Sum_Order_By>;
  var_pop?: Maybe<Class_Review_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Review_Var_Samp_Order_By>;
  variance?: Maybe<Class_Review_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_review" */
export type Class_Review_Arr_Rel_Insert_Input = {
  data: Array<Class_Review_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Class_Review_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Review_Avg_Fields = {
  __typename?: 'class_review_avg_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_review" */
export type Class_Review_Avg_Order_By = {
  value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_review". All fields are combined with a logical 'AND'. */
export type Class_Review_Bool_Exp = {
  _and?: Maybe<Array<Class_Review_Bool_Exp>>;
  _not?: Maybe<Class_Review_Bool_Exp>;
  _or?: Maybe<Array<Class_Review_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  class_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_review" */
export enum Class_Review_Constraint {
  /** unique or primary key constraint */
  ClassReviewPkey = 'class_review_pkey'
}

/** input type for incrementing numeric columns in table "class_review" */
export type Class_Review_Inc_Input = {
  value?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_review" */
export type Class_Review_Insert_Input = {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Class_Review_Max_Fields = {
  __typename?: 'class_review_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "class_review" */
export type Class_Review_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Review_Min_Fields = {
  __typename?: 'class_review_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "class_review" */
export type Class_Review_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_review" */
export type Class_Review_Mutation_Response = {
  __typename?: 'class_review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Review>;
};

/** on conflict condition type for table "class_review" */
export type Class_Review_On_Conflict = {
  constraint: Class_Review_Constraint;
  update_columns?: Array<Class_Review_Update_Column>;
  where?: Maybe<Class_Review_Bool_Exp>;
};

/** Ordering options when selecting data from "class_review". */
export type Class_Review_Order_By = {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: class_review */
export type Class_Review_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "class_review" */
export enum Class_Review_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "class_review" */
export type Class_Review_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Class_Review_Stddev_Fields = {
  __typename?: 'class_review_stddev_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_review" */
export type Class_Review_Stddev_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Review_Stddev_Pop_Fields = {
  __typename?: 'class_review_stddev_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_review" */
export type Class_Review_Stddev_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Review_Stddev_Samp_Fields = {
  __typename?: 'class_review_stddev_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_review" */
export type Class_Review_Stddev_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Review_Sum_Fields = {
  __typename?: 'class_review_sum_fields';
  value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_review" */
export type Class_Review_Sum_Order_By = {
  value?: Maybe<Order_By>;
};

/** update columns of table "class_review" */
export enum Class_Review_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Class_Review_Var_Pop_Fields = {
  __typename?: 'class_review_var_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_review" */
export type Class_Review_Var_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Review_Var_Samp_Fields = {
  __typename?: 'class_review_var_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_review" */
export type Class_Review_Var_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Review_Variance_Fields = {
  __typename?: 'class_review_variance_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_review" */
export type Class_Review_Variance_Order_By = {
  value?: Maybe<Order_By>;
};

/** columns and relationships of "class_safety_guideline" */
export type Class_Safety_Guideline = {
  __typename?: 'class_safety_guideline';
  /** An object relationship */
  class: Class;
  class_id: Scalars['uuid'];
  id: Scalars['Int'];
  /** An object relationship */
  safety_guideline: Safety_Guideline;
  safety_guideline_id: Scalars['String'];
};

/** aggregated selection of "class_safety_guideline" */
export type Class_Safety_Guideline_Aggregate = {
  __typename?: 'class_safety_guideline_aggregate';
  aggregate?: Maybe<Class_Safety_Guideline_Aggregate_Fields>;
  nodes: Array<Class_Safety_Guideline>;
};

/** aggregate fields of "class_safety_guideline" */
export type Class_Safety_Guideline_Aggregate_Fields = {
  __typename?: 'class_safety_guideline_aggregate_fields';
  avg?: Maybe<Class_Safety_Guideline_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Class_Safety_Guideline_Max_Fields>;
  min?: Maybe<Class_Safety_Guideline_Min_Fields>;
  stddev?: Maybe<Class_Safety_Guideline_Stddev_Fields>;
  stddev_pop?: Maybe<Class_Safety_Guideline_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Class_Safety_Guideline_Stddev_Samp_Fields>;
  sum?: Maybe<Class_Safety_Guideline_Sum_Fields>;
  var_pop?: Maybe<Class_Safety_Guideline_Var_Pop_Fields>;
  var_samp?: Maybe<Class_Safety_Guideline_Var_Samp_Fields>;
  variance?: Maybe<Class_Safety_Guideline_Variance_Fields>;
};


/** aggregate fields of "class_safety_guideline" */
export type Class_Safety_Guideline_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Safety_Guideline_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "class_safety_guideline" */
export type Class_Safety_Guideline_Aggregate_Order_By = {
  avg?: Maybe<Class_Safety_Guideline_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Safety_Guideline_Max_Order_By>;
  min?: Maybe<Class_Safety_Guideline_Min_Order_By>;
  stddev?: Maybe<Class_Safety_Guideline_Stddev_Order_By>;
  stddev_pop?: Maybe<Class_Safety_Guideline_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Class_Safety_Guideline_Stddev_Samp_Order_By>;
  sum?: Maybe<Class_Safety_Guideline_Sum_Order_By>;
  var_pop?: Maybe<Class_Safety_Guideline_Var_Pop_Order_By>;
  var_samp?: Maybe<Class_Safety_Guideline_Var_Samp_Order_By>;
  variance?: Maybe<Class_Safety_Guideline_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "class_safety_guideline" */
export type Class_Safety_Guideline_Arr_Rel_Insert_Input = {
  data: Array<Class_Safety_Guideline_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Class_Safety_Guideline_On_Conflict>;
};

/** aggregate avg on columns */
export type Class_Safety_Guideline_Avg_Fields = {
  __typename?: 'class_safety_guideline_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "class_safety_guideline" */
export type Class_Safety_Guideline_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "class_safety_guideline". All fields are combined with a logical 'AND'. */
export type Class_Safety_Guideline_Bool_Exp = {
  _and?: Maybe<Array<Class_Safety_Guideline_Bool_Exp>>;
  _not?: Maybe<Class_Safety_Guideline_Bool_Exp>;
  _or?: Maybe<Array<Class_Safety_Guideline_Bool_Exp>>;
  class?: Maybe<Class_Bool_Exp>;
  class_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  safety_guideline?: Maybe<Safety_Guideline_Bool_Exp>;
  safety_guideline_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_safety_guideline" */
export enum Class_Safety_Guideline_Constraint {
  /** unique or primary key constraint */
  ClassSafetyGuidelinePkey = 'class_safety_guideline_pkey'
}

/** input type for incrementing numeric columns in table "class_safety_guideline" */
export type Class_Safety_Guideline_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "class_safety_guideline" */
export type Class_Safety_Guideline_Insert_Input = {
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  safety_guideline?: Maybe<Safety_Guideline_Obj_Rel_Insert_Input>;
  safety_guideline_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Class_Safety_Guideline_Max_Fields = {
  __typename?: 'class_safety_guideline_max_fields';
  class_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  safety_guideline_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "class_safety_guideline" */
export type Class_Safety_Guideline_Max_Order_By = {
  class_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  safety_guideline_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Safety_Guideline_Min_Fields = {
  __typename?: 'class_safety_guideline_min_fields';
  class_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  safety_guideline_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "class_safety_guideline" */
export type Class_Safety_Guideline_Min_Order_By = {
  class_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  safety_guideline_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_safety_guideline" */
export type Class_Safety_Guideline_Mutation_Response = {
  __typename?: 'class_safety_guideline_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Safety_Guideline>;
};

/** on conflict condition type for table "class_safety_guideline" */
export type Class_Safety_Guideline_On_Conflict = {
  constraint: Class_Safety_Guideline_Constraint;
  update_columns?: Array<Class_Safety_Guideline_Update_Column>;
  where?: Maybe<Class_Safety_Guideline_Bool_Exp>;
};

/** Ordering options when selecting data from "class_safety_guideline". */
export type Class_Safety_Guideline_Order_By = {
  class?: Maybe<Class_Order_By>;
  class_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  safety_guideline?: Maybe<Safety_Guideline_Order_By>;
  safety_guideline_id?: Maybe<Order_By>;
};

/** primary key columns input for table: class_safety_guideline */
export type Class_Safety_Guideline_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "class_safety_guideline" */
export enum Class_Safety_Guideline_Select_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Id = 'id',
  /** column name */
  SafetyGuidelineId = 'safety_guideline_id'
}

/** input type for updating data in table "class_safety_guideline" */
export type Class_Safety_Guideline_Set_Input = {
  class_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  safety_guideline_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Class_Safety_Guideline_Stddev_Fields = {
  __typename?: 'class_safety_guideline_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "class_safety_guideline" */
export type Class_Safety_Guideline_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Class_Safety_Guideline_Stddev_Pop_Fields = {
  __typename?: 'class_safety_guideline_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "class_safety_guideline" */
export type Class_Safety_Guideline_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Class_Safety_Guideline_Stddev_Samp_Fields = {
  __typename?: 'class_safety_guideline_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "class_safety_guideline" */
export type Class_Safety_Guideline_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Class_Safety_Guideline_Sum_Fields = {
  __typename?: 'class_safety_guideline_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "class_safety_guideline" */
export type Class_Safety_Guideline_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "class_safety_guideline" */
export enum Class_Safety_Guideline_Update_Column {
  /** column name */
  ClassId = 'class_id',
  /** column name */
  Id = 'id',
  /** column name */
  SafetyGuidelineId = 'safety_guideline_id'
}

/** aggregate var_pop on columns */
export type Class_Safety_Guideline_Var_Pop_Fields = {
  __typename?: 'class_safety_guideline_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "class_safety_guideline" */
export type Class_Safety_Guideline_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Class_Safety_Guideline_Var_Samp_Fields = {
  __typename?: 'class_safety_guideline_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "class_safety_guideline" */
export type Class_Safety_Guideline_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Class_Safety_Guideline_Variance_Fields = {
  __typename?: 'class_safety_guideline_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "class_safety_guideline" */
export type Class_Safety_Guideline_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** select columns of table "class" */
export enum Class_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CostType = 'costType',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MaxCapacity = 'maxCapacity',
  /** column name */
  Name = 'name',
  /** column name */
  Online = 'online',
  /** column name */
  Picture = 'picture',
  /** column name */
  Price = 'price',
  /** column name */
  Recurrence = 'recurrence',
  /** column name */
  Setting = 'setting',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YogaType = 'yogaType'
}

/** input type for updating data in table "class" */
export type Class_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  costType?: Maybe<Class_Cost_Type_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Class_Level_Enum>;
  location_id?: Maybe<Scalars['uuid']>;
  maxCapacity?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  online?: Maybe<Scalars['Boolean']>;
  picture?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  recurrence?: Maybe<Class_Recurrence_Enum>;
  setting?: Maybe<Class_Setting_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  yogaType?: Maybe<Yoga_Type_Enum>;
};

/** columns and relationships of "class_setting" */
export type Class_Setting = {
  __typename?: 'class_setting';
  value: Scalars['String'];
};

/** aggregated selection of "class_setting" */
export type Class_Setting_Aggregate = {
  __typename?: 'class_setting_aggregate';
  aggregate?: Maybe<Class_Setting_Aggregate_Fields>;
  nodes: Array<Class_Setting>;
};

/** aggregate fields of "class_setting" */
export type Class_Setting_Aggregate_Fields = {
  __typename?: 'class_setting_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Class_Setting_Max_Fields>;
  min?: Maybe<Class_Setting_Min_Fields>;
};


/** aggregate fields of "class_setting" */
export type Class_Setting_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Setting_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "class_setting". All fields are combined with a logical 'AND'. */
export type Class_Setting_Bool_Exp = {
  _and?: Maybe<Array<Class_Setting_Bool_Exp>>;
  _not?: Maybe<Class_Setting_Bool_Exp>;
  _or?: Maybe<Array<Class_Setting_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "class_setting" */
export enum Class_Setting_Constraint {
  /** unique or primary key constraint */
  SettingPkey = 'setting_pkey'
}

export enum Class_Setting_Enum {
  Outdoor = 'outdoor',
  Studio = 'studio'
}

/** Boolean expression to compare columns of type "class_setting_enum". All fields are combined with logical 'AND'. */
export type Class_Setting_Enum_Comparison_Exp = {
  _eq?: Maybe<Class_Setting_Enum>;
  _in?: Maybe<Array<Class_Setting_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Class_Setting_Enum>;
  _nin?: Maybe<Array<Class_Setting_Enum>>;
};

/** input type for inserting data into table "class_setting" */
export type Class_Setting_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Class_Setting_Max_Fields = {
  __typename?: 'class_setting_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Class_Setting_Min_Fields = {
  __typename?: 'class_setting_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "class_setting" */
export type Class_Setting_Mutation_Response = {
  __typename?: 'class_setting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Setting>;
};

/** on conflict condition type for table "class_setting" */
export type Class_Setting_On_Conflict = {
  constraint: Class_Setting_Constraint;
  update_columns?: Array<Class_Setting_Update_Column>;
  where?: Maybe<Class_Setting_Bool_Exp>;
};

/** Ordering options when selecting data from "class_setting". */
export type Class_Setting_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: class_setting */
export type Class_Setting_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "class_setting" */
export enum Class_Setting_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "class_setting" */
export type Class_Setting_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "class_setting" */
export enum Class_Setting_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type Class_Stddev_Fields = {
  __typename?: 'class_stddev_fields';
  maxCapacity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Class_Stddev_Pop_Fields = {
  __typename?: 'class_stddev_pop_fields';
  maxCapacity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Class_Stddev_Samp_Fields = {
  __typename?: 'class_stddev_samp_fields';
  maxCapacity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Class_Sum_Fields = {
  __typename?: 'class_sum_fields';
  maxCapacity?: Maybe<Scalars['numeric']>;
  price?: Maybe<Scalars['Int']>;
};

/** update columns of table "class" */
export enum Class_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CostType = 'costType',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MaxCapacity = 'maxCapacity',
  /** column name */
  Name = 'name',
  /** column name */
  Online = 'online',
  /** column name */
  Picture = 'picture',
  /** column name */
  Price = 'price',
  /** column name */
  Recurrence = 'recurrence',
  /** column name */
  Setting = 'setting',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YogaType = 'yogaType'
}

/** aggregate var_pop on columns */
export type Class_Var_Pop_Fields = {
  __typename?: 'class_var_pop_fields';
  maxCapacity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Class_Var_Samp_Fields = {
  __typename?: 'class_var_samp_fields';
  maxCapacity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Class_Variance_Fields = {
  __typename?: 'class_variance_fields';
  maxCapacity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "favorite_class" */
export type Favorite_Class = {
  __typename?: 'favorite_class';
  account_id: Scalars['uuid'];
  /** An object relationship */
  class: Class;
  /** An object relationship */
  class_date_time: Class_Date_Time;
  class_date_time_id: Scalars['uuid'];
  class_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "favorite_class" */
export type Favorite_Class_Aggregate = {
  __typename?: 'favorite_class_aggregate';
  aggregate?: Maybe<Favorite_Class_Aggregate_Fields>;
  nodes: Array<Favorite_Class>;
};

/** aggregate fields of "favorite_class" */
export type Favorite_Class_Aggregate_Fields = {
  __typename?: 'favorite_class_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Favorite_Class_Max_Fields>;
  min?: Maybe<Favorite_Class_Min_Fields>;
};


/** aggregate fields of "favorite_class" */
export type Favorite_Class_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Favorite_Class_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "favorite_class". All fields are combined with a logical 'AND'. */
export type Favorite_Class_Bool_Exp = {
  _and?: Maybe<Array<Favorite_Class_Bool_Exp>>;
  _not?: Maybe<Favorite_Class_Bool_Exp>;
  _or?: Maybe<Array<Favorite_Class_Bool_Exp>>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  class?: Maybe<Class_Bool_Exp>;
  class_date_time?: Maybe<Class_Date_Time_Bool_Exp>;
  class_date_time_id?: Maybe<Uuid_Comparison_Exp>;
  class_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "favorite_class" */
export enum Favorite_Class_Constraint {
  /** unique or primary key constraint */
  FavoriteClassPkey = 'favorite_class_pkey'
}

/** input type for inserting data into table "favorite_class" */
export type Favorite_Class_Insert_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  class?: Maybe<Class_Obj_Rel_Insert_Input>;
  class_date_time?: Maybe<Class_Date_Time_Obj_Rel_Insert_Input>;
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Favorite_Class_Max_Fields = {
  __typename?: 'favorite_class_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Favorite_Class_Min_Fields = {
  __typename?: 'favorite_class_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "favorite_class" */
export type Favorite_Class_Mutation_Response = {
  __typename?: 'favorite_class_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Favorite_Class>;
};

/** input type for inserting object relation for remote table "favorite_class" */
export type Favorite_Class_Obj_Rel_Insert_Input = {
  data: Favorite_Class_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Favorite_Class_On_Conflict>;
};

/** on conflict condition type for table "favorite_class" */
export type Favorite_Class_On_Conflict = {
  constraint: Favorite_Class_Constraint;
  update_columns?: Array<Favorite_Class_Update_Column>;
  where?: Maybe<Favorite_Class_Bool_Exp>;
};

/** Ordering options when selecting data from "favorite_class". */
export type Favorite_Class_Order_By = {
  account_id?: Maybe<Order_By>;
  class?: Maybe<Class_Order_By>;
  class_date_time?: Maybe<Class_Date_Time_Order_By>;
  class_date_time_id?: Maybe<Order_By>;
  class_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: favorite_class */
export type Favorite_Class_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "favorite_class" */
export enum Favorite_Class_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ClassDateTimeId = 'class_date_time_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "favorite_class" */
export type Favorite_Class_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  class_date_time_id?: Maybe<Scalars['uuid']>;
  class_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "favorite_class" */
export enum Favorite_Class_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ClassDateTimeId = 'class_date_time_id',
  /** column name */
  ClassId = 'class_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}


export type Geography_Cast_Exp = {
  geometry?: Maybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: Maybe<Geography_Cast_Exp>;
  _eq?: Maybe<Scalars['geography']>;
  _gt?: Maybe<Scalars['geography']>;
  _gte?: Maybe<Scalars['geography']>;
  _in?: Maybe<Array<Scalars['geography']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geography']>;
  _lte?: Maybe<Scalars['geography']>;
  _neq?: Maybe<Scalars['geography']>;
  _nin?: Maybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: Maybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: Maybe<Scalars['geography']>;
};


export type Geometry_Cast_Exp = {
  geography?: Maybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: Maybe<Geometry_Cast_Exp>;
  _eq?: Maybe<Scalars['geometry']>;
  _gt?: Maybe<Scalars['geometry']>;
  _gte?: Maybe<Scalars['geometry']>;
  _in?: Maybe<Array<Scalars['geometry']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geometry']>;
  _lte?: Maybe<Scalars['geometry']>;
  _neq?: Maybe<Scalars['geometry']>;
  _nin?: Maybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: Maybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: Maybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains?: Maybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses?: Maybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: Maybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: Maybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: Maybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: Maybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: Maybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: Maybe<Scalars['geometry']>;
};

/** columns and relationships of "insurance_document" */
export type Insurance_Document = {
  __typename?: 'insurance_document';
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  date_filter?: Maybe<Insurance_Document_Date_Filter>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  file_url: Scalars['String'];
  id: Scalars['uuid'];
  invalid_reason?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  verified: Scalars['Boolean'];
};

/** aggregated selection of "insurance_document" */
export type Insurance_Document_Aggregate = {
  __typename?: 'insurance_document_aggregate';
  aggregate?: Maybe<Insurance_Document_Aggregate_Fields>;
  nodes: Array<Insurance_Document>;
};

/** aggregate fields of "insurance_document" */
export type Insurance_Document_Aggregate_Fields = {
  __typename?: 'insurance_document_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Insurance_Document_Max_Fields>;
  min?: Maybe<Insurance_Document_Min_Fields>;
};


/** aggregate fields of "insurance_document" */
export type Insurance_Document_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insurance_Document_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "insurance_document". All fields are combined with a logical 'AND'. */
export type Insurance_Document_Bool_Exp = {
  _and?: Maybe<Array<Insurance_Document_Bool_Exp>>;
  _not?: Maybe<Insurance_Document_Bool_Exp>;
  _or?: Maybe<Array<Insurance_Document_Bool_Exp>>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_filter?: Maybe<Insurance_Document_Date_Filter_Bool_Exp>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_url?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invalid_reason?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  verified?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "insurance_document" */
export enum Insurance_Document_Constraint {
  /** unique or primary key constraint */
  InsuranceDocumentPkey = 'insurance_document_pkey'
}

/** columns and relationships of "insurance_document_date_filter" */
export type Insurance_Document_Date_Filter = {
  __typename?: 'insurance_document_date_filter';
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  now?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "insurance_document_date_filter" */
export type Insurance_Document_Date_Filter_Aggregate = {
  __typename?: 'insurance_document_date_filter_aggregate';
  aggregate?: Maybe<Insurance_Document_Date_Filter_Aggregate_Fields>;
  nodes: Array<Insurance_Document_Date_Filter>;
};

/** aggregate fields of "insurance_document_date_filter" */
export type Insurance_Document_Date_Filter_Aggregate_Fields = {
  __typename?: 'insurance_document_date_filter_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Insurance_Document_Date_Filter_Max_Fields>;
  min?: Maybe<Insurance_Document_Date_Filter_Min_Fields>;
};


/** aggregate fields of "insurance_document_date_filter" */
export type Insurance_Document_Date_Filter_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insurance_Document_Date_Filter_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "insurance_document_date_filter". All fields are combined with a logical 'AND'. */
export type Insurance_Document_Date_Filter_Bool_Exp = {
  _and?: Maybe<Array<Insurance_Document_Date_Filter_Bool_Exp>>;
  _not?: Maybe<Insurance_Document_Date_Filter_Bool_Exp>;
  _or?: Maybe<Array<Insurance_Document_Date_Filter_Bool_Exp>>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  now?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "insurance_document_date_filter" */
export type Insurance_Document_Date_Filter_Insert_Input = {
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  now?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Insurance_Document_Date_Filter_Max_Fields = {
  __typename?: 'insurance_document_date_filter_max_fields';
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  now?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Insurance_Document_Date_Filter_Min_Fields = {
  __typename?: 'insurance_document_date_filter_min_fields';
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  now?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "insurance_document_date_filter" */
export type Insurance_Document_Date_Filter_Mutation_Response = {
  __typename?: 'insurance_document_date_filter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insurance_Document_Date_Filter>;
};

/** input type for inserting object relation for remote table "insurance_document_date_filter" */
export type Insurance_Document_Date_Filter_Obj_Rel_Insert_Input = {
  data: Insurance_Document_Date_Filter_Insert_Input;
};

/** Ordering options when selecting data from "insurance_document_date_filter". */
export type Insurance_Document_Date_Filter_Order_By = {
  expires_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  now?: Maybe<Order_By>;
};

/** select columns of table "insurance_document_date_filter" */
export enum Insurance_Document_Date_Filter_Select_Column {
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  Now = 'now'
}

/** input type for updating data in table "insurance_document_date_filter" */
export type Insurance_Document_Date_Filter_Set_Input = {
  expires_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  now?: Maybe<Scalars['timestamptz']>;
};

/** input type for inserting data into table "insurance_document" */
export type Insurance_Document_Insert_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_filter?: Maybe<Insurance_Document_Date_Filter_Obj_Rel_Insert_Input>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invalid_reason?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Insurance_Document_Max_Fields = {
  __typename?: 'insurance_document_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invalid_reason?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Insurance_Document_Min_Fields = {
  __typename?: 'insurance_document_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invalid_reason?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "insurance_document" */
export type Insurance_Document_Mutation_Response = {
  __typename?: 'insurance_document_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Insurance_Document>;
};

/** on conflict condition type for table "insurance_document" */
export type Insurance_Document_On_Conflict = {
  constraint: Insurance_Document_Constraint;
  update_columns?: Array<Insurance_Document_Update_Column>;
  where?: Maybe<Insurance_Document_Bool_Exp>;
};

/** Ordering options when selecting data from "insurance_document". */
export type Insurance_Document_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_filter?: Maybe<Insurance_Document_Date_Filter_Order_By>;
  expires_at?: Maybe<Order_By>;
  file_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invalid_reason?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  verified?: Maybe<Order_By>;
};

/** primary key columns input for table: insurance_document */
export type Insurance_Document_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "insurance_document" */
export enum Insurance_Document_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  InvalidReason = 'invalid_reason',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Verified = 'verified'
}

/** input type for updating data in table "insurance_document" */
export type Insurance_Document_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  file_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invalid_reason?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "insurance_document" */
export enum Insurance_Document_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  FileUrl = 'file_url',
  /** column name */
  Id = 'id',
  /** column name */
  InvalidReason = 'invalid_reason',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Verified = 'verified'
}


/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "location" */
export type Location = {
  __typename?: 'location';
  account_id: Scalars['uuid'];
  address: Scalars['String'];
  addressSecondary?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  coordinates?: Maybe<Scalars['geography']>;
  country: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  placeID: Scalars['String'];
  state: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  zipCode: Scalars['String'];
};

/** aggregated selection of "location" */
export type Location_Aggregate = {
  __typename?: 'location_aggregate';
  aggregate?: Maybe<Location_Aggregate_Fields>;
  nodes: Array<Location>;
};

/** aggregate fields of "location" */
export type Location_Aggregate_Fields = {
  __typename?: 'location_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Location_Max_Fields>;
  min?: Maybe<Location_Min_Fields>;
};


/** aggregate fields of "location" */
export type Location_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Location_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export type Location_Bool_Exp = {
  _and?: Maybe<Array<Location_Bool_Exp>>;
  _not?: Maybe<Location_Bool_Exp>;
  _or?: Maybe<Array<Location_Bool_Exp>>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  address?: Maybe<String_Comparison_Exp>;
  addressSecondary?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  coordinates?: Maybe<Geography_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  picture?: Maybe<String_Comparison_Exp>;
  placeID?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  zipCode?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "location" */
export enum Location_Constraint {
  /** unique or primary key constraint */
  LocationPkey = 'location_pkey'
}

/** input type for inserting data into table "location" */
export type Location_Insert_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  address?: Maybe<Scalars['String']>;
  addressSecondary?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Scalars['geography']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  placeID?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Location_Max_Fields = {
  __typename?: 'location_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  address?: Maybe<Scalars['String']>;
  addressSecondary?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  placeID?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Location_Min_Fields = {
  __typename?: 'location_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  address?: Maybe<Scalars['String']>;
  addressSecondary?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  placeID?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "location" */
export type Location_Mutation_Response = {
  __typename?: 'location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Location>;
};

/** input type for inserting object relation for remote table "location" */
export type Location_Obj_Rel_Insert_Input = {
  data: Location_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Location_On_Conflict>;
};

/** on conflict condition type for table "location" */
export type Location_On_Conflict = {
  constraint: Location_Constraint;
  update_columns?: Array<Location_Update_Column>;
  where?: Maybe<Location_Bool_Exp>;
};

/** Ordering options when selecting data from "location". */
export type Location_Order_By = {
  account_id?: Maybe<Order_By>;
  address?: Maybe<Order_By>;
  addressSecondary?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  coordinates?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  picture?: Maybe<Order_By>;
  placeID?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zipCode?: Maybe<Order_By>;
};

/** primary key columns input for table: location */
export type Location_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "location" */
export enum Location_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Address = 'address',
  /** column name */
  AddressSecondary = 'addressSecondary',
  /** column name */
  City = 'city',
  /** column name */
  Coordinates = 'coordinates',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Picture = 'picture',
  /** column name */
  PlaceId = 'placeID',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zipCode'
}

/** input type for updating data in table "location" */
export type Location_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  address?: Maybe<Scalars['String']>;
  addressSecondary?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Scalars['geography']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  placeID?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zipCode?: Maybe<Scalars['String']>;
};

/** update columns of table "location" */
export enum Location_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Address = 'address',
  /** column name */
  AddressSecondary = 'addressSecondary',
  /** column name */
  City = 'city',
  /** column name */
  Coordinates = 'coordinates',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Picture = 'picture',
  /** column name */
  PlaceId = 'placeID',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zipCode'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  addBankAccount?: Maybe<AddBankAccountOutput>;
  addCreditCard?: Maybe<AddCreditCardOutput>;
  agreeToTerms?: Maybe<AgreeToTermsOutput>;
  bookClass?: Maybe<BookClassOutput>;
  bookClassIntent?: Maybe<BookClassIntentOutput>;
  bookClassUpdateIntent?: Maybe<BookClassUpdateIntentOutput>;
  cancelBook?: Maybe<CancelBookOutput>;
  createCompanyAccount?: Maybe<CreateCompanyInfoOutput>;
  createIndividualAccount?: Maybe<CreateIndividualAccountOutput>;
  deleteBillingSource?: Maybe<DeleteBillingSourceOutput>;
  /** delete data from the table: "account" */
  delete_account?: Maybe<Account_Mutation_Response>;
  /** delete single row from the table: "account" */
  delete_account_by_pk?: Maybe<Account>;
  /** delete data from the table: "account_certification" */
  delete_account_certification?: Maybe<Account_Certification_Mutation_Response>;
  /** delete single row from the table: "account_certification" */
  delete_account_certification_by_pk?: Maybe<Account_Certification>;
  /** delete data from the table: "account_link" */
  delete_account_link?: Maybe<Account_Link_Mutation_Response>;
  /** delete single row from the table: "account_link" */
  delete_account_link_by_pk?: Maybe<Account_Link>;
  /** delete data from the table: "account_role" */
  delete_account_role?: Maybe<Account_Role_Mutation_Response>;
  /** delete single row from the table: "account_role" */
  delete_account_role_by_pk?: Maybe<Account_Role>;
  /** delete data from the table: "account_social_media" */
  delete_account_social_media?: Maybe<Account_Social_Media_Mutation_Response>;
  /** delete single row from the table: "account_social_media" */
  delete_account_social_media_by_pk?: Maybe<Account_Social_Media>;
  /** delete data from the table: "account_yoga_type" */
  delete_account_yoga_type?: Maybe<Account_Yoga_Type_Mutation_Response>;
  /** delete single row from the table: "account_yoga_type" */
  delete_account_yoga_type_by_pk?: Maybe<Account_Yoga_Type>;
  /** delete data from the table: "blog" */
  delete_blog?: Maybe<Blog_Mutation_Response>;
  /** delete single row from the table: "blog" */
  delete_blog_by_pk?: Maybe<Blog>;
  /** delete data from the table: "blog_type" */
  delete_blog_type?: Maybe<Blog_Type_Mutation_Response>;
  /** delete single row from the table: "blog_type" */
  delete_blog_type_by_pk?: Maybe<Blog_Type>;
  /** delete data from the table: "class" */
  delete_class?: Maybe<Class_Mutation_Response>;
  /** delete data from the table: "class_book" */
  delete_class_book?: Maybe<Class_Book_Mutation_Response>;
  /** delete single row from the table: "class_book" */
  delete_class_book_by_pk?: Maybe<Class_Book>;
  /** delete data from the table: "class_book_cancellation" */
  delete_class_book_cancellation?: Maybe<Class_Book_Cancellation_Mutation_Response>;
  /** delete single row from the table: "class_book_cancellation" */
  delete_class_book_cancellation_by_pk?: Maybe<Class_Book_Cancellation>;
  /** delete single row from the table: "class" */
  delete_class_by_pk?: Maybe<Class>;
  /** delete data from the table: "class_cost_type" */
  delete_class_cost_type?: Maybe<Class_Cost_Type_Mutation_Response>;
  /** delete single row from the table: "class_cost_type" */
  delete_class_cost_type_by_pk?: Maybe<Class_Cost_Type>;
  /** delete data from the table: "class_date_time" */
  delete_class_date_time?: Maybe<Class_Date_Time_Mutation_Response>;
  /** delete single row from the table: "class_date_time" */
  delete_class_date_time_by_pk?: Maybe<Class_Date_Time>;
  /** delete data from the table: "class_event" */
  delete_class_event?: Maybe<Class_Event_Mutation_Response>;
  /** delete single row from the table: "class_event" */
  delete_class_event_by_pk?: Maybe<Class_Event>;
  /** delete data from the table: "class_level" */
  delete_class_level?: Maybe<Class_Level_Mutation_Response>;
  /** delete single row from the table: "class_level" */
  delete_class_level_by_pk?: Maybe<Class_Level>;
  /** delete data from the table: "class_picture" */
  delete_class_picture?: Maybe<Class_Picture_Mutation_Response>;
  /** delete single row from the table: "class_picture" */
  delete_class_picture_by_pk?: Maybe<Class_Picture>;
  /** delete data from the table: "class_recurrence" */
  delete_class_recurrence?: Maybe<Class_Recurrence_Mutation_Response>;
  /** delete single row from the table: "class_recurrence" */
  delete_class_recurrence_by_pk?: Maybe<Class_Recurrence>;
  /** delete data from the table: "class_review" */
  delete_class_review?: Maybe<Class_Review_Mutation_Response>;
  /** delete single row from the table: "class_review" */
  delete_class_review_by_pk?: Maybe<Class_Review>;
  /** delete data from the table: "class_safety_guideline" */
  delete_class_safety_guideline?: Maybe<Class_Safety_Guideline_Mutation_Response>;
  /** delete single row from the table: "class_safety_guideline" */
  delete_class_safety_guideline_by_pk?: Maybe<Class_Safety_Guideline>;
  /** delete data from the table: "class_setting" */
  delete_class_setting?: Maybe<Class_Setting_Mutation_Response>;
  /** delete single row from the table: "class_setting" */
  delete_class_setting_by_pk?: Maybe<Class_Setting>;
  /** delete data from the table: "favorite_class" */
  delete_favorite_class?: Maybe<Favorite_Class_Mutation_Response>;
  /** delete single row from the table: "favorite_class" */
  delete_favorite_class_by_pk?: Maybe<Favorite_Class>;
  /** delete data from the table: "insurance_document" */
  delete_insurance_document?: Maybe<Insurance_Document_Mutation_Response>;
  /** delete single row from the table: "insurance_document" */
  delete_insurance_document_by_pk?: Maybe<Insurance_Document>;
  /** delete data from the table: "insurance_document_date_filter" */
  delete_insurance_document_date_filter?: Maybe<Insurance_Document_Date_Filter_Mutation_Response>;
  /** delete data from the table: "location" */
  delete_location?: Maybe<Location_Mutation_Response>;
  /** delete single row from the table: "location" */
  delete_location_by_pk?: Maybe<Location>;
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<Notification_Mutation_Response>;
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<Notification>;
  /** delete data from the table: "preferences" */
  delete_preferences?: Maybe<Preferences_Mutation_Response>;
  /** delete single row from the table: "preferences" */
  delete_preferences_by_pk?: Maybe<Preferences>;
  /** delete data from the table: "profile_entity" */
  delete_profile_entity?: Maybe<Profile_Entity_Mutation_Response>;
  /** delete single row from the table: "profile_entity" */
  delete_profile_entity_by_pk?: Maybe<Profile_Entity>;
  /** delete data from the table: "profile_person" */
  delete_profile_person?: Maybe<Profile_Person_Mutation_Response>;
  /** delete single row from the table: "profile_person" */
  delete_profile_person_by_pk?: Maybe<Profile_Person>;
  /** delete data from the table: "safety_guideline" */
  delete_safety_guideline?: Maybe<Safety_Guideline_Mutation_Response>;
  /** delete single row from the table: "safety_guideline" */
  delete_safety_guideline_by_pk?: Maybe<Safety_Guideline>;
  /** delete data from the table: "searching_history" */
  delete_searching_history?: Maybe<Searching_History_Mutation_Response>;
  /** delete single row from the table: "searching_history" */
  delete_searching_history_by_pk?: Maybe<Searching_History>;
  /** delete data from the table: "setting_notification" */
  delete_setting_notification?: Maybe<Setting_Notification_Mutation_Response>;
  /** delete single row from the table: "setting_notification" */
  delete_setting_notification_by_pk?: Maybe<Setting_Notification>;
  /** delete data from the table: "setting_notification_category" */
  delete_setting_notification_category?: Maybe<Setting_Notification_Category_Mutation_Response>;
  /** delete single row from the table: "setting_notification_category" */
  delete_setting_notification_category_by_pk?: Maybe<Setting_Notification_Category>;
  /** delete data from the table: "setting_notification_type" */
  delete_setting_notification_type?: Maybe<Setting_Notification_Type_Mutation_Response>;
  /** delete single row from the table: "setting_notification_type" */
  delete_setting_notification_type_by_pk?: Maybe<Setting_Notification_Type>;
  /** delete data from the table: "social_media_type" */
  delete_social_media_type?: Maybe<Social_Media_Type_Mutation_Response>;
  /** delete single row from the table: "social_media_type" */
  delete_social_media_type_by_pk?: Maybe<Social_Media_Type>;
  /** delete data from the table: "studio_picture" */
  delete_studio_picture?: Maybe<Studio_Picture_Mutation_Response>;
  /** delete single row from the table: "studio_picture" */
  delete_studio_picture_by_pk?: Maybe<Studio_Picture>;
  /** delete data from the table: "studio_review" */
  delete_studio_review?: Maybe<Studio_Review_Mutation_Response>;
  /** delete single row from the table: "studio_review" */
  delete_studio_review_by_pk?: Maybe<Studio_Review>;
  /** delete data from the table: "teacher" */
  delete_teacher?: Maybe<Teacher_Mutation_Response>;
  /** delete single row from the table: "teacher" */
  delete_teacher_by_pk?: Maybe<Teacher>;
  /** delete data from the table: "teacher_review" */
  delete_teacher_review?: Maybe<Teacher_Review_Mutation_Response>;
  /** delete single row from the table: "teacher_review" */
  delete_teacher_review_by_pk?: Maybe<Teacher_Review>;
  /** delete data from the table: "temp_coming_soon_student" */
  delete_temp_coming_soon_student?: Maybe<Temp_Coming_Soon_Student_Mutation_Response>;
  /** delete single row from the table: "temp_coming_soon_student" */
  delete_temp_coming_soon_student_by_pk?: Maybe<Temp_Coming_Soon_Student>;
  /** delete data from the table: "tip" */
  delete_tip?: Maybe<Tip_Mutation_Response>;
  /** delete single row from the table: "tip" */
  delete_tip_by_pk?: Maybe<Tip>;
  /** delete data from the table: "tip_current" */
  delete_tip_current?: Maybe<Tip_Current_Mutation_Response>;
  /** delete single row from the table: "tip_current" */
  delete_tip_current_by_pk?: Maybe<Tip_Current>;
  /** delete data from the table: "tip_source" */
  delete_tip_source?: Maybe<Tip_Source_Mutation_Response>;
  /** delete single row from the table: "tip_source" */
  delete_tip_source_by_pk?: Maybe<Tip_Source>;
  /** delete data from the table: "yoga_type" */
  delete_yoga_type?: Maybe<Yoga_Type_Mutation_Response>;
  /** delete single row from the table: "yoga_type" */
  delete_yoga_type_by_pk?: Maybe<Yoga_Type>;
  /** insert data into the table: "account" */
  insert_account?: Maybe<Account_Mutation_Response>;
  /** insert data into the table: "account_certification" */
  insert_account_certification?: Maybe<Account_Certification_Mutation_Response>;
  /** insert a single row into the table: "account_certification" */
  insert_account_certification_one?: Maybe<Account_Certification>;
  /** insert data into the table: "account_link" */
  insert_account_link?: Maybe<Account_Link_Mutation_Response>;
  /** insert a single row into the table: "account_link" */
  insert_account_link_one?: Maybe<Account_Link>;
  /** insert a single row into the table: "account" */
  insert_account_one?: Maybe<Account>;
  /** insert data into the table: "account_role" */
  insert_account_role?: Maybe<Account_Role_Mutation_Response>;
  /** insert a single row into the table: "account_role" */
  insert_account_role_one?: Maybe<Account_Role>;
  /** insert data into the table: "account_social_media" */
  insert_account_social_media?: Maybe<Account_Social_Media_Mutation_Response>;
  /** insert a single row into the table: "account_social_media" */
  insert_account_social_media_one?: Maybe<Account_Social_Media>;
  /** insert data into the table: "account_yoga_type" */
  insert_account_yoga_type?: Maybe<Account_Yoga_Type_Mutation_Response>;
  /** insert a single row into the table: "account_yoga_type" */
  insert_account_yoga_type_one?: Maybe<Account_Yoga_Type>;
  /** insert data into the table: "blog" */
  insert_blog?: Maybe<Blog_Mutation_Response>;
  /** insert a single row into the table: "blog" */
  insert_blog_one?: Maybe<Blog>;
  /** insert data into the table: "blog_type" */
  insert_blog_type?: Maybe<Blog_Type_Mutation_Response>;
  /** insert a single row into the table: "blog_type" */
  insert_blog_type_one?: Maybe<Blog_Type>;
  /** insert data into the table: "class" */
  insert_class?: Maybe<Class_Mutation_Response>;
  /** insert data into the table: "class_book" */
  insert_class_book?: Maybe<Class_Book_Mutation_Response>;
  /** insert data into the table: "class_book_cancellation" */
  insert_class_book_cancellation?: Maybe<Class_Book_Cancellation_Mutation_Response>;
  /** insert a single row into the table: "class_book_cancellation" */
  insert_class_book_cancellation_one?: Maybe<Class_Book_Cancellation>;
  /** insert a single row into the table: "class_book" */
  insert_class_book_one?: Maybe<Class_Book>;
  /** insert data into the table: "class_cost_type" */
  insert_class_cost_type?: Maybe<Class_Cost_Type_Mutation_Response>;
  /** insert a single row into the table: "class_cost_type" */
  insert_class_cost_type_one?: Maybe<Class_Cost_Type>;
  /** insert data into the table: "class_date_time" */
  insert_class_date_time?: Maybe<Class_Date_Time_Mutation_Response>;
  /** insert a single row into the table: "class_date_time" */
  insert_class_date_time_one?: Maybe<Class_Date_Time>;
  /** insert data into the table: "class_event" */
  insert_class_event?: Maybe<Class_Event_Mutation_Response>;
  /** insert a single row into the table: "class_event" */
  insert_class_event_one?: Maybe<Class_Event>;
  /** insert data into the table: "class_level" */
  insert_class_level?: Maybe<Class_Level_Mutation_Response>;
  /** insert a single row into the table: "class_level" */
  insert_class_level_one?: Maybe<Class_Level>;
  /** insert a single row into the table: "class" */
  insert_class_one?: Maybe<Class>;
  /** insert data into the table: "class_picture" */
  insert_class_picture?: Maybe<Class_Picture_Mutation_Response>;
  /** insert a single row into the table: "class_picture" */
  insert_class_picture_one?: Maybe<Class_Picture>;
  /** insert data into the table: "class_recurrence" */
  insert_class_recurrence?: Maybe<Class_Recurrence_Mutation_Response>;
  /** insert a single row into the table: "class_recurrence" */
  insert_class_recurrence_one?: Maybe<Class_Recurrence>;
  /** insert data into the table: "class_review" */
  insert_class_review?: Maybe<Class_Review_Mutation_Response>;
  /** insert a single row into the table: "class_review" */
  insert_class_review_one?: Maybe<Class_Review>;
  /** insert data into the table: "class_safety_guideline" */
  insert_class_safety_guideline?: Maybe<Class_Safety_Guideline_Mutation_Response>;
  /** insert a single row into the table: "class_safety_guideline" */
  insert_class_safety_guideline_one?: Maybe<Class_Safety_Guideline>;
  /** insert data into the table: "class_setting" */
  insert_class_setting?: Maybe<Class_Setting_Mutation_Response>;
  /** insert a single row into the table: "class_setting" */
  insert_class_setting_one?: Maybe<Class_Setting>;
  /** insert data into the table: "favorite_class" */
  insert_favorite_class?: Maybe<Favorite_Class_Mutation_Response>;
  /** insert a single row into the table: "favorite_class" */
  insert_favorite_class_one?: Maybe<Favorite_Class>;
  /** insert data into the table: "insurance_document" */
  insert_insurance_document?: Maybe<Insurance_Document_Mutation_Response>;
  /** insert data into the table: "insurance_document_date_filter" */
  insert_insurance_document_date_filter?: Maybe<Insurance_Document_Date_Filter_Mutation_Response>;
  /** insert a single row into the table: "insurance_document_date_filter" */
  insert_insurance_document_date_filter_one?: Maybe<Insurance_Document_Date_Filter>;
  /** insert a single row into the table: "insurance_document" */
  insert_insurance_document_one?: Maybe<Insurance_Document>;
  /** insert data into the table: "location" */
  insert_location?: Maybe<Location_Mutation_Response>;
  /** insert a single row into the table: "location" */
  insert_location_one?: Maybe<Location>;
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<Notification_Mutation_Response>;
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<Notification>;
  /** insert data into the table: "preferences" */
  insert_preferences?: Maybe<Preferences_Mutation_Response>;
  /** insert a single row into the table: "preferences" */
  insert_preferences_one?: Maybe<Preferences>;
  /** insert data into the table: "profile_entity" */
  insert_profile_entity?: Maybe<Profile_Entity_Mutation_Response>;
  /** insert a single row into the table: "profile_entity" */
  insert_profile_entity_one?: Maybe<Profile_Entity>;
  /** insert data into the table: "profile_person" */
  insert_profile_person?: Maybe<Profile_Person_Mutation_Response>;
  /** insert a single row into the table: "profile_person" */
  insert_profile_person_one?: Maybe<Profile_Person>;
  /** insert data into the table: "safety_guideline" */
  insert_safety_guideline?: Maybe<Safety_Guideline_Mutation_Response>;
  /** insert a single row into the table: "safety_guideline" */
  insert_safety_guideline_one?: Maybe<Safety_Guideline>;
  /** insert data into the table: "searching_history" */
  insert_searching_history?: Maybe<Searching_History_Mutation_Response>;
  /** insert a single row into the table: "searching_history" */
  insert_searching_history_one?: Maybe<Searching_History>;
  /** insert data into the table: "setting_notification" */
  insert_setting_notification?: Maybe<Setting_Notification_Mutation_Response>;
  /** insert data into the table: "setting_notification_category" */
  insert_setting_notification_category?: Maybe<Setting_Notification_Category_Mutation_Response>;
  /** insert a single row into the table: "setting_notification_category" */
  insert_setting_notification_category_one?: Maybe<Setting_Notification_Category>;
  /** insert a single row into the table: "setting_notification" */
  insert_setting_notification_one?: Maybe<Setting_Notification>;
  /** insert data into the table: "setting_notification_type" */
  insert_setting_notification_type?: Maybe<Setting_Notification_Type_Mutation_Response>;
  /** insert a single row into the table: "setting_notification_type" */
  insert_setting_notification_type_one?: Maybe<Setting_Notification_Type>;
  /** insert data into the table: "social_media_type" */
  insert_social_media_type?: Maybe<Social_Media_Type_Mutation_Response>;
  /** insert a single row into the table: "social_media_type" */
  insert_social_media_type_one?: Maybe<Social_Media_Type>;
  /** insert data into the table: "studio_picture" */
  insert_studio_picture?: Maybe<Studio_Picture_Mutation_Response>;
  /** insert a single row into the table: "studio_picture" */
  insert_studio_picture_one?: Maybe<Studio_Picture>;
  /** insert data into the table: "studio_review" */
  insert_studio_review?: Maybe<Studio_Review_Mutation_Response>;
  /** insert a single row into the table: "studio_review" */
  insert_studio_review_one?: Maybe<Studio_Review>;
  /** insert data into the table: "teacher" */
  insert_teacher?: Maybe<Teacher_Mutation_Response>;
  /** insert a single row into the table: "teacher" */
  insert_teacher_one?: Maybe<Teacher>;
  /** insert data into the table: "teacher_review" */
  insert_teacher_review?: Maybe<Teacher_Review_Mutation_Response>;
  /** insert a single row into the table: "teacher_review" */
  insert_teacher_review_one?: Maybe<Teacher_Review>;
  /** insert data into the table: "temp_coming_soon_student" */
  insert_temp_coming_soon_student?: Maybe<Temp_Coming_Soon_Student_Mutation_Response>;
  /** insert a single row into the table: "temp_coming_soon_student" */
  insert_temp_coming_soon_student_one?: Maybe<Temp_Coming_Soon_Student>;
  /** insert data into the table: "tip" */
  insert_tip?: Maybe<Tip_Mutation_Response>;
  /** insert data into the table: "tip_current" */
  insert_tip_current?: Maybe<Tip_Current_Mutation_Response>;
  /** insert a single row into the table: "tip_current" */
  insert_tip_current_one?: Maybe<Tip_Current>;
  /** insert a single row into the table: "tip" */
  insert_tip_one?: Maybe<Tip>;
  /** insert data into the table: "tip_source" */
  insert_tip_source?: Maybe<Tip_Source_Mutation_Response>;
  /** insert a single row into the table: "tip_source" */
  insert_tip_source_one?: Maybe<Tip_Source>;
  /** insert data into the table: "yoga_type" */
  insert_yoga_type?: Maybe<Yoga_Type_Mutation_Response>;
  /** insert a single row into the table: "yoga_type" */
  insert_yoga_type_one?: Maybe<Yoga_Type>;
  login?: Maybe<LoginOutput>;
  loginVerify?: Maybe<LoginVerifyOutput>;
  signupStudent?: Maybe<SignupStudentOutput>;
  signupStudio?: Maybe<SignupStudioOutput>;
  signupTeacher?: Maybe<SignupTeacherOutput>;
  updateClassEventMeetingLinkByWebID?: Maybe<UpdateClassEventMeetingLinkByWebIdOutput>;
  updateDefaultSource?: Maybe<UpdateDefaultSourceOutput>;
  /** update data of the table: "account" */
  update_account?: Maybe<Account_Mutation_Response>;
  /** update single row of the table: "account" */
  update_account_by_pk?: Maybe<Account>;
  /** update data of the table: "account_certification" */
  update_account_certification?: Maybe<Account_Certification_Mutation_Response>;
  /** update single row of the table: "account_certification" */
  update_account_certification_by_pk?: Maybe<Account_Certification>;
  /** update data of the table: "account_link" */
  update_account_link?: Maybe<Account_Link_Mutation_Response>;
  /** update single row of the table: "account_link" */
  update_account_link_by_pk?: Maybe<Account_Link>;
  /** update data of the table: "account_role" */
  update_account_role?: Maybe<Account_Role_Mutation_Response>;
  /** update single row of the table: "account_role" */
  update_account_role_by_pk?: Maybe<Account_Role>;
  /** update data of the table: "account_social_media" */
  update_account_social_media?: Maybe<Account_Social_Media_Mutation_Response>;
  /** update single row of the table: "account_social_media" */
  update_account_social_media_by_pk?: Maybe<Account_Social_Media>;
  /** update data of the table: "account_yoga_type" */
  update_account_yoga_type?: Maybe<Account_Yoga_Type_Mutation_Response>;
  /** update single row of the table: "account_yoga_type" */
  update_account_yoga_type_by_pk?: Maybe<Account_Yoga_Type>;
  /** update data of the table: "blog" */
  update_blog?: Maybe<Blog_Mutation_Response>;
  /** update single row of the table: "blog" */
  update_blog_by_pk?: Maybe<Blog>;
  /** update data of the table: "blog_type" */
  update_blog_type?: Maybe<Blog_Type_Mutation_Response>;
  /** update single row of the table: "blog_type" */
  update_blog_type_by_pk?: Maybe<Blog_Type>;
  /** update data of the table: "class" */
  update_class?: Maybe<Class_Mutation_Response>;
  /** update data of the table: "class_book" */
  update_class_book?: Maybe<Class_Book_Mutation_Response>;
  /** update single row of the table: "class_book" */
  update_class_book_by_pk?: Maybe<Class_Book>;
  /** update data of the table: "class_book_cancellation" */
  update_class_book_cancellation?: Maybe<Class_Book_Cancellation_Mutation_Response>;
  /** update single row of the table: "class_book_cancellation" */
  update_class_book_cancellation_by_pk?: Maybe<Class_Book_Cancellation>;
  /** update single row of the table: "class" */
  update_class_by_pk?: Maybe<Class>;
  /** update data of the table: "class_cost_type" */
  update_class_cost_type?: Maybe<Class_Cost_Type_Mutation_Response>;
  /** update single row of the table: "class_cost_type" */
  update_class_cost_type_by_pk?: Maybe<Class_Cost_Type>;
  /** update data of the table: "class_date_time" */
  update_class_date_time?: Maybe<Class_Date_Time_Mutation_Response>;
  /** update single row of the table: "class_date_time" */
  update_class_date_time_by_pk?: Maybe<Class_Date_Time>;
  /** update data of the table: "class_event" */
  update_class_event?: Maybe<Class_Event_Mutation_Response>;
  /** update single row of the table: "class_event" */
  update_class_event_by_pk?: Maybe<Class_Event>;
  /** update data of the table: "class_level" */
  update_class_level?: Maybe<Class_Level_Mutation_Response>;
  /** update single row of the table: "class_level" */
  update_class_level_by_pk?: Maybe<Class_Level>;
  /** update data of the table: "class_picture" */
  update_class_picture?: Maybe<Class_Picture_Mutation_Response>;
  /** update single row of the table: "class_picture" */
  update_class_picture_by_pk?: Maybe<Class_Picture>;
  /** update data of the table: "class_recurrence" */
  update_class_recurrence?: Maybe<Class_Recurrence_Mutation_Response>;
  /** update single row of the table: "class_recurrence" */
  update_class_recurrence_by_pk?: Maybe<Class_Recurrence>;
  /** update data of the table: "class_review" */
  update_class_review?: Maybe<Class_Review_Mutation_Response>;
  /** update single row of the table: "class_review" */
  update_class_review_by_pk?: Maybe<Class_Review>;
  /** update data of the table: "class_safety_guideline" */
  update_class_safety_guideline?: Maybe<Class_Safety_Guideline_Mutation_Response>;
  /** update single row of the table: "class_safety_guideline" */
  update_class_safety_guideline_by_pk?: Maybe<Class_Safety_Guideline>;
  /** update data of the table: "class_setting" */
  update_class_setting?: Maybe<Class_Setting_Mutation_Response>;
  /** update single row of the table: "class_setting" */
  update_class_setting_by_pk?: Maybe<Class_Setting>;
  /** update data of the table: "favorite_class" */
  update_favorite_class?: Maybe<Favorite_Class_Mutation_Response>;
  /** update single row of the table: "favorite_class" */
  update_favorite_class_by_pk?: Maybe<Favorite_Class>;
  /** update data of the table: "insurance_document" */
  update_insurance_document?: Maybe<Insurance_Document_Mutation_Response>;
  /** update single row of the table: "insurance_document" */
  update_insurance_document_by_pk?: Maybe<Insurance_Document>;
  /** update data of the table: "insurance_document_date_filter" */
  update_insurance_document_date_filter?: Maybe<Insurance_Document_Date_Filter_Mutation_Response>;
  /** update data of the table: "location" */
  update_location?: Maybe<Location_Mutation_Response>;
  /** update single row of the table: "location" */
  update_location_by_pk?: Maybe<Location>;
  /** update data of the table: "notification" */
  update_notification?: Maybe<Notification_Mutation_Response>;
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<Notification>;
  /** update data of the table: "preferences" */
  update_preferences?: Maybe<Preferences_Mutation_Response>;
  /** update single row of the table: "preferences" */
  update_preferences_by_pk?: Maybe<Preferences>;
  /** update data of the table: "profile_entity" */
  update_profile_entity?: Maybe<Profile_Entity_Mutation_Response>;
  /** update single row of the table: "profile_entity" */
  update_profile_entity_by_pk?: Maybe<Profile_Entity>;
  /** update data of the table: "profile_person" */
  update_profile_person?: Maybe<Profile_Person_Mutation_Response>;
  /** update single row of the table: "profile_person" */
  update_profile_person_by_pk?: Maybe<Profile_Person>;
  /** update data of the table: "safety_guideline" */
  update_safety_guideline?: Maybe<Safety_Guideline_Mutation_Response>;
  /** update single row of the table: "safety_guideline" */
  update_safety_guideline_by_pk?: Maybe<Safety_Guideline>;
  /** update data of the table: "searching_history" */
  update_searching_history?: Maybe<Searching_History_Mutation_Response>;
  /** update single row of the table: "searching_history" */
  update_searching_history_by_pk?: Maybe<Searching_History>;
  /** update data of the table: "setting_notification" */
  update_setting_notification?: Maybe<Setting_Notification_Mutation_Response>;
  /** update single row of the table: "setting_notification" */
  update_setting_notification_by_pk?: Maybe<Setting_Notification>;
  /** update data of the table: "setting_notification_category" */
  update_setting_notification_category?: Maybe<Setting_Notification_Category_Mutation_Response>;
  /** update single row of the table: "setting_notification_category" */
  update_setting_notification_category_by_pk?: Maybe<Setting_Notification_Category>;
  /** update data of the table: "setting_notification_type" */
  update_setting_notification_type?: Maybe<Setting_Notification_Type_Mutation_Response>;
  /** update single row of the table: "setting_notification_type" */
  update_setting_notification_type_by_pk?: Maybe<Setting_Notification_Type>;
  /** update data of the table: "social_media_type" */
  update_social_media_type?: Maybe<Social_Media_Type_Mutation_Response>;
  /** update single row of the table: "social_media_type" */
  update_social_media_type_by_pk?: Maybe<Social_Media_Type>;
  /** update data of the table: "studio_picture" */
  update_studio_picture?: Maybe<Studio_Picture_Mutation_Response>;
  /** update single row of the table: "studio_picture" */
  update_studio_picture_by_pk?: Maybe<Studio_Picture>;
  /** update data of the table: "studio_review" */
  update_studio_review?: Maybe<Studio_Review_Mutation_Response>;
  /** update single row of the table: "studio_review" */
  update_studio_review_by_pk?: Maybe<Studio_Review>;
  /** update data of the table: "teacher" */
  update_teacher?: Maybe<Teacher_Mutation_Response>;
  /** update single row of the table: "teacher" */
  update_teacher_by_pk?: Maybe<Teacher>;
  /** update data of the table: "teacher_review" */
  update_teacher_review?: Maybe<Teacher_Review_Mutation_Response>;
  /** update single row of the table: "teacher_review" */
  update_teacher_review_by_pk?: Maybe<Teacher_Review>;
  /** update data of the table: "temp_coming_soon_student" */
  update_temp_coming_soon_student?: Maybe<Temp_Coming_Soon_Student_Mutation_Response>;
  /** update single row of the table: "temp_coming_soon_student" */
  update_temp_coming_soon_student_by_pk?: Maybe<Temp_Coming_Soon_Student>;
  /** update data of the table: "tip" */
  update_tip?: Maybe<Tip_Mutation_Response>;
  /** update single row of the table: "tip" */
  update_tip_by_pk?: Maybe<Tip>;
  /** update data of the table: "tip_current" */
  update_tip_current?: Maybe<Tip_Current_Mutation_Response>;
  /** update single row of the table: "tip_current" */
  update_tip_current_by_pk?: Maybe<Tip_Current>;
  /** update data of the table: "tip_source" */
  update_tip_source?: Maybe<Tip_Source_Mutation_Response>;
  /** update single row of the table: "tip_source" */
  update_tip_source_by_pk?: Maybe<Tip_Source>;
  /** update data of the table: "yoga_type" */
  update_yoga_type?: Maybe<Yoga_Type_Mutation_Response>;
  /** update single row of the table: "yoga_type" */
  update_yoga_type_by_pk?: Maybe<Yoga_Type>;
  uploadInsuranceDocument?: Maybe<UploadInsuranceDocumentOutput>;
  uploadPicture?: Maybe<UploadPictureOutput>;
};


/** mutation root */
export type Mutation_RootAddBankAccountArgs = {
  bankToken: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAddCreditCardArgs = {
  cardToken: Scalars['String'];
};


/** mutation root */
export type Mutation_RootBookClassArgs = {
  object?: Maybe<BookClassInput>;
};


/** mutation root */
export type Mutation_RootBookClassIntentArgs = {
  object?: Maybe<BookClassIntentInput>;
};


/** mutation root */
export type Mutation_RootBookClassUpdateIntentArgs = {
  object?: Maybe<BookClassUpdateIntentInput>;
};


/** mutation root */
export type Mutation_RootCancelBookArgs = {
  classBookID: Scalars['uuid'];
  comments?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateCompanyAccountArgs = {
  address: CompanyAddress;
  name: Scalars['String'];
  taxID: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootCreateIndividualAccountArgs = {
  address: IndividualAddress;
  birthday: Scalars['String'];
  firstName: Scalars['String'];
  last4ssn: Scalars['Int'];
  lastName: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteBillingSourceArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AccountArgs = {
  where: Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Account_CertificationArgs = {
  where: Account_Certification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Certification_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Account_LinkArgs = {
  where: Account_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Link_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Account_RoleArgs = {
  where: Account_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Role_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Account_Social_MediaArgs = {
  where: Account_Social_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Social_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Account_Yoga_TypeArgs = {
  where: Account_Yoga_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Yoga_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_BlogArgs = {
  where: Blog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blog_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Blog_TypeArgs = {
  where: Blog_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blog_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ClassArgs = {
  where: Class_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_BookArgs = {
  where: Class_Book_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Book_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Class_Book_CancellationArgs = {
  where: Class_Book_Cancellation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Book_Cancellation_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Class_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Class_Cost_TypeArgs = {
  where: Class_Cost_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Cost_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Class_Date_TimeArgs = {
  where: Class_Date_Time_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Date_Time_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Class_EventArgs = {
  where: Class_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Class_LevelArgs = {
  where: Class_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Level_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Class_PictureArgs = {
  where: Class_Picture_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Class_RecurrenceArgs = {
  where: Class_Recurrence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Recurrence_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Class_ReviewArgs = {
  where: Class_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Class_Safety_GuidelineArgs = {
  where: Class_Safety_Guideline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Safety_Guideline_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Class_SettingArgs = {
  where: Class_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Class_Setting_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Favorite_ClassArgs = {
  where: Favorite_Class_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Favorite_Class_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Insurance_DocumentArgs = {
  where: Insurance_Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insurance_Document_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Insurance_Document_Date_FilterArgs = {
  where: Insurance_Document_Date_Filter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LocationArgs = {
  where: Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Location_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationArgs = {
  where: Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PreferencesArgs = {
  where: Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Preferences_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_EntityArgs = {
  where: Profile_Entity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Entity_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Profile_PersonArgs = {
  where: Profile_Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profile_Person_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Safety_GuidelineArgs = {
  where: Safety_Guideline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Safety_Guideline_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Searching_HistoryArgs = {
  where: Searching_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Searching_History_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Setting_NotificationArgs = {
  where: Setting_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Setting_Notification_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Setting_Notification_CategoryArgs = {
  where: Setting_Notification_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Setting_Notification_Category_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Setting_Notification_TypeArgs = {
  where: Setting_Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Setting_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Social_Media_TypeArgs = {
  where: Social_Media_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Social_Media_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Studio_PictureArgs = {
  where: Studio_Picture_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Studio_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Studio_ReviewArgs = {
  where: Studio_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Studio_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TeacherArgs = {
  where: Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Teacher_ReviewArgs = {
  where: Teacher_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Teacher_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Temp_Coming_Soon_StudentArgs = {
  where: Temp_Coming_Soon_Student_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Temp_Coming_Soon_Student_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TipArgs = {
  where: Tip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tip_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Tip_CurrentArgs = {
  where: Tip_Current_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tip_Current_By_PkArgs = {
  good_for: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Tip_SourceArgs = {
  where: Tip_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tip_Source_By_PkArgs = {
  source: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Yoga_TypeArgs = {
  where: Yoga_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Yoga_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_AccountArgs = {
  objects: Array<Account_Insert_Input>;
  on_conflict?: Maybe<Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_CertificationArgs = {
  objects: Array<Account_Certification_Insert_Input>;
  on_conflict?: Maybe<Account_Certification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Certification_OneArgs = {
  object: Account_Certification_Insert_Input;
  on_conflict?: Maybe<Account_Certification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_LinkArgs = {
  objects: Array<Account_Link_Insert_Input>;
  on_conflict?: Maybe<Account_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Link_OneArgs = {
  object: Account_Link_Insert_Input;
  on_conflict?: Maybe<Account_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_OneArgs = {
  object: Account_Insert_Input;
  on_conflict?: Maybe<Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_RoleArgs = {
  objects: Array<Account_Role_Insert_Input>;
  on_conflict?: Maybe<Account_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Role_OneArgs = {
  object: Account_Role_Insert_Input;
  on_conflict?: Maybe<Account_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Social_MediaArgs = {
  objects: Array<Account_Social_Media_Insert_Input>;
  on_conflict?: Maybe<Account_Social_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Social_Media_OneArgs = {
  object: Account_Social_Media_Insert_Input;
  on_conflict?: Maybe<Account_Social_Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Yoga_TypeArgs = {
  objects: Array<Account_Yoga_Type_Insert_Input>;
  on_conflict?: Maybe<Account_Yoga_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Yoga_Type_OneArgs = {
  object: Account_Yoga_Type_Insert_Input;
  on_conflict?: Maybe<Account_Yoga_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BlogArgs = {
  objects: Array<Blog_Insert_Input>;
  on_conflict?: Maybe<Blog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_OneArgs = {
  object: Blog_Insert_Input;
  on_conflict?: Maybe<Blog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_TypeArgs = {
  objects: Array<Blog_Type_Insert_Input>;
  on_conflict?: Maybe<Blog_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_Type_OneArgs = {
  object: Blog_Type_Insert_Input;
  on_conflict?: Maybe<Blog_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClassArgs = {
  objects: Array<Class_Insert_Input>;
  on_conflict?: Maybe<Class_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_BookArgs = {
  objects: Array<Class_Book_Insert_Input>;
  on_conflict?: Maybe<Class_Book_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Book_CancellationArgs = {
  objects: Array<Class_Book_Cancellation_Insert_Input>;
  on_conflict?: Maybe<Class_Book_Cancellation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Book_Cancellation_OneArgs = {
  object: Class_Book_Cancellation_Insert_Input;
  on_conflict?: Maybe<Class_Book_Cancellation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Book_OneArgs = {
  object: Class_Book_Insert_Input;
  on_conflict?: Maybe<Class_Book_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Cost_TypeArgs = {
  objects: Array<Class_Cost_Type_Insert_Input>;
  on_conflict?: Maybe<Class_Cost_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Cost_Type_OneArgs = {
  object: Class_Cost_Type_Insert_Input;
  on_conflict?: Maybe<Class_Cost_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Date_TimeArgs = {
  objects: Array<Class_Date_Time_Insert_Input>;
  on_conflict?: Maybe<Class_Date_Time_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Date_Time_OneArgs = {
  object: Class_Date_Time_Insert_Input;
  on_conflict?: Maybe<Class_Date_Time_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_EventArgs = {
  objects: Array<Class_Event_Insert_Input>;
  on_conflict?: Maybe<Class_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Event_OneArgs = {
  object: Class_Event_Insert_Input;
  on_conflict?: Maybe<Class_Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_LevelArgs = {
  objects: Array<Class_Level_Insert_Input>;
  on_conflict?: Maybe<Class_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Level_OneArgs = {
  object: Class_Level_Insert_Input;
  on_conflict?: Maybe<Class_Level_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_OneArgs = {
  object: Class_Insert_Input;
  on_conflict?: Maybe<Class_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_PictureArgs = {
  objects: Array<Class_Picture_Insert_Input>;
  on_conflict?: Maybe<Class_Picture_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Picture_OneArgs = {
  object: Class_Picture_Insert_Input;
  on_conflict?: Maybe<Class_Picture_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_RecurrenceArgs = {
  objects: Array<Class_Recurrence_Insert_Input>;
  on_conflict?: Maybe<Class_Recurrence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Recurrence_OneArgs = {
  object: Class_Recurrence_Insert_Input;
  on_conflict?: Maybe<Class_Recurrence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_ReviewArgs = {
  objects: Array<Class_Review_Insert_Input>;
  on_conflict?: Maybe<Class_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Review_OneArgs = {
  object: Class_Review_Insert_Input;
  on_conflict?: Maybe<Class_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Safety_GuidelineArgs = {
  objects: Array<Class_Safety_Guideline_Insert_Input>;
  on_conflict?: Maybe<Class_Safety_Guideline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Safety_Guideline_OneArgs = {
  object: Class_Safety_Guideline_Insert_Input;
  on_conflict?: Maybe<Class_Safety_Guideline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_SettingArgs = {
  objects: Array<Class_Setting_Insert_Input>;
  on_conflict?: Maybe<Class_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Class_Setting_OneArgs = {
  object: Class_Setting_Insert_Input;
  on_conflict?: Maybe<Class_Setting_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Favorite_ClassArgs = {
  objects: Array<Favorite_Class_Insert_Input>;
  on_conflict?: Maybe<Favorite_Class_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Favorite_Class_OneArgs = {
  object: Favorite_Class_Insert_Input;
  on_conflict?: Maybe<Favorite_Class_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_DocumentArgs = {
  objects: Array<Insurance_Document_Insert_Input>;
  on_conflict?: Maybe<Insurance_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_Document_Date_FilterArgs = {
  objects: Array<Insurance_Document_Date_Filter_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_Document_Date_Filter_OneArgs = {
  object: Insurance_Document_Date_Filter_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_Document_OneArgs = {
  object: Insurance_Document_Insert_Input;
  on_conflict?: Maybe<Insurance_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocationArgs = {
  objects: Array<Location_Insert_Input>;
  on_conflict?: Maybe<Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Location_OneArgs = {
  object: Location_Insert_Input;
  on_conflict?: Maybe<Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationArgs = {
  objects: Array<Notification_Insert_Input>;
  on_conflict?: Maybe<Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_OneArgs = {
  object: Notification_Insert_Input;
  on_conflict?: Maybe<Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PreferencesArgs = {
  objects: Array<Preferences_Insert_Input>;
  on_conflict?: Maybe<Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Preferences_OneArgs = {
  object: Preferences_Insert_Input;
  on_conflict?: Maybe<Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_EntityArgs = {
  objects: Array<Profile_Entity_Insert_Input>;
  on_conflict?: Maybe<Profile_Entity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Entity_OneArgs = {
  object: Profile_Entity_Insert_Input;
  on_conflict?: Maybe<Profile_Entity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_PersonArgs = {
  objects: Array<Profile_Person_Insert_Input>;
  on_conflict?: Maybe<Profile_Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profile_Person_OneArgs = {
  object: Profile_Person_Insert_Input;
  on_conflict?: Maybe<Profile_Person_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Safety_GuidelineArgs = {
  objects: Array<Safety_Guideline_Insert_Input>;
  on_conflict?: Maybe<Safety_Guideline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Safety_Guideline_OneArgs = {
  object: Safety_Guideline_Insert_Input;
  on_conflict?: Maybe<Safety_Guideline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Searching_HistoryArgs = {
  objects: Array<Searching_History_Insert_Input>;
  on_conflict?: Maybe<Searching_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Searching_History_OneArgs = {
  object: Searching_History_Insert_Input;
  on_conflict?: Maybe<Searching_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Setting_NotificationArgs = {
  objects: Array<Setting_Notification_Insert_Input>;
  on_conflict?: Maybe<Setting_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Setting_Notification_CategoryArgs = {
  objects: Array<Setting_Notification_Category_Insert_Input>;
  on_conflict?: Maybe<Setting_Notification_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Setting_Notification_Category_OneArgs = {
  object: Setting_Notification_Category_Insert_Input;
  on_conflict?: Maybe<Setting_Notification_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Setting_Notification_OneArgs = {
  object: Setting_Notification_Insert_Input;
  on_conflict?: Maybe<Setting_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Setting_Notification_TypeArgs = {
  objects: Array<Setting_Notification_Type_Insert_Input>;
  on_conflict?: Maybe<Setting_Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Setting_Notification_Type_OneArgs = {
  object: Setting_Notification_Type_Insert_Input;
  on_conflict?: Maybe<Setting_Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Social_Media_TypeArgs = {
  objects: Array<Social_Media_Type_Insert_Input>;
  on_conflict?: Maybe<Social_Media_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Social_Media_Type_OneArgs = {
  object: Social_Media_Type_Insert_Input;
  on_conflict?: Maybe<Social_Media_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Studio_PictureArgs = {
  objects: Array<Studio_Picture_Insert_Input>;
  on_conflict?: Maybe<Studio_Picture_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Studio_Picture_OneArgs = {
  object: Studio_Picture_Insert_Input;
  on_conflict?: Maybe<Studio_Picture_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Studio_ReviewArgs = {
  objects: Array<Studio_Review_Insert_Input>;
  on_conflict?: Maybe<Studio_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Studio_Review_OneArgs = {
  object: Studio_Review_Insert_Input;
  on_conflict?: Maybe<Studio_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TeacherArgs = {
  objects: Array<Teacher_Insert_Input>;
  on_conflict?: Maybe<Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_OneArgs = {
  object: Teacher_Insert_Input;
  on_conflict?: Maybe<Teacher_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_ReviewArgs = {
  objects: Array<Teacher_Review_Insert_Input>;
  on_conflict?: Maybe<Teacher_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Teacher_Review_OneArgs = {
  object: Teacher_Review_Insert_Input;
  on_conflict?: Maybe<Teacher_Review_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Temp_Coming_Soon_StudentArgs = {
  objects: Array<Temp_Coming_Soon_Student_Insert_Input>;
  on_conflict?: Maybe<Temp_Coming_Soon_Student_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Temp_Coming_Soon_Student_OneArgs = {
  object: Temp_Coming_Soon_Student_Insert_Input;
  on_conflict?: Maybe<Temp_Coming_Soon_Student_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TipArgs = {
  objects: Array<Tip_Insert_Input>;
  on_conflict?: Maybe<Tip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_CurrentArgs = {
  objects: Array<Tip_Current_Insert_Input>;
  on_conflict?: Maybe<Tip_Current_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Current_OneArgs = {
  object: Tip_Current_Insert_Input;
  on_conflict?: Maybe<Tip_Current_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_OneArgs = {
  object: Tip_Insert_Input;
  on_conflict?: Maybe<Tip_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_SourceArgs = {
  objects: Array<Tip_Source_Insert_Input>;
  on_conflict?: Maybe<Tip_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tip_Source_OneArgs = {
  object: Tip_Source_Insert_Input;
  on_conflict?: Maybe<Tip_Source_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Yoga_TypeArgs = {
  objects: Array<Yoga_Type_Insert_Input>;
  on_conflict?: Maybe<Yoga_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Yoga_Type_OneArgs = {
  object: Yoga_Type_Insert_Input;
  on_conflict?: Maybe<Yoga_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootLoginVerifyArgs = {
  code?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSignupStudentArgs = {
  preferences?: Maybe<SignupPreferences>;
  profile: SignupStudentProfile;
  termsAndConditions: Scalars['Boolean'];
  timezone?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSignupStudioArgs = {
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
  profile: SignupStudioProfile;
  termsAndConditions: Scalars['Boolean'];
  timezone?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSignupTeacherArgs = {
  object: Scalars['jsonb'];
  termsAndConditions: Scalars['Boolean'];
};


/** mutation root */
export type Mutation_RootUpdateClassEventMeetingLinkByWebIdArgs = {
  meetingLink: Scalars['String'];
  webID: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootUpdateDefaultSourceArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_AccountArgs = {
  _append?: Maybe<Account_Append_Input>;
  _delete_at_path?: Maybe<Account_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Account_Delete_Elem_Input>;
  _delete_key?: Maybe<Account_Delete_Key_Input>;
  _prepend?: Maybe<Account_Prepend_Input>;
  _set?: Maybe<Account_Set_Input>;
  where: Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_By_PkArgs = {
  _append?: Maybe<Account_Append_Input>;
  _delete_at_path?: Maybe<Account_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Account_Delete_Elem_Input>;
  _delete_key?: Maybe<Account_Delete_Key_Input>;
  _prepend?: Maybe<Account_Prepend_Input>;
  _set?: Maybe<Account_Set_Input>;
  pk_columns: Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_CertificationArgs = {
  _inc?: Maybe<Account_Certification_Inc_Input>;
  _set?: Maybe<Account_Certification_Set_Input>;
  where: Account_Certification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Certification_By_PkArgs = {
  _inc?: Maybe<Account_Certification_Inc_Input>;
  _set?: Maybe<Account_Certification_Set_Input>;
  pk_columns: Account_Certification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_LinkArgs = {
  _inc?: Maybe<Account_Link_Inc_Input>;
  _set?: Maybe<Account_Link_Set_Input>;
  where: Account_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Link_By_PkArgs = {
  _inc?: Maybe<Account_Link_Inc_Input>;
  _set?: Maybe<Account_Link_Set_Input>;
  pk_columns: Account_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_RoleArgs = {
  _set?: Maybe<Account_Role_Set_Input>;
  where: Account_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Role_By_PkArgs = {
  _set?: Maybe<Account_Role_Set_Input>;
  pk_columns: Account_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Social_MediaArgs = {
  _inc?: Maybe<Account_Social_Media_Inc_Input>;
  _set?: Maybe<Account_Social_Media_Set_Input>;
  where: Account_Social_Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Social_Media_By_PkArgs = {
  _inc?: Maybe<Account_Social_Media_Inc_Input>;
  _set?: Maybe<Account_Social_Media_Set_Input>;
  pk_columns: Account_Social_Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Yoga_TypeArgs = {
  _inc?: Maybe<Account_Yoga_Type_Inc_Input>;
  _set?: Maybe<Account_Yoga_Type_Set_Input>;
  where: Account_Yoga_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Yoga_Type_By_PkArgs = {
  _inc?: Maybe<Account_Yoga_Type_Inc_Input>;
  _set?: Maybe<Account_Yoga_Type_Set_Input>;
  pk_columns: Account_Yoga_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BlogArgs = {
  _inc?: Maybe<Blog_Inc_Input>;
  _set?: Maybe<Blog_Set_Input>;
  where: Blog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_By_PkArgs = {
  _inc?: Maybe<Blog_Inc_Input>;
  _set?: Maybe<Blog_Set_Input>;
  pk_columns: Blog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_TypeArgs = {
  _set?: Maybe<Blog_Type_Set_Input>;
  where: Blog_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_Type_By_PkArgs = {
  _set?: Maybe<Blog_Type_Set_Input>;
  pk_columns: Blog_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ClassArgs = {
  _inc?: Maybe<Class_Inc_Input>;
  _set?: Maybe<Class_Set_Input>;
  where: Class_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_BookArgs = {
  _append?: Maybe<Class_Book_Append_Input>;
  _delete_at_path?: Maybe<Class_Book_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Class_Book_Delete_Elem_Input>;
  _delete_key?: Maybe<Class_Book_Delete_Key_Input>;
  _inc?: Maybe<Class_Book_Inc_Input>;
  _prepend?: Maybe<Class_Book_Prepend_Input>;
  _set?: Maybe<Class_Book_Set_Input>;
  where: Class_Book_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Book_By_PkArgs = {
  _append?: Maybe<Class_Book_Append_Input>;
  _delete_at_path?: Maybe<Class_Book_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Class_Book_Delete_Elem_Input>;
  _delete_key?: Maybe<Class_Book_Delete_Key_Input>;
  _inc?: Maybe<Class_Book_Inc_Input>;
  _prepend?: Maybe<Class_Book_Prepend_Input>;
  _set?: Maybe<Class_Book_Set_Input>;
  pk_columns: Class_Book_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Book_CancellationArgs = {
  _set?: Maybe<Class_Book_Cancellation_Set_Input>;
  where: Class_Book_Cancellation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Book_Cancellation_By_PkArgs = {
  _set?: Maybe<Class_Book_Cancellation_Set_Input>;
  pk_columns: Class_Book_Cancellation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_By_PkArgs = {
  _inc?: Maybe<Class_Inc_Input>;
  _set?: Maybe<Class_Set_Input>;
  pk_columns: Class_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Cost_TypeArgs = {
  _set?: Maybe<Class_Cost_Type_Set_Input>;
  where: Class_Cost_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Cost_Type_By_PkArgs = {
  _set?: Maybe<Class_Cost_Type_Set_Input>;
  pk_columns: Class_Cost_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Date_TimeArgs = {
  _inc?: Maybe<Class_Date_Time_Inc_Input>;
  _set?: Maybe<Class_Date_Time_Set_Input>;
  where: Class_Date_Time_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Date_Time_By_PkArgs = {
  _inc?: Maybe<Class_Date_Time_Inc_Input>;
  _set?: Maybe<Class_Date_Time_Set_Input>;
  pk_columns: Class_Date_Time_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_EventArgs = {
  _set?: Maybe<Class_Event_Set_Input>;
  where: Class_Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Event_By_PkArgs = {
  _set?: Maybe<Class_Event_Set_Input>;
  pk_columns: Class_Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_LevelArgs = {
  _set?: Maybe<Class_Level_Set_Input>;
  where: Class_Level_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Level_By_PkArgs = {
  _set?: Maybe<Class_Level_Set_Input>;
  pk_columns: Class_Level_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_PictureArgs = {
  _set?: Maybe<Class_Picture_Set_Input>;
  where: Class_Picture_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Picture_By_PkArgs = {
  _set?: Maybe<Class_Picture_Set_Input>;
  pk_columns: Class_Picture_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_RecurrenceArgs = {
  _set?: Maybe<Class_Recurrence_Set_Input>;
  where: Class_Recurrence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Recurrence_By_PkArgs = {
  _set?: Maybe<Class_Recurrence_Set_Input>;
  pk_columns: Class_Recurrence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_ReviewArgs = {
  _inc?: Maybe<Class_Review_Inc_Input>;
  _set?: Maybe<Class_Review_Set_Input>;
  where: Class_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Review_By_PkArgs = {
  _inc?: Maybe<Class_Review_Inc_Input>;
  _set?: Maybe<Class_Review_Set_Input>;
  pk_columns: Class_Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Safety_GuidelineArgs = {
  _inc?: Maybe<Class_Safety_Guideline_Inc_Input>;
  _set?: Maybe<Class_Safety_Guideline_Set_Input>;
  where: Class_Safety_Guideline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Safety_Guideline_By_PkArgs = {
  _inc?: Maybe<Class_Safety_Guideline_Inc_Input>;
  _set?: Maybe<Class_Safety_Guideline_Set_Input>;
  pk_columns: Class_Safety_Guideline_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Class_SettingArgs = {
  _set?: Maybe<Class_Setting_Set_Input>;
  where: Class_Setting_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Class_Setting_By_PkArgs = {
  _set?: Maybe<Class_Setting_Set_Input>;
  pk_columns: Class_Setting_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Favorite_ClassArgs = {
  _set?: Maybe<Favorite_Class_Set_Input>;
  where: Favorite_Class_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Favorite_Class_By_PkArgs = {
  _set?: Maybe<Favorite_Class_Set_Input>;
  pk_columns: Favorite_Class_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_DocumentArgs = {
  _set?: Maybe<Insurance_Document_Set_Input>;
  where: Insurance_Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Document_By_PkArgs = {
  _set?: Maybe<Insurance_Document_Set_Input>;
  pk_columns: Insurance_Document_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Document_Date_FilterArgs = {
  _set?: Maybe<Insurance_Document_Date_Filter_Set_Input>;
  where: Insurance_Document_Date_Filter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LocationArgs = {
  _set?: Maybe<Location_Set_Input>;
  where: Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Location_By_PkArgs = {
  _set?: Maybe<Location_Set_Input>;
  pk_columns: Location_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationArgs = {
  _set?: Maybe<Notification_Set_Input>;
  where: Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_By_PkArgs = {
  _set?: Maybe<Notification_Set_Input>;
  pk_columns: Notification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PreferencesArgs = {
  _set?: Maybe<Preferences_Set_Input>;
  where: Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Preferences_By_PkArgs = {
  _set?: Maybe<Preferences_Set_Input>;
  pk_columns: Preferences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_EntityArgs = {
  _set?: Maybe<Profile_Entity_Set_Input>;
  where: Profile_Entity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Entity_By_PkArgs = {
  _set?: Maybe<Profile_Entity_Set_Input>;
  pk_columns: Profile_Entity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_PersonArgs = {
  _set?: Maybe<Profile_Person_Set_Input>;
  where: Profile_Person_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profile_Person_By_PkArgs = {
  _set?: Maybe<Profile_Person_Set_Input>;
  pk_columns: Profile_Person_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Safety_GuidelineArgs = {
  _set?: Maybe<Safety_Guideline_Set_Input>;
  where: Safety_Guideline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Safety_Guideline_By_PkArgs = {
  _set?: Maybe<Safety_Guideline_Set_Input>;
  pk_columns: Safety_Guideline_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Searching_HistoryArgs = {
  _set?: Maybe<Searching_History_Set_Input>;
  where: Searching_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Searching_History_By_PkArgs = {
  _set?: Maybe<Searching_History_Set_Input>;
  pk_columns: Searching_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Setting_NotificationArgs = {
  _set?: Maybe<Setting_Notification_Set_Input>;
  where: Setting_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Setting_Notification_By_PkArgs = {
  _set?: Maybe<Setting_Notification_Set_Input>;
  pk_columns: Setting_Notification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Setting_Notification_CategoryArgs = {
  _set?: Maybe<Setting_Notification_Category_Set_Input>;
  where: Setting_Notification_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Setting_Notification_Category_By_PkArgs = {
  _set?: Maybe<Setting_Notification_Category_Set_Input>;
  pk_columns: Setting_Notification_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Setting_Notification_TypeArgs = {
  _set?: Maybe<Setting_Notification_Type_Set_Input>;
  where: Setting_Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Setting_Notification_Type_By_PkArgs = {
  _set?: Maybe<Setting_Notification_Type_Set_Input>;
  pk_columns: Setting_Notification_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Social_Media_TypeArgs = {
  _set?: Maybe<Social_Media_Type_Set_Input>;
  where: Social_Media_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Social_Media_Type_By_PkArgs = {
  _set?: Maybe<Social_Media_Type_Set_Input>;
  pk_columns: Social_Media_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Studio_PictureArgs = {
  _inc?: Maybe<Studio_Picture_Inc_Input>;
  _set?: Maybe<Studio_Picture_Set_Input>;
  where: Studio_Picture_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Studio_Picture_By_PkArgs = {
  _inc?: Maybe<Studio_Picture_Inc_Input>;
  _set?: Maybe<Studio_Picture_Set_Input>;
  pk_columns: Studio_Picture_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Studio_ReviewArgs = {
  _inc?: Maybe<Studio_Review_Inc_Input>;
  _set?: Maybe<Studio_Review_Set_Input>;
  where: Studio_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Studio_Review_By_PkArgs = {
  _inc?: Maybe<Studio_Review_Inc_Input>;
  _set?: Maybe<Studio_Review_Set_Input>;
  pk_columns: Studio_Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TeacherArgs = {
  _set?: Maybe<Teacher_Set_Input>;
  where: Teacher_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_By_PkArgs = {
  _set?: Maybe<Teacher_Set_Input>;
  pk_columns: Teacher_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_ReviewArgs = {
  _inc?: Maybe<Teacher_Review_Inc_Input>;
  _set?: Maybe<Teacher_Review_Set_Input>;
  where: Teacher_Review_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Teacher_Review_By_PkArgs = {
  _inc?: Maybe<Teacher_Review_Inc_Input>;
  _set?: Maybe<Teacher_Review_Set_Input>;
  pk_columns: Teacher_Review_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Temp_Coming_Soon_StudentArgs = {
  _set?: Maybe<Temp_Coming_Soon_Student_Set_Input>;
  where: Temp_Coming_Soon_Student_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Temp_Coming_Soon_Student_By_PkArgs = {
  _set?: Maybe<Temp_Coming_Soon_Student_Set_Input>;
  pk_columns: Temp_Coming_Soon_Student_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TipArgs = {
  _set?: Maybe<Tip_Set_Input>;
  where: Tip_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_By_PkArgs = {
  _set?: Maybe<Tip_Set_Input>;
  pk_columns: Tip_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_CurrentArgs = {
  _set?: Maybe<Tip_Current_Set_Input>;
  where: Tip_Current_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Current_By_PkArgs = {
  _set?: Maybe<Tip_Current_Set_Input>;
  pk_columns: Tip_Current_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_SourceArgs = {
  _inc?: Maybe<Tip_Source_Inc_Input>;
  _set?: Maybe<Tip_Source_Set_Input>;
  where: Tip_Source_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tip_Source_By_PkArgs = {
  _inc?: Maybe<Tip_Source_Inc_Input>;
  _set?: Maybe<Tip_Source_Set_Input>;
  pk_columns: Tip_Source_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Yoga_TypeArgs = {
  _set?: Maybe<Yoga_Type_Set_Input>;
  where: Yoga_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Yoga_Type_By_PkArgs = {
  _set?: Maybe<Yoga_Type_Set_Input>;
  pk_columns: Yoga_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUploadInsuranceDocumentArgs = {
  base64String: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootUploadPictureArgs = {
  base64String: Scalars['String'];
  format: Scalars['String'];
};

/** columns and relationships of "notification" */
export type Notification = {
  __typename?: 'notification';
  /** An object relationship */
  account: Account;
  account_id: Scalars['uuid'];
  category: Setting_Notification_Category_Enum;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  type: Setting_Notification_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "notification" */
export type Notification_Aggregate = {
  __typename?: 'notification_aggregate';
  aggregate?: Maybe<Notification_Aggregate_Fields>;
  nodes: Array<Notification>;
};

/** aggregate fields of "notification" */
export type Notification_Aggregate_Fields = {
  __typename?: 'notification_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Max_Fields>;
  min?: Maybe<Notification_Min_Fields>;
};


/** aggregate fields of "notification" */
export type Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type Notification_Bool_Exp = {
  _and?: Maybe<Array<Notification_Bool_Exp>>;
  _not?: Maybe<Notification_Bool_Exp>;
  _or?: Maybe<Array<Notification_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  category?: Maybe<Setting_Notification_Category_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<Setting_Notification_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification" */
export enum Notification_Constraint {
  /** unique or primary key constraint */
  NotificationsPkey = 'notifications_pkey'
}

/** input type for inserting data into table "notification" */
export type Notification_Insert_Input = {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  category?: Maybe<Setting_Notification_Category_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Setting_Notification_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Notification_Max_Fields = {
  __typename?: 'notification_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Notification_Min_Fields = {
  __typename?: 'notification_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "notification" */
export type Notification_Mutation_Response = {
  __typename?: 'notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification>;
};

/** on conflict condition type for table "notification" */
export type Notification_On_Conflict = {
  constraint: Notification_Constraint;
  update_columns?: Array<Notification_Update_Column>;
  where?: Maybe<Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "notification". */
export type Notification_Order_By = {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  category?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: notification */
export type Notification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "notification" */
export enum Notification_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "notification" */
export type Notification_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  category?: Maybe<Setting_Notification_Category_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Setting_Notification_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "notification" */
export enum Notification_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "preferences" */
export type Preferences = {
  __typename?: 'preferences';
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  levels: Scalars['String'];
  settings: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  yogaTypes: Scalars['String'];
};

/** aggregated selection of "preferences" */
export type Preferences_Aggregate = {
  __typename?: 'preferences_aggregate';
  aggregate?: Maybe<Preferences_Aggregate_Fields>;
  nodes: Array<Preferences>;
};

/** aggregate fields of "preferences" */
export type Preferences_Aggregate_Fields = {
  __typename?: 'preferences_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Preferences_Max_Fields>;
  min?: Maybe<Preferences_Min_Fields>;
};


/** aggregate fields of "preferences" */
export type Preferences_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Preferences_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "preferences". All fields are combined with a logical 'AND'. */
export type Preferences_Bool_Exp = {
  _and?: Maybe<Array<Preferences_Bool_Exp>>;
  _not?: Maybe<Preferences_Bool_Exp>;
  _or?: Maybe<Array<Preferences_Bool_Exp>>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  levels?: Maybe<String_Comparison_Exp>;
  settings?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  yogaTypes?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "preferences" */
export enum Preferences_Constraint {
  /** unique or primary key constraint */
  PreferencesAccountIdKey = 'preferences_account_id_key',
  /** unique or primary key constraint */
  PreferencesPkey = 'preferences_pkey'
}

/** input type for inserting data into table "preferences" */
export type Preferences_Insert_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  levels?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  yogaTypes?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Preferences_Max_Fields = {
  __typename?: 'preferences_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  levels?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  yogaTypes?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Preferences_Min_Fields = {
  __typename?: 'preferences_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  levels?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  yogaTypes?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "preferences" */
export type Preferences_Mutation_Response = {
  __typename?: 'preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Preferences>;
};

/** input type for inserting object relation for remote table "preferences" */
export type Preferences_Obj_Rel_Insert_Input = {
  data: Preferences_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Preferences_On_Conflict>;
};

/** on conflict condition type for table "preferences" */
export type Preferences_On_Conflict = {
  constraint: Preferences_Constraint;
  update_columns?: Array<Preferences_Update_Column>;
  where?: Maybe<Preferences_Bool_Exp>;
};

/** Ordering options when selecting data from "preferences". */
export type Preferences_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  levels?: Maybe<Order_By>;
  settings?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  yogaTypes?: Maybe<Order_By>;
};

/** primary key columns input for table: preferences */
export type Preferences_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "preferences" */
export enum Preferences_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Levels = 'levels',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YogaTypes = 'yogaTypes'
}

/** input type for updating data in table "preferences" */
export type Preferences_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  levels?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  yogaTypes?: Maybe<Scalars['String']>;
};

/** update columns of table "preferences" */
export enum Preferences_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Levels = 'levels',
  /** column name */
  Settings = 'settings',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  YogaTypes = 'yogaTypes'
}

/** columns and relationships of "profile_entity" */
export type Profile_Entity = {
  __typename?: 'profile_entity';
  /** An object relationship */
  account: Account;
  account_id: Scalars['uuid'];
  bio?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "profile_entity" */
export type Profile_Entity_Aggregate = {
  __typename?: 'profile_entity_aggregate';
  aggregate?: Maybe<Profile_Entity_Aggregate_Fields>;
  nodes: Array<Profile_Entity>;
};

/** aggregate fields of "profile_entity" */
export type Profile_Entity_Aggregate_Fields = {
  __typename?: 'profile_entity_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Entity_Max_Fields>;
  min?: Maybe<Profile_Entity_Min_Fields>;
};


/** aggregate fields of "profile_entity" */
export type Profile_Entity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Entity_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "profile_entity". All fields are combined with a logical 'AND'. */
export type Profile_Entity_Bool_Exp = {
  _and?: Maybe<Array<Profile_Entity_Bool_Exp>>;
  _not?: Maybe<Profile_Entity_Bool_Exp>;
  _or?: Maybe<Array<Profile_Entity_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  bio?: Maybe<String_Comparison_Exp>;
  contactPhoneNumber?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phoneNumber?: Maybe<String_Comparison_Exp>;
  picture?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_entity" */
export enum Profile_Entity_Constraint {
  /** unique or primary key constraint */
  ProfileEntityEmailKey = 'profile_entity_email_key',
  /** unique or primary key constraint */
  ProfileEntityPkey = 'profile_entity_pkey'
}

/** input type for inserting data into table "profile_entity" */
export type Profile_Entity_Insert_Input = {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  bio?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Entity_Max_Fields = {
  __typename?: 'profile_entity_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  bio?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Profile_Entity_Min_Fields = {
  __typename?: 'profile_entity_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  bio?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "profile_entity" */
export type Profile_Entity_Mutation_Response = {
  __typename?: 'profile_entity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Entity>;
};

/** input type for inserting object relation for remote table "profile_entity" */
export type Profile_Entity_Obj_Rel_Insert_Input = {
  data: Profile_Entity_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Entity_On_Conflict>;
};

/** on conflict condition type for table "profile_entity" */
export type Profile_Entity_On_Conflict = {
  constraint: Profile_Entity_Constraint;
  update_columns?: Array<Profile_Entity_Update_Column>;
  where?: Maybe<Profile_Entity_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_entity". */
export type Profile_Entity_Order_By = {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  bio?: Maybe<Order_By>;
  contactPhoneNumber?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phoneNumber?: Maybe<Order_By>;
  picture?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: profile_entity */
export type Profile_Entity_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "profile_entity" */
export enum Profile_Entity_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Bio = 'bio',
  /** column name */
  ContactPhoneNumber = 'contactPhoneNumber',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Picture = 'picture',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profile_entity" */
export type Profile_Entity_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  bio?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "profile_entity" */
export enum Profile_Entity_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Bio = 'bio',
  /** column name */
  ContactPhoneNumber = 'contactPhoneNumber',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Picture = 'picture',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "profile_person" */
export type Profile_Person = {
  __typename?: 'profile_person';
  /** An object relationship */
  account?: Maybe<Account>;
  account_id?: Maybe<Scalars['uuid']>;
  birthday?: Maybe<Scalars['date']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['uuid'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  /** An object relationship */
  teacher?: Maybe<Teacher>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "profile_person" */
export type Profile_Person_Aggregate = {
  __typename?: 'profile_person_aggregate';
  aggregate?: Maybe<Profile_Person_Aggregate_Fields>;
  nodes: Array<Profile_Person>;
};

/** aggregate fields of "profile_person" */
export type Profile_Person_Aggregate_Fields = {
  __typename?: 'profile_person_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profile_Person_Max_Fields>;
  min?: Maybe<Profile_Person_Min_Fields>;
};


/** aggregate fields of "profile_person" */
export type Profile_Person_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Profile_Person_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "profile_person". All fields are combined with a logical 'AND'. */
export type Profile_Person_Bool_Exp = {
  _and?: Maybe<Array<Profile_Person_Bool_Exp>>;
  _not?: Maybe<Profile_Person_Bool_Exp>;
  _or?: Maybe<Array<Profile_Person_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  birthday?: Maybe<Date_Comparison_Exp>;
  contactPhoneNumber?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  firstName?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lastName?: Maybe<String_Comparison_Exp>;
  phoneNumber?: Maybe<String_Comparison_Exp>;
  picture?: Maybe<String_Comparison_Exp>;
  teacher?: Maybe<Teacher_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "profile_person" */
export enum Profile_Person_Constraint {
  /** unique or primary key constraint */
  ProfilePersonPkey = 'profile_person_pkey'
}

/** input type for inserting data into table "profile_person" */
export type Profile_Person_Insert_Input = {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  birthday?: Maybe<Scalars['date']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  teacher?: Maybe<Teacher_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Profile_Person_Max_Fields = {
  __typename?: 'profile_person_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  birthday?: Maybe<Scalars['date']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Profile_Person_Min_Fields = {
  __typename?: 'profile_person_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  birthday?: Maybe<Scalars['date']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "profile_person" */
export type Profile_Person_Mutation_Response = {
  __typename?: 'profile_person_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profile_Person>;
};

/** input type for inserting object relation for remote table "profile_person" */
export type Profile_Person_Obj_Rel_Insert_Input = {
  data: Profile_Person_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Profile_Person_On_Conflict>;
};

/** on conflict condition type for table "profile_person" */
export type Profile_Person_On_Conflict = {
  constraint: Profile_Person_Constraint;
  update_columns?: Array<Profile_Person_Update_Column>;
  where?: Maybe<Profile_Person_Bool_Exp>;
};

/** Ordering options when selecting data from "profile_person". */
export type Profile_Person_Order_By = {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  birthday?: Maybe<Order_By>;
  contactPhoneNumber?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  firstName?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastName?: Maybe<Order_By>;
  phoneNumber?: Maybe<Order_By>;
  picture?: Maybe<Order_By>;
  teacher?: Maybe<Teacher_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: profile_person */
export type Profile_Person_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "profile_person" */
export enum Profile_Person_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  ContactPhoneNumber = 'contactPhoneNumber',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Picture = 'picture',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "profile_person" */
export type Profile_Person_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  birthday?: Maybe<Scalars['date']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "profile_person" */
export enum Profile_Person_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  ContactPhoneNumber = 'contactPhoneNumber',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'lastName',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  Picture = 'picture',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  accountStatus: Array<Maybe<AccountStatus>>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table: "account_certification" */
  account_certification: Array<Account_Certification>;
  /** fetch aggregated fields from the table: "account_certification" */
  account_certification_aggregate: Account_Certification_Aggregate;
  /** fetch data from the table: "account_certification" using primary key columns */
  account_certification_by_pk?: Maybe<Account_Certification>;
  /** fetch data from the table: "account_link" */
  account_link: Array<Account_Link>;
  /** fetch aggregated fields from the table: "account_link" */
  account_link_aggregate: Account_Link_Aggregate;
  /** fetch data from the table: "account_link" using primary key columns */
  account_link_by_pk?: Maybe<Account_Link>;
  /** fetch data from the table: "account_role" */
  account_role: Array<Account_Role>;
  /** fetch aggregated fields from the table: "account_role" */
  account_role_aggregate: Account_Role_Aggregate;
  /** fetch data from the table: "account_role" using primary key columns */
  account_role_by_pk?: Maybe<Account_Role>;
  /** An array relationship */
  account_social_media: Array<Account_Social_Media>;
  /** An aggregate relationship */
  account_social_media_aggregate: Account_Social_Media_Aggregate;
  /** fetch data from the table: "account_social_media" using primary key columns */
  account_social_media_by_pk?: Maybe<Account_Social_Media>;
  /** fetch data from the table: "account_yoga_type" */
  account_yoga_type: Array<Account_Yoga_Type>;
  /** fetch aggregated fields from the table: "account_yoga_type" */
  account_yoga_type_aggregate: Account_Yoga_Type_Aggregate;
  /** fetch data from the table: "account_yoga_type" using primary key columns */
  account_yoga_type_by_pk?: Maybe<Account_Yoga_Type>;
  billingSources: BillingSourcesOutput;
  /** fetch data from the table: "blog" */
  blog: Array<Blog>;
  /** fetch aggregated fields from the table: "blog" */
  blog_aggregate: Blog_Aggregate;
  /** fetch data from the table: "blog" using primary key columns */
  blog_by_pk?: Maybe<Blog>;
  /** fetch data from the table: "blog_type" */
  blog_type: Array<Blog_Type>;
  /** fetch aggregated fields from the table: "blog_type" */
  blog_type_aggregate: Blog_Type_Aggregate;
  /** fetch data from the table: "blog_type" using primary key columns */
  blog_type_by_pk?: Maybe<Blog_Type>;
  /** fetch data from the table: "class" */
  class: Array<Class>;
  classAttending: ClassAttending;
  classEventByWebID?: Maybe<ClassEventByWebIdOutput>;
  /** fetch aggregated fields from the table: "class" */
  class_aggregate: Class_Aggregate;
  /** fetch data from the table: "class_book" */
  class_book: Array<Class_Book>;
  /** fetch aggregated fields from the table: "class_book" */
  class_book_aggregate: Class_Book_Aggregate;
  /** fetch data from the table: "class_book" using primary key columns */
  class_book_by_pk?: Maybe<Class_Book>;
  /** fetch data from the table: "class_book_cancellation" */
  class_book_cancellation: Array<Class_Book_Cancellation>;
  /** fetch aggregated fields from the table: "class_book_cancellation" */
  class_book_cancellation_aggregate: Class_Book_Cancellation_Aggregate;
  /** fetch data from the table: "class_book_cancellation" using primary key columns */
  class_book_cancellation_by_pk?: Maybe<Class_Book_Cancellation>;
  /** fetch data from the table: "class" using primary key columns */
  class_by_pk?: Maybe<Class>;
  /** fetch data from the table: "class_cost_type" */
  class_cost_type: Array<Class_Cost_Type>;
  /** fetch aggregated fields from the table: "class_cost_type" */
  class_cost_type_aggregate: Class_Cost_Type_Aggregate;
  /** fetch data from the table: "class_cost_type" using primary key columns */
  class_cost_type_by_pk?: Maybe<Class_Cost_Type>;
  /** fetch data from the table: "class_date_time" */
  class_date_time: Array<Class_Date_Time>;
  /** fetch aggregated fields from the table: "class_date_time" */
  class_date_time_aggregate: Class_Date_Time_Aggregate;
  /** fetch data from the table: "class_date_time" using primary key columns */
  class_date_time_by_pk?: Maybe<Class_Date_Time>;
  /** fetch data from the table: "class_event" */
  class_event: Array<Class_Event>;
  /** fetch aggregated fields from the table: "class_event" */
  class_event_aggregate: Class_Event_Aggregate;
  /** fetch data from the table: "class_event" using primary key columns */
  class_event_by_pk?: Maybe<Class_Event>;
  /** fetch data from the table: "class_level" */
  class_level: Array<Class_Level>;
  /** fetch aggregated fields from the table: "class_level" */
  class_level_aggregate: Class_Level_Aggregate;
  /** fetch data from the table: "class_level" using primary key columns */
  class_level_by_pk?: Maybe<Class_Level>;
  /** fetch data from the table: "class_picture" */
  class_picture: Array<Class_Picture>;
  /** fetch aggregated fields from the table: "class_picture" */
  class_picture_aggregate: Class_Picture_Aggregate;
  /** fetch data from the table: "class_picture" using primary key columns */
  class_picture_by_pk?: Maybe<Class_Picture>;
  /** fetch data from the table: "class_recurrence" */
  class_recurrence: Array<Class_Recurrence>;
  /** fetch aggregated fields from the table: "class_recurrence" */
  class_recurrence_aggregate: Class_Recurrence_Aggregate;
  /** fetch data from the table: "class_recurrence" using primary key columns */
  class_recurrence_by_pk?: Maybe<Class_Recurrence>;
  /** fetch data from the table: "class_review" */
  class_review: Array<Class_Review>;
  /** fetch aggregated fields from the table: "class_review" */
  class_review_aggregate: Class_Review_Aggregate;
  /** fetch data from the table: "class_review" using primary key columns */
  class_review_by_pk?: Maybe<Class_Review>;
  /** fetch data from the table: "class_safety_guideline" */
  class_safety_guideline: Array<Class_Safety_Guideline>;
  /** fetch aggregated fields from the table: "class_safety_guideline" */
  class_safety_guideline_aggregate: Class_Safety_Guideline_Aggregate;
  /** fetch data from the table: "class_safety_guideline" using primary key columns */
  class_safety_guideline_by_pk?: Maybe<Class_Safety_Guideline>;
  /** fetch data from the table: "class_setting" */
  class_setting: Array<Class_Setting>;
  /** fetch aggregated fields from the table: "class_setting" */
  class_setting_aggregate: Class_Setting_Aggregate;
  /** fetch data from the table: "class_setting" using primary key columns */
  class_setting_by_pk?: Maybe<Class_Setting>;
  /** fetch data from the table: "favorite_class" */
  favorite_class: Array<Favorite_Class>;
  /** fetch aggregated fields from the table: "favorite_class" */
  favorite_class_aggregate: Favorite_Class_Aggregate;
  /** fetch data from the table: "favorite_class" using primary key columns */
  favorite_class_by_pk?: Maybe<Favorite_Class>;
  findLocation?: Maybe<Array<Maybe<FindLocationOutput>>>;
  /** fetch data from the table: "insurance_document" */
  insurance_document: Array<Insurance_Document>;
  /** fetch aggregated fields from the table: "insurance_document" */
  insurance_document_aggregate: Insurance_Document_Aggregate;
  /** fetch data from the table: "insurance_document" using primary key columns */
  insurance_document_by_pk?: Maybe<Insurance_Document>;
  /** fetch data from the table: "insurance_document_date_filter" */
  insurance_document_date_filter: Array<Insurance_Document_Date_Filter>;
  /** fetch aggregated fields from the table: "insurance_document_date_filter" */
  insurance_document_date_filter_aggregate: Insurance_Document_Date_Filter_Aggregate;
  legalInformation: LegalInformationOutput;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  placeCoordinates?: Maybe<PlaceCoordinateOutput>;
  /** fetch data from the table: "preferences" */
  preferences: Array<Preferences>;
  /** fetch aggregated fields from the table: "preferences" */
  preferences_aggregate: Preferences_Aggregate;
  /** fetch data from the table: "preferences" using primary key columns */
  preferences_by_pk?: Maybe<Preferences>;
  /** fetch data from the table: "profile_entity" */
  profile_entity: Array<Profile_Entity>;
  /** fetch aggregated fields from the table: "profile_entity" */
  profile_entity_aggregate: Profile_Entity_Aggregate;
  /** fetch data from the table: "profile_entity" using primary key columns */
  profile_entity_by_pk?: Maybe<Profile_Entity>;
  /** fetch data from the table: "profile_person" */
  profile_person: Array<Profile_Person>;
  /** fetch aggregated fields from the table: "profile_person" */
  profile_person_aggregate: Profile_Person_Aggregate;
  /** fetch data from the table: "profile_person" using primary key columns */
  profile_person_by_pk?: Maybe<Profile_Person>;
  publicProfile: PublicProfile;
  /** fetch data from the table: "safety_guideline" */
  safety_guideline: Array<Safety_Guideline>;
  /** fetch aggregated fields from the table: "safety_guideline" */
  safety_guideline_aggregate: Safety_Guideline_Aggregate;
  /** fetch data from the table: "safety_guideline" using primary key columns */
  safety_guideline_by_pk?: Maybe<Safety_Guideline>;
  /** Search Teachers & Studios */
  searchSeller?: Maybe<SearchSellerOutput>;
  /** fetch data from the table: "searching_history" */
  searching_history: Array<Searching_History>;
  /** fetch aggregated fields from the table: "searching_history" */
  searching_history_aggregate: Searching_History_Aggregate;
  /** fetch data from the table: "searching_history" using primary key columns */
  searching_history_by_pk?: Maybe<Searching_History>;
  /** fetch data from the table: "setting_notification" */
  setting_notification: Array<Setting_Notification>;
  /** fetch aggregated fields from the table: "setting_notification" */
  setting_notification_aggregate: Setting_Notification_Aggregate;
  /** fetch data from the table: "setting_notification" using primary key columns */
  setting_notification_by_pk?: Maybe<Setting_Notification>;
  /** fetch data from the table: "setting_notification_category" */
  setting_notification_category: Array<Setting_Notification_Category>;
  /** fetch aggregated fields from the table: "setting_notification_category" */
  setting_notification_category_aggregate: Setting_Notification_Category_Aggregate;
  /** fetch data from the table: "setting_notification_category" using primary key columns */
  setting_notification_category_by_pk?: Maybe<Setting_Notification_Category>;
  /** fetch data from the table: "setting_notification_type" */
  setting_notification_type: Array<Setting_Notification_Type>;
  /** fetch aggregated fields from the table: "setting_notification_type" */
  setting_notification_type_aggregate: Setting_Notification_Type_Aggregate;
  /** fetch data from the table: "setting_notification_type" using primary key columns */
  setting_notification_type_by_pk?: Maybe<Setting_Notification_Type>;
  /** fetch data from the table: "social_media_type" */
  social_media_type: Array<Social_Media_Type>;
  /** fetch aggregated fields from the table: "social_media_type" */
  social_media_type_aggregate: Social_Media_Type_Aggregate;
  /** fetch data from the table: "social_media_type" using primary key columns */
  social_media_type_by_pk?: Maybe<Social_Media_Type>;
  /** fetch data from the table: "studio_picture" */
  studio_picture: Array<Studio_Picture>;
  /** fetch aggregated fields from the table: "studio_picture" */
  studio_picture_aggregate: Studio_Picture_Aggregate;
  /** fetch data from the table: "studio_picture" using primary key columns */
  studio_picture_by_pk?: Maybe<Studio_Picture>;
  /** fetch data from the table: "studio_review" */
  studio_review: Array<Studio_Review>;
  /** fetch aggregated fields from the table: "studio_review" */
  studio_review_aggregate: Studio_Review_Aggregate;
  /** fetch data from the table: "studio_review" using primary key columns */
  studio_review_by_pk?: Maybe<Studio_Review>;
  /** fetch data from the table: "teacher" */
  teacher: Array<Teacher>;
  /** fetch aggregated fields from the table: "teacher" */
  teacher_aggregate: Teacher_Aggregate;
  /** fetch data from the table: "teacher" using primary key columns */
  teacher_by_pk?: Maybe<Teacher>;
  /** fetch data from the table: "teacher_review" */
  teacher_review: Array<Teacher_Review>;
  /** fetch aggregated fields from the table: "teacher_review" */
  teacher_review_aggregate: Teacher_Review_Aggregate;
  /** fetch data from the table: "teacher_review" using primary key columns */
  teacher_review_by_pk?: Maybe<Teacher_Review>;
  /** fetch data from the table: "temp_coming_soon_student" */
  temp_coming_soon_student: Array<Temp_Coming_Soon_Student>;
  /** fetch aggregated fields from the table: "temp_coming_soon_student" */
  temp_coming_soon_student_aggregate: Temp_Coming_Soon_Student_Aggregate;
  /** fetch data from the table: "temp_coming_soon_student" using primary key columns */
  temp_coming_soon_student_by_pk?: Maybe<Temp_Coming_Soon_Student>;
  terms: Terms;
  /** fetch data from the table: "tip" */
  tip: Array<Tip>;
  /** fetch aggregated fields from the table: "tip" */
  tip_aggregate: Tip_Aggregate;
  /** fetch data from the table: "tip" using primary key columns */
  tip_by_pk?: Maybe<Tip>;
  /** fetch data from the table: "tip_current" */
  tip_current: Array<Tip_Current>;
  /** fetch aggregated fields from the table: "tip_current" */
  tip_current_aggregate: Tip_Current_Aggregate;
  /** fetch data from the table: "tip_current" using primary key columns */
  tip_current_by_pk?: Maybe<Tip_Current>;
  /** fetch data from the table: "tip_source" */
  tip_source: Array<Tip_Source>;
  /** fetch aggregated fields from the table: "tip_source" */
  tip_source_aggregate: Tip_Source_Aggregate;
  /** fetch data from the table: "tip_source" using primary key columns */
  tip_source_by_pk?: Maybe<Tip_Source>;
  /** fetch data from the table: "yoga_type" */
  yoga_type: Array<Yoga_Type>;
  /** fetch aggregated fields from the table: "yoga_type" */
  yoga_type_aggregate: Yoga_Type_Aggregate;
  /** fetch data from the table: "yoga_type" using primary key columns */
  yoga_type_by_pk?: Maybe<Yoga_Type>;
};


export type Query_RootAccountArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Query_RootAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Query_RootAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAccount_CertificationArgs = {
  distinct_on?: Maybe<Array<Account_Certification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Certification_Order_By>>;
  where?: Maybe<Account_Certification_Bool_Exp>;
};


export type Query_RootAccount_Certification_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Certification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Certification_Order_By>>;
  where?: Maybe<Account_Certification_Bool_Exp>;
};


export type Query_RootAccount_Certification_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAccount_LinkArgs = {
  distinct_on?: Maybe<Array<Account_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Link_Order_By>>;
  where?: Maybe<Account_Link_Bool_Exp>;
};


export type Query_RootAccount_Link_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Link_Order_By>>;
  where?: Maybe<Account_Link_Bool_Exp>;
};


export type Query_RootAccount_Link_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAccount_RoleArgs = {
  distinct_on?: Maybe<Array<Account_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Role_Order_By>>;
  where?: Maybe<Account_Role_Bool_Exp>;
};


export type Query_RootAccount_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Role_Order_By>>;
  where?: Maybe<Account_Role_Bool_Exp>;
};


export type Query_RootAccount_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootAccount_Social_MediaArgs = {
  distinct_on?: Maybe<Array<Account_Social_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Social_Media_Order_By>>;
  where?: Maybe<Account_Social_Media_Bool_Exp>;
};


export type Query_RootAccount_Social_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Social_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Social_Media_Order_By>>;
  where?: Maybe<Account_Social_Media_Bool_Exp>;
};


export type Query_RootAccount_Social_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAccount_Yoga_TypeArgs = {
  distinct_on?: Maybe<Array<Account_Yoga_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Yoga_Type_Order_By>>;
  where?: Maybe<Account_Yoga_Type_Bool_Exp>;
};


export type Query_RootAccount_Yoga_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Yoga_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Yoga_Type_Order_By>>;
  where?: Maybe<Account_Yoga_Type_Bool_Exp>;
};


export type Query_RootAccount_Yoga_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootBlogArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};


export type Query_RootBlog_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};


export type Query_RootBlog_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBlog_TypeArgs = {
  distinct_on?: Maybe<Array<Blog_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Type_Order_By>>;
  where?: Maybe<Blog_Type_Bool_Exp>;
};


export type Query_RootBlog_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Type_Order_By>>;
  where?: Maybe<Blog_Type_Bool_Exp>;
};


export type Query_RootBlog_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootClassArgs = {
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Query_RootClassAttendingArgs = {
  classEventID: Scalars['uuid'];
};


export type Query_RootClassEventByWebIdArgs = {
  webID: Scalars['uuid'];
};


export type Query_RootClass_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Query_RootClass_BookArgs = {
  distinct_on?: Maybe<Array<Class_Book_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Book_Order_By>>;
  where?: Maybe<Class_Book_Bool_Exp>;
};


export type Query_RootClass_Book_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Book_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Book_Order_By>>;
  where?: Maybe<Class_Book_Bool_Exp>;
};


export type Query_RootClass_Book_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClass_Book_CancellationArgs = {
  distinct_on?: Maybe<Array<Class_Book_Cancellation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Book_Cancellation_Order_By>>;
  where?: Maybe<Class_Book_Cancellation_Bool_Exp>;
};


export type Query_RootClass_Book_Cancellation_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Book_Cancellation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Book_Cancellation_Order_By>>;
  where?: Maybe<Class_Book_Cancellation_Bool_Exp>;
};


export type Query_RootClass_Book_Cancellation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClass_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClass_Cost_TypeArgs = {
  distinct_on?: Maybe<Array<Class_Cost_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Cost_Type_Order_By>>;
  where?: Maybe<Class_Cost_Type_Bool_Exp>;
};


export type Query_RootClass_Cost_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Cost_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Cost_Type_Order_By>>;
  where?: Maybe<Class_Cost_Type_Bool_Exp>;
};


export type Query_RootClass_Cost_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootClass_Date_TimeArgs = {
  distinct_on?: Maybe<Array<Class_Date_Time_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Date_Time_Order_By>>;
  where?: Maybe<Class_Date_Time_Bool_Exp>;
};


export type Query_RootClass_Date_Time_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Date_Time_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Date_Time_Order_By>>;
  where?: Maybe<Class_Date_Time_Bool_Exp>;
};


export type Query_RootClass_Date_Time_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClass_EventArgs = {
  distinct_on?: Maybe<Array<Class_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Event_Order_By>>;
  where?: Maybe<Class_Event_Bool_Exp>;
};


export type Query_RootClass_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Event_Order_By>>;
  where?: Maybe<Class_Event_Bool_Exp>;
};


export type Query_RootClass_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClass_LevelArgs = {
  distinct_on?: Maybe<Array<Class_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Level_Order_By>>;
  where?: Maybe<Class_Level_Bool_Exp>;
};


export type Query_RootClass_Level_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Level_Order_By>>;
  where?: Maybe<Class_Level_Bool_Exp>;
};


export type Query_RootClass_Level_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootClass_PictureArgs = {
  distinct_on?: Maybe<Array<Class_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Picture_Order_By>>;
  where?: Maybe<Class_Picture_Bool_Exp>;
};


export type Query_RootClass_Picture_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Picture_Order_By>>;
  where?: Maybe<Class_Picture_Bool_Exp>;
};


export type Query_RootClass_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClass_RecurrenceArgs = {
  distinct_on?: Maybe<Array<Class_Recurrence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Recurrence_Order_By>>;
  where?: Maybe<Class_Recurrence_Bool_Exp>;
};


export type Query_RootClass_Recurrence_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Recurrence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Recurrence_Order_By>>;
  where?: Maybe<Class_Recurrence_Bool_Exp>;
};


export type Query_RootClass_Recurrence_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootClass_ReviewArgs = {
  distinct_on?: Maybe<Array<Class_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Review_Order_By>>;
  where?: Maybe<Class_Review_Bool_Exp>;
};


export type Query_RootClass_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Review_Order_By>>;
  where?: Maybe<Class_Review_Bool_Exp>;
};


export type Query_RootClass_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClass_Safety_GuidelineArgs = {
  distinct_on?: Maybe<Array<Class_Safety_Guideline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Safety_Guideline_Order_By>>;
  where?: Maybe<Class_Safety_Guideline_Bool_Exp>;
};


export type Query_RootClass_Safety_Guideline_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Safety_Guideline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Safety_Guideline_Order_By>>;
  where?: Maybe<Class_Safety_Guideline_Bool_Exp>;
};


export type Query_RootClass_Safety_Guideline_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClass_SettingArgs = {
  distinct_on?: Maybe<Array<Class_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Setting_Order_By>>;
  where?: Maybe<Class_Setting_Bool_Exp>;
};


export type Query_RootClass_Setting_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Setting_Order_By>>;
  where?: Maybe<Class_Setting_Bool_Exp>;
};


export type Query_RootClass_Setting_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFavorite_ClassArgs = {
  distinct_on?: Maybe<Array<Favorite_Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Favorite_Class_Order_By>>;
  where?: Maybe<Favorite_Class_Bool_Exp>;
};


export type Query_RootFavorite_Class_AggregateArgs = {
  distinct_on?: Maybe<Array<Favorite_Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Favorite_Class_Order_By>>;
  where?: Maybe<Favorite_Class_Bool_Exp>;
};


export type Query_RootFavorite_Class_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFindLocationArgs = {
  input: Scalars['String'];
  type: Scalars['String'];
};


export type Query_RootInsurance_DocumentArgs = {
  distinct_on?: Maybe<Array<Insurance_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Document_Order_By>>;
  where?: Maybe<Insurance_Document_Bool_Exp>;
};


export type Query_RootInsurance_Document_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Document_Order_By>>;
  where?: Maybe<Insurance_Document_Bool_Exp>;
};


export type Query_RootInsurance_Document_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootInsurance_Document_Date_FilterArgs = {
  distinct_on?: Maybe<Array<Insurance_Document_Date_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Document_Date_Filter_Order_By>>;
  where?: Maybe<Insurance_Document_Date_Filter_Bool_Exp>;
};


export type Query_RootInsurance_Document_Date_Filter_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Document_Date_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Document_Date_Filter_Order_By>>;
  where?: Maybe<Insurance_Document_Date_Filter_Bool_Exp>;
};


export type Query_RootLocationArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};


export type Query_RootLocation_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};


export type Query_RootLocation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNotificationArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Order_By>>;
  where?: Maybe<Notification_Bool_Exp>;
};


export type Query_RootNotification_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Order_By>>;
  where?: Maybe<Notification_Bool_Exp>;
};


export type Query_RootNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPlaceCoordinatesArgs = {
  placeID: Scalars['String'];
};


export type Query_RootPreferencesArgs = {
  distinct_on?: Maybe<Array<Preferences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Preferences_Order_By>>;
  where?: Maybe<Preferences_Bool_Exp>;
};


export type Query_RootPreferences_AggregateArgs = {
  distinct_on?: Maybe<Array<Preferences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Preferences_Order_By>>;
  where?: Maybe<Preferences_Bool_Exp>;
};


export type Query_RootPreferences_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProfile_EntityArgs = {
  distinct_on?: Maybe<Array<Profile_Entity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Entity_Order_By>>;
  where?: Maybe<Profile_Entity_Bool_Exp>;
};


export type Query_RootProfile_Entity_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Entity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Entity_Order_By>>;
  where?: Maybe<Profile_Entity_Bool_Exp>;
};


export type Query_RootProfile_Entity_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProfile_PersonArgs = {
  distinct_on?: Maybe<Array<Profile_Person_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Person_Order_By>>;
  where?: Maybe<Profile_Person_Bool_Exp>;
};


export type Query_RootProfile_Person_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Person_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Person_Order_By>>;
  where?: Maybe<Profile_Person_Bool_Exp>;
};


export type Query_RootProfile_Person_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPublicProfileArgs = {
  accountID?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
};


export type Query_RootSafety_GuidelineArgs = {
  distinct_on?: Maybe<Array<Safety_Guideline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Safety_Guideline_Order_By>>;
  where?: Maybe<Safety_Guideline_Bool_Exp>;
};


export type Query_RootSafety_Guideline_AggregateArgs = {
  distinct_on?: Maybe<Array<Safety_Guideline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Safety_Guideline_Order_By>>;
  where?: Maybe<Safety_Guideline_Bool_Exp>;
};


export type Query_RootSafety_Guideline_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootSearchSellerArgs = {
  type: Scalars['String'];
};


export type Query_RootSearching_HistoryArgs = {
  distinct_on?: Maybe<Array<Searching_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Searching_History_Order_By>>;
  where?: Maybe<Searching_History_Bool_Exp>;
};


export type Query_RootSearching_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Searching_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Searching_History_Order_By>>;
  where?: Maybe<Searching_History_Bool_Exp>;
};


export type Query_RootSearching_History_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSetting_NotificationArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Order_By>>;
  where?: Maybe<Setting_Notification_Bool_Exp>;
};


export type Query_RootSetting_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Order_By>>;
  where?: Maybe<Setting_Notification_Bool_Exp>;
};


export type Query_RootSetting_Notification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSetting_Notification_CategoryArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Category_Order_By>>;
  where?: Maybe<Setting_Notification_Category_Bool_Exp>;
};


export type Query_RootSetting_Notification_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Category_Order_By>>;
  where?: Maybe<Setting_Notification_Category_Bool_Exp>;
};


export type Query_RootSetting_Notification_Category_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootSetting_Notification_TypeArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Type_Order_By>>;
  where?: Maybe<Setting_Notification_Type_Bool_Exp>;
};


export type Query_RootSetting_Notification_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Type_Order_By>>;
  where?: Maybe<Setting_Notification_Type_Bool_Exp>;
};


export type Query_RootSetting_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootSocial_Media_TypeArgs = {
  distinct_on?: Maybe<Array<Social_Media_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Media_Type_Order_By>>;
  where?: Maybe<Social_Media_Type_Bool_Exp>;
};


export type Query_RootSocial_Media_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Media_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Media_Type_Order_By>>;
  where?: Maybe<Social_Media_Type_Bool_Exp>;
};


export type Query_RootSocial_Media_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootStudio_PictureArgs = {
  distinct_on?: Maybe<Array<Studio_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Studio_Picture_Order_By>>;
  where?: Maybe<Studio_Picture_Bool_Exp>;
};


export type Query_RootStudio_Picture_AggregateArgs = {
  distinct_on?: Maybe<Array<Studio_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Studio_Picture_Order_By>>;
  where?: Maybe<Studio_Picture_Bool_Exp>;
};


export type Query_RootStudio_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootStudio_ReviewArgs = {
  distinct_on?: Maybe<Array<Studio_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Studio_Review_Order_By>>;
  where?: Maybe<Studio_Review_Bool_Exp>;
};


export type Query_RootStudio_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Studio_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Studio_Review_Order_By>>;
  where?: Maybe<Studio_Review_Bool_Exp>;
};


export type Query_RootStudio_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeacherArgs = {
  distinct_on?: Maybe<Array<Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Order_By>>;
  where?: Maybe<Teacher_Bool_Exp>;
};


export type Query_RootTeacher_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Order_By>>;
  where?: Maybe<Teacher_Bool_Exp>;
};


export type Query_RootTeacher_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTeacher_ReviewArgs = {
  distinct_on?: Maybe<Array<Teacher_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Review_Order_By>>;
  where?: Maybe<Teacher_Review_Bool_Exp>;
};


export type Query_RootTeacher_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Review_Order_By>>;
  where?: Maybe<Teacher_Review_Bool_Exp>;
};


export type Query_RootTeacher_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTemp_Coming_Soon_StudentArgs = {
  distinct_on?: Maybe<Array<Temp_Coming_Soon_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Coming_Soon_Student_Order_By>>;
  where?: Maybe<Temp_Coming_Soon_Student_Bool_Exp>;
};


export type Query_RootTemp_Coming_Soon_Student_AggregateArgs = {
  distinct_on?: Maybe<Array<Temp_Coming_Soon_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Coming_Soon_Student_Order_By>>;
  where?: Maybe<Temp_Coming_Soon_Student_Bool_Exp>;
};


export type Query_RootTemp_Coming_Soon_Student_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTipArgs = {
  distinct_on?: Maybe<Array<Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Order_By>>;
  where?: Maybe<Tip_Bool_Exp>;
};


export type Query_RootTip_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Order_By>>;
  where?: Maybe<Tip_Bool_Exp>;
};


export type Query_RootTip_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTip_CurrentArgs = {
  distinct_on?: Maybe<Array<Tip_Current_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Current_Order_By>>;
  where?: Maybe<Tip_Current_Bool_Exp>;
};


export type Query_RootTip_Current_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Current_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Current_Order_By>>;
  where?: Maybe<Tip_Current_Bool_Exp>;
};


export type Query_RootTip_Current_By_PkArgs = {
  good_for: Scalars['String'];
};


export type Query_RootTip_SourceArgs = {
  distinct_on?: Maybe<Array<Tip_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Source_Order_By>>;
  where?: Maybe<Tip_Source_Bool_Exp>;
};


export type Query_RootTip_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Source_Order_By>>;
  where?: Maybe<Tip_Source_Bool_Exp>;
};


export type Query_RootTip_Source_By_PkArgs = {
  source: Scalars['String'];
};


export type Query_RootYoga_TypeArgs = {
  distinct_on?: Maybe<Array<Yoga_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Yoga_Type_Order_By>>;
  where?: Maybe<Yoga_Type_Bool_Exp>;
};


export type Query_RootYoga_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Yoga_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Yoga_Type_Order_By>>;
  where?: Maybe<Yoga_Type_Bool_Exp>;
};


export type Query_RootYoga_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** columns and relationships of "safety_guideline" */
export type Safety_Guideline = {
  __typename?: 'safety_guideline';
  description: Scalars['String'];
  id: Scalars['String'];
  label: Scalars['String'];
};

/** aggregated selection of "safety_guideline" */
export type Safety_Guideline_Aggregate = {
  __typename?: 'safety_guideline_aggregate';
  aggregate?: Maybe<Safety_Guideline_Aggregate_Fields>;
  nodes: Array<Safety_Guideline>;
};

/** aggregate fields of "safety_guideline" */
export type Safety_Guideline_Aggregate_Fields = {
  __typename?: 'safety_guideline_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Safety_Guideline_Max_Fields>;
  min?: Maybe<Safety_Guideline_Min_Fields>;
};


/** aggregate fields of "safety_guideline" */
export type Safety_Guideline_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Safety_Guideline_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "safety_guideline". All fields are combined with a logical 'AND'. */
export type Safety_Guideline_Bool_Exp = {
  _and?: Maybe<Array<Safety_Guideline_Bool_Exp>>;
  _not?: Maybe<Safety_Guideline_Bool_Exp>;
  _or?: Maybe<Array<Safety_Guideline_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "safety_guideline" */
export enum Safety_Guideline_Constraint {
  /** unique or primary key constraint */
  SafetyGuidelinesPkey = 'safety_guidelines_pkey'
}

/** input type for inserting data into table "safety_guideline" */
export type Safety_Guideline_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Safety_Guideline_Max_Fields = {
  __typename?: 'safety_guideline_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Safety_Guideline_Min_Fields = {
  __typename?: 'safety_guideline_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "safety_guideline" */
export type Safety_Guideline_Mutation_Response = {
  __typename?: 'safety_guideline_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Safety_Guideline>;
};

/** input type for inserting object relation for remote table "safety_guideline" */
export type Safety_Guideline_Obj_Rel_Insert_Input = {
  data: Safety_Guideline_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Safety_Guideline_On_Conflict>;
};

/** on conflict condition type for table "safety_guideline" */
export type Safety_Guideline_On_Conflict = {
  constraint: Safety_Guideline_Constraint;
  update_columns?: Array<Safety_Guideline_Update_Column>;
  where?: Maybe<Safety_Guideline_Bool_Exp>;
};

/** Ordering options when selecting data from "safety_guideline". */
export type Safety_Guideline_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
};

/** primary key columns input for table: safety_guideline */
export type Safety_Guideline_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "safety_guideline" */
export enum Safety_Guideline_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label'
}

/** input type for updating data in table "safety_guideline" */
export type Safety_Guideline_Set_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

/** update columns of table "safety_guideline" */
export enum Safety_Guideline_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label'
}

/** columns and relationships of "searching_history" */
export type Searching_History = {
  __typename?: 'searching_history';
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  placeID: Scalars['String'];
  searching: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "searching_history" */
export type Searching_History_Aggregate = {
  __typename?: 'searching_history_aggregate';
  aggregate?: Maybe<Searching_History_Aggregate_Fields>;
  nodes: Array<Searching_History>;
};

/** aggregate fields of "searching_history" */
export type Searching_History_Aggregate_Fields = {
  __typename?: 'searching_history_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Searching_History_Max_Fields>;
  min?: Maybe<Searching_History_Min_Fields>;
};


/** aggregate fields of "searching_history" */
export type Searching_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Searching_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "searching_history". All fields are combined with a logical 'AND'. */
export type Searching_History_Bool_Exp = {
  _and?: Maybe<Array<Searching_History_Bool_Exp>>;
  _not?: Maybe<Searching_History_Bool_Exp>;
  _or?: Maybe<Array<Searching_History_Bool_Exp>>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  placeID?: Maybe<String_Comparison_Exp>;
  searching?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "searching_history" */
export enum Searching_History_Constraint {
  /** unique or primary key constraint */
  SearchingHistoryPkey = 'searching_history_pkey'
}

/** input type for inserting data into table "searching_history" */
export type Searching_History_Insert_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  placeID?: Maybe<Scalars['String']>;
  searching?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Searching_History_Max_Fields = {
  __typename?: 'searching_history_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  placeID?: Maybe<Scalars['String']>;
  searching?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Searching_History_Min_Fields = {
  __typename?: 'searching_history_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  placeID?: Maybe<Scalars['String']>;
  searching?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "searching_history" */
export type Searching_History_Mutation_Response = {
  __typename?: 'searching_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Searching_History>;
};

/** on conflict condition type for table "searching_history" */
export type Searching_History_On_Conflict = {
  constraint: Searching_History_Constraint;
  update_columns?: Array<Searching_History_Update_Column>;
  where?: Maybe<Searching_History_Bool_Exp>;
};

/** Ordering options when selecting data from "searching_history". */
export type Searching_History_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  placeID?: Maybe<Order_By>;
  searching?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: searching_history */
export type Searching_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "searching_history" */
export enum Searching_History_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'placeID',
  /** column name */
  Searching = 'searching',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "searching_history" */
export type Searching_History_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  placeID?: Maybe<Scalars['String']>;
  searching?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "searching_history" */
export enum Searching_History_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlaceId = 'placeID',
  /** column name */
  Searching = 'searching',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "setting_notification" */
export type Setting_Notification = {
  __typename?: 'setting_notification';
  category: Setting_Notification_Category_Enum;
  created_at: Scalars['timestamptz'];
  /** dictates if the user can turn it off (false means it cannot) */
  customizable: Scalars['Boolean'];
  id: Scalars['uuid'];
  type: Setting_Notification_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "setting_notification" */
export type Setting_Notification_Aggregate = {
  __typename?: 'setting_notification_aggregate';
  aggregate?: Maybe<Setting_Notification_Aggregate_Fields>;
  nodes: Array<Setting_Notification>;
};

/** aggregate fields of "setting_notification" */
export type Setting_Notification_Aggregate_Fields = {
  __typename?: 'setting_notification_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Setting_Notification_Max_Fields>;
  min?: Maybe<Setting_Notification_Min_Fields>;
};


/** aggregate fields of "setting_notification" */
export type Setting_Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Setting_Notification_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "setting_notification". All fields are combined with a logical 'AND'. */
export type Setting_Notification_Bool_Exp = {
  _and?: Maybe<Array<Setting_Notification_Bool_Exp>>;
  _not?: Maybe<Setting_Notification_Bool_Exp>;
  _or?: Maybe<Array<Setting_Notification_Bool_Exp>>;
  category?: Maybe<Setting_Notification_Category_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  customizable?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<Setting_Notification_Type_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "setting_notification_category" */
export type Setting_Notification_Category = {
  __typename?: 'setting_notification_category';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "setting_notification_category" */
export type Setting_Notification_Category_Aggregate = {
  __typename?: 'setting_notification_category_aggregate';
  aggregate?: Maybe<Setting_Notification_Category_Aggregate_Fields>;
  nodes: Array<Setting_Notification_Category>;
};

/** aggregate fields of "setting_notification_category" */
export type Setting_Notification_Category_Aggregate_Fields = {
  __typename?: 'setting_notification_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Setting_Notification_Category_Max_Fields>;
  min?: Maybe<Setting_Notification_Category_Min_Fields>;
};


/** aggregate fields of "setting_notification_category" */
export type Setting_Notification_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Setting_Notification_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "setting_notification_category". All fields are combined with a logical 'AND'. */
export type Setting_Notification_Category_Bool_Exp = {
  _and?: Maybe<Array<Setting_Notification_Category_Bool_Exp>>;
  _not?: Maybe<Setting_Notification_Category_Bool_Exp>;
  _or?: Maybe<Array<Setting_Notification_Category_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "setting_notification_category" */
export enum Setting_Notification_Category_Constraint {
  /** unique or primary key constraint */
  SettingsNotificationCategoryPkey = 'settings_notification_category_pkey'
}

export enum Setting_Notification_Category_Enum {
  /** Receive payment status alerts, get receipts, and get notified of any billing issues. */
  Booking = 'booking',
  /** Receive updates on regulations and general policy, terms, and conditions from Ohmunity. */
  Policy = 'policy',
  /** Stay informed on new class packages, price reductions and special events. */
  Promotions = 'promotions',
  /** Receive messages from studios and teachers. */
  Reminders = 'reminders',
  /** Get notifications related to customer support. */
  Support = 'support'
}

/** Boolean expression to compare columns of type "setting_notification_category_enum". All fields are combined with logical 'AND'. */
export type Setting_Notification_Category_Enum_Comparison_Exp = {
  _eq?: Maybe<Setting_Notification_Category_Enum>;
  _in?: Maybe<Array<Setting_Notification_Category_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Setting_Notification_Category_Enum>;
  _nin?: Maybe<Array<Setting_Notification_Category_Enum>>;
};

/** input type for inserting data into table "setting_notification_category" */
export type Setting_Notification_Category_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Setting_Notification_Category_Max_Fields = {
  __typename?: 'setting_notification_category_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Setting_Notification_Category_Min_Fields = {
  __typename?: 'setting_notification_category_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "setting_notification_category" */
export type Setting_Notification_Category_Mutation_Response = {
  __typename?: 'setting_notification_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Setting_Notification_Category>;
};

/** on conflict condition type for table "setting_notification_category" */
export type Setting_Notification_Category_On_Conflict = {
  constraint: Setting_Notification_Category_Constraint;
  update_columns?: Array<Setting_Notification_Category_Update_Column>;
  where?: Maybe<Setting_Notification_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "setting_notification_category". */
export type Setting_Notification_Category_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: setting_notification_category */
export type Setting_Notification_Category_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "setting_notification_category" */
export enum Setting_Notification_Category_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "setting_notification_category" */
export type Setting_Notification_Category_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "setting_notification_category" */
export enum Setting_Notification_Category_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** unique or primary key constraints on table "setting_notification" */
export enum Setting_Notification_Constraint {
  /** unique or primary key constraint */
  SettingsNotificationPkey = 'settings_notification_pkey'
}

/** input type for inserting data into table "setting_notification" */
export type Setting_Notification_Insert_Input = {
  category?: Maybe<Setting_Notification_Category_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** dictates if the user can turn it off (false means it cannot) */
  customizable?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Setting_Notification_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Setting_Notification_Max_Fields = {
  __typename?: 'setting_notification_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Setting_Notification_Min_Fields = {
  __typename?: 'setting_notification_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "setting_notification" */
export type Setting_Notification_Mutation_Response = {
  __typename?: 'setting_notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Setting_Notification>;
};

/** on conflict condition type for table "setting_notification" */
export type Setting_Notification_On_Conflict = {
  constraint: Setting_Notification_Constraint;
  update_columns?: Array<Setting_Notification_Update_Column>;
  where?: Maybe<Setting_Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "setting_notification". */
export type Setting_Notification_Order_By = {
  category?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customizable?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: setting_notification */
export type Setting_Notification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "setting_notification" */
export enum Setting_Notification_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Customizable = 'customizable',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "setting_notification" */
export type Setting_Notification_Set_Input = {
  category?: Maybe<Setting_Notification_Category_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** dictates if the user can turn it off (false means it cannot) */
  customizable?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Setting_Notification_Type_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "setting_notification_type" */
export type Setting_Notification_Type = {
  __typename?: 'setting_notification_type';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "setting_notification_type" */
export type Setting_Notification_Type_Aggregate = {
  __typename?: 'setting_notification_type_aggregate';
  aggregate?: Maybe<Setting_Notification_Type_Aggregate_Fields>;
  nodes: Array<Setting_Notification_Type>;
};

/** aggregate fields of "setting_notification_type" */
export type Setting_Notification_Type_Aggregate_Fields = {
  __typename?: 'setting_notification_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Setting_Notification_Type_Max_Fields>;
  min?: Maybe<Setting_Notification_Type_Min_Fields>;
};


/** aggregate fields of "setting_notification_type" */
export type Setting_Notification_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Setting_Notification_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "setting_notification_type". All fields are combined with a logical 'AND'. */
export type Setting_Notification_Type_Bool_Exp = {
  _and?: Maybe<Array<Setting_Notification_Type_Bool_Exp>>;
  _not?: Maybe<Setting_Notification_Type_Bool_Exp>;
  _or?: Maybe<Array<Setting_Notification_Type_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "setting_notification_type" */
export enum Setting_Notification_Type_Constraint {
  /** unique or primary key constraint */
  NotificationsTypePkey = 'notifications_type_pkey'
}

export enum Setting_Notification_Type_Enum {
  /** Email */
  Email = 'email',
  /** Push Notifications */
  Push = 'push',
  /** Text Messages */
  Sms = 'sms'
}

/** Boolean expression to compare columns of type "setting_notification_type_enum". All fields are combined with logical 'AND'. */
export type Setting_Notification_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Setting_Notification_Type_Enum>;
  _in?: Maybe<Array<Setting_Notification_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Setting_Notification_Type_Enum>;
  _nin?: Maybe<Array<Setting_Notification_Type_Enum>>;
};

/** input type for inserting data into table "setting_notification_type" */
export type Setting_Notification_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Setting_Notification_Type_Max_Fields = {
  __typename?: 'setting_notification_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Setting_Notification_Type_Min_Fields = {
  __typename?: 'setting_notification_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "setting_notification_type" */
export type Setting_Notification_Type_Mutation_Response = {
  __typename?: 'setting_notification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Setting_Notification_Type>;
};

/** on conflict condition type for table "setting_notification_type" */
export type Setting_Notification_Type_On_Conflict = {
  constraint: Setting_Notification_Type_Constraint;
  update_columns?: Array<Setting_Notification_Type_Update_Column>;
  where?: Maybe<Setting_Notification_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "setting_notification_type". */
export type Setting_Notification_Type_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: setting_notification_type */
export type Setting_Notification_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "setting_notification_type" */
export enum Setting_Notification_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "setting_notification_type" */
export type Setting_Notification_Type_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "setting_notification_type" */
export enum Setting_Notification_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "setting_notification" */
export enum Setting_Notification_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Customizable = 'customizable',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** columns and relationships of "social_media_type" */
export type Social_Media_Type = {
  __typename?: 'social_media_type';
  value: Scalars['String'];
};

/** aggregated selection of "social_media_type" */
export type Social_Media_Type_Aggregate = {
  __typename?: 'social_media_type_aggregate';
  aggregate?: Maybe<Social_Media_Type_Aggregate_Fields>;
  nodes: Array<Social_Media_Type>;
};

/** aggregate fields of "social_media_type" */
export type Social_Media_Type_Aggregate_Fields = {
  __typename?: 'social_media_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Social_Media_Type_Max_Fields>;
  min?: Maybe<Social_Media_Type_Min_Fields>;
};


/** aggregate fields of "social_media_type" */
export type Social_Media_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Social_Media_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "social_media_type". All fields are combined with a logical 'AND'. */
export type Social_Media_Type_Bool_Exp = {
  _and?: Maybe<Array<Social_Media_Type_Bool_Exp>>;
  _not?: Maybe<Social_Media_Type_Bool_Exp>;
  _or?: Maybe<Array<Social_Media_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "social_media_type" */
export enum Social_Media_Type_Constraint {
  /** unique or primary key constraint */
  SocialMediaTypePkey = 'social_media_type_pkey'
}

export enum Social_Media_Type_Enum {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Linkedin = 'linkedin',
  Tiktok = 'tiktok',
  Twitter = 'twitter'
}

/** Boolean expression to compare columns of type "social_media_type_enum". All fields are combined with logical 'AND'. */
export type Social_Media_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Social_Media_Type_Enum>;
  _in?: Maybe<Array<Social_Media_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Social_Media_Type_Enum>;
  _nin?: Maybe<Array<Social_Media_Type_Enum>>;
};

/** input type for inserting data into table "social_media_type" */
export type Social_Media_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Social_Media_Type_Max_Fields = {
  __typename?: 'social_media_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Social_Media_Type_Min_Fields = {
  __typename?: 'social_media_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "social_media_type" */
export type Social_Media_Type_Mutation_Response = {
  __typename?: 'social_media_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Social_Media_Type>;
};

/** on conflict condition type for table "social_media_type" */
export type Social_Media_Type_On_Conflict = {
  constraint: Social_Media_Type_Constraint;
  update_columns?: Array<Social_Media_Type_Update_Column>;
  where?: Maybe<Social_Media_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "social_media_type". */
export type Social_Media_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: social_media_type */
export type Social_Media_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "social_media_type" */
export enum Social_Media_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "social_media_type" */
export type Social_Media_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "social_media_type" */
export enum Social_Media_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: Maybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Start_Dow_Class_Event_Args = {
  tz_interval?: Maybe<Scalars['String']>;
};

/** columns and relationships of "studio_picture" */
export type Studio_Picture = {
  __typename?: 'studio_picture';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  order: Scalars['Int'];
  owner_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "studio_picture" */
export type Studio_Picture_Aggregate = {
  __typename?: 'studio_picture_aggregate';
  aggregate?: Maybe<Studio_Picture_Aggregate_Fields>;
  nodes: Array<Studio_Picture>;
};

/** aggregate fields of "studio_picture" */
export type Studio_Picture_Aggregate_Fields = {
  __typename?: 'studio_picture_aggregate_fields';
  avg?: Maybe<Studio_Picture_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Studio_Picture_Max_Fields>;
  min?: Maybe<Studio_Picture_Min_Fields>;
  stddev?: Maybe<Studio_Picture_Stddev_Fields>;
  stddev_pop?: Maybe<Studio_Picture_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Studio_Picture_Stddev_Samp_Fields>;
  sum?: Maybe<Studio_Picture_Sum_Fields>;
  var_pop?: Maybe<Studio_Picture_Var_Pop_Fields>;
  var_samp?: Maybe<Studio_Picture_Var_Samp_Fields>;
  variance?: Maybe<Studio_Picture_Variance_Fields>;
};


/** aggregate fields of "studio_picture" */
export type Studio_Picture_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Studio_Picture_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "studio_picture" */
export type Studio_Picture_Aggregate_Order_By = {
  avg?: Maybe<Studio_Picture_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Studio_Picture_Max_Order_By>;
  min?: Maybe<Studio_Picture_Min_Order_By>;
  stddev?: Maybe<Studio_Picture_Stddev_Order_By>;
  stddev_pop?: Maybe<Studio_Picture_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Studio_Picture_Stddev_Samp_Order_By>;
  sum?: Maybe<Studio_Picture_Sum_Order_By>;
  var_pop?: Maybe<Studio_Picture_Var_Pop_Order_By>;
  var_samp?: Maybe<Studio_Picture_Var_Samp_Order_By>;
  variance?: Maybe<Studio_Picture_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "studio_picture" */
export type Studio_Picture_Arr_Rel_Insert_Input = {
  data: Array<Studio_Picture_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Studio_Picture_On_Conflict>;
};

/** aggregate avg on columns */
export type Studio_Picture_Avg_Fields = {
  __typename?: 'studio_picture_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "studio_picture" */
export type Studio_Picture_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "studio_picture". All fields are combined with a logical 'AND'. */
export type Studio_Picture_Bool_Exp = {
  _and?: Maybe<Array<Studio_Picture_Bool_Exp>>;
  _not?: Maybe<Studio_Picture_Bool_Exp>;
  _or?: Maybe<Array<Studio_Picture_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "studio_picture" */
export enum Studio_Picture_Constraint {
  /** unique or primary key constraint */
  StudioPicturePkey = 'studio_picture_pkey'
}

/** input type for incrementing numeric columns in table "studio_picture" */
export type Studio_Picture_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "studio_picture" */
export type Studio_Picture_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Studio_Picture_Max_Fields = {
  __typename?: 'studio_picture_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "studio_picture" */
export type Studio_Picture_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Studio_Picture_Min_Fields = {
  __typename?: 'studio_picture_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "studio_picture" */
export type Studio_Picture_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "studio_picture" */
export type Studio_Picture_Mutation_Response = {
  __typename?: 'studio_picture_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Studio_Picture>;
};

/** on conflict condition type for table "studio_picture" */
export type Studio_Picture_On_Conflict = {
  constraint: Studio_Picture_Constraint;
  update_columns?: Array<Studio_Picture_Update_Column>;
  where?: Maybe<Studio_Picture_Bool_Exp>;
};

/** Ordering options when selecting data from "studio_picture". */
export type Studio_Picture_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: studio_picture */
export type Studio_Picture_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "studio_picture" */
export enum Studio_Picture_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "studio_picture" */
export type Studio_Picture_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Studio_Picture_Stddev_Fields = {
  __typename?: 'studio_picture_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "studio_picture" */
export type Studio_Picture_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Studio_Picture_Stddev_Pop_Fields = {
  __typename?: 'studio_picture_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "studio_picture" */
export type Studio_Picture_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Studio_Picture_Stddev_Samp_Fields = {
  __typename?: 'studio_picture_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "studio_picture" */
export type Studio_Picture_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Studio_Picture_Sum_Fields = {
  __typename?: 'studio_picture_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "studio_picture" */
export type Studio_Picture_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "studio_picture" */
export enum Studio_Picture_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type Studio_Picture_Var_Pop_Fields = {
  __typename?: 'studio_picture_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "studio_picture" */
export type Studio_Picture_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Studio_Picture_Var_Samp_Fields = {
  __typename?: 'studio_picture_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "studio_picture" */
export type Studio_Picture_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Studio_Picture_Variance_Fields = {
  __typename?: 'studio_picture_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "studio_picture" */
export type Studio_Picture_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "studio_review" */
export type Studio_Review = {
  __typename?: 'studio_review';
  /** An object relationship */
  account: Account;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An object relationship */
  studio: Account;
  studio_id: Scalars['uuid'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  value: Scalars['Int'];
};

/** aggregated selection of "studio_review" */
export type Studio_Review_Aggregate = {
  __typename?: 'studio_review_aggregate';
  aggregate?: Maybe<Studio_Review_Aggregate_Fields>;
  nodes: Array<Studio_Review>;
};

/** aggregate fields of "studio_review" */
export type Studio_Review_Aggregate_Fields = {
  __typename?: 'studio_review_aggregate_fields';
  avg?: Maybe<Studio_Review_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Studio_Review_Max_Fields>;
  min?: Maybe<Studio_Review_Min_Fields>;
  stddev?: Maybe<Studio_Review_Stddev_Fields>;
  stddev_pop?: Maybe<Studio_Review_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Studio_Review_Stddev_Samp_Fields>;
  sum?: Maybe<Studio_Review_Sum_Fields>;
  var_pop?: Maybe<Studio_Review_Var_Pop_Fields>;
  var_samp?: Maybe<Studio_Review_Var_Samp_Fields>;
  variance?: Maybe<Studio_Review_Variance_Fields>;
};


/** aggregate fields of "studio_review" */
export type Studio_Review_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Studio_Review_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Studio_Review_Avg_Fields = {
  __typename?: 'studio_review_avg_fields';
  value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "studio_review". All fields are combined with a logical 'AND'. */
export type Studio_Review_Bool_Exp = {
  _and?: Maybe<Array<Studio_Review_Bool_Exp>>;
  _not?: Maybe<Studio_Review_Bool_Exp>;
  _or?: Maybe<Array<Studio_Review_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  studio?: Maybe<Account_Bool_Exp>;
  studio_id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "studio_review" */
export enum Studio_Review_Constraint {
  /** unique or primary key constraint */
  StudioReviewPkey = 'studio_review_pkey'
}

/** input type for incrementing numeric columns in table "studio_review" */
export type Studio_Review_Inc_Input = {
  value?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "studio_review" */
export type Studio_Review_Insert_Input = {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  studio?: Maybe<Account_Obj_Rel_Insert_Input>;
  studio_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Studio_Review_Max_Fields = {
  __typename?: 'studio_review_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  studio_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Studio_Review_Min_Fields = {
  __typename?: 'studio_review_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  studio_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "studio_review" */
export type Studio_Review_Mutation_Response = {
  __typename?: 'studio_review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Studio_Review>;
};

/** on conflict condition type for table "studio_review" */
export type Studio_Review_On_Conflict = {
  constraint: Studio_Review_Constraint;
  update_columns?: Array<Studio_Review_Update_Column>;
  where?: Maybe<Studio_Review_Bool_Exp>;
};

/** Ordering options when selecting data from "studio_review". */
export type Studio_Review_Order_By = {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  studio?: Maybe<Account_Order_By>;
  studio_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: studio_review */
export type Studio_Review_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "studio_review" */
export enum Studio_Review_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  StudioId = 'studio_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "studio_review" */
export type Studio_Review_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  studio_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Studio_Review_Stddev_Fields = {
  __typename?: 'studio_review_stddev_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Studio_Review_Stddev_Pop_Fields = {
  __typename?: 'studio_review_stddev_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Studio_Review_Stddev_Samp_Fields = {
  __typename?: 'studio_review_stddev_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Studio_Review_Sum_Fields = {
  __typename?: 'studio_review_sum_fields';
  value?: Maybe<Scalars['Int']>;
};

/** update columns of table "studio_review" */
export enum Studio_Review_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  StudioId = 'studio_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Studio_Review_Var_Pop_Fields = {
  __typename?: 'studio_review_var_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Studio_Review_Var_Samp_Fields = {
  __typename?: 'studio_review_var_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Studio_Review_Variance_Fields = {
  __typename?: 'studio_review_variance_fields';
  value?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table: "account_certification" */
  account_certification: Array<Account_Certification>;
  /** fetch aggregated fields from the table: "account_certification" */
  account_certification_aggregate: Account_Certification_Aggregate;
  /** fetch data from the table: "account_certification" using primary key columns */
  account_certification_by_pk?: Maybe<Account_Certification>;
  /** fetch data from the table: "account_link" */
  account_link: Array<Account_Link>;
  /** fetch aggregated fields from the table: "account_link" */
  account_link_aggregate: Account_Link_Aggregate;
  /** fetch data from the table: "account_link" using primary key columns */
  account_link_by_pk?: Maybe<Account_Link>;
  /** fetch data from the table: "account_role" */
  account_role: Array<Account_Role>;
  /** fetch aggregated fields from the table: "account_role" */
  account_role_aggregate: Account_Role_Aggregate;
  /** fetch data from the table: "account_role" using primary key columns */
  account_role_by_pk?: Maybe<Account_Role>;
  /** An array relationship */
  account_social_media: Array<Account_Social_Media>;
  /** An aggregate relationship */
  account_social_media_aggregate: Account_Social_Media_Aggregate;
  /** fetch data from the table: "account_social_media" using primary key columns */
  account_social_media_by_pk?: Maybe<Account_Social_Media>;
  /** fetch data from the table: "account_yoga_type" */
  account_yoga_type: Array<Account_Yoga_Type>;
  /** fetch aggregated fields from the table: "account_yoga_type" */
  account_yoga_type_aggregate: Account_Yoga_Type_Aggregate;
  /** fetch data from the table: "account_yoga_type" using primary key columns */
  account_yoga_type_by_pk?: Maybe<Account_Yoga_Type>;
  /** fetch data from the table: "blog" */
  blog: Array<Blog>;
  /** fetch aggregated fields from the table: "blog" */
  blog_aggregate: Blog_Aggregate;
  /** fetch data from the table: "blog" using primary key columns */
  blog_by_pk?: Maybe<Blog>;
  /** fetch data from the table: "blog_type" */
  blog_type: Array<Blog_Type>;
  /** fetch aggregated fields from the table: "blog_type" */
  blog_type_aggregate: Blog_Type_Aggregate;
  /** fetch data from the table: "blog_type" using primary key columns */
  blog_type_by_pk?: Maybe<Blog_Type>;
  /** fetch data from the table: "class" */
  class: Array<Class>;
  /** fetch aggregated fields from the table: "class" */
  class_aggregate: Class_Aggregate;
  /** fetch data from the table: "class_book" */
  class_book: Array<Class_Book>;
  /** fetch aggregated fields from the table: "class_book" */
  class_book_aggregate: Class_Book_Aggregate;
  /** fetch data from the table: "class_book" using primary key columns */
  class_book_by_pk?: Maybe<Class_Book>;
  /** fetch data from the table: "class_book_cancellation" */
  class_book_cancellation: Array<Class_Book_Cancellation>;
  /** fetch aggregated fields from the table: "class_book_cancellation" */
  class_book_cancellation_aggregate: Class_Book_Cancellation_Aggregate;
  /** fetch data from the table: "class_book_cancellation" using primary key columns */
  class_book_cancellation_by_pk?: Maybe<Class_Book_Cancellation>;
  /** fetch data from the table: "class" using primary key columns */
  class_by_pk?: Maybe<Class>;
  /** fetch data from the table: "class_cost_type" */
  class_cost_type: Array<Class_Cost_Type>;
  /** fetch aggregated fields from the table: "class_cost_type" */
  class_cost_type_aggregate: Class_Cost_Type_Aggregate;
  /** fetch data from the table: "class_cost_type" using primary key columns */
  class_cost_type_by_pk?: Maybe<Class_Cost_Type>;
  /** fetch data from the table: "class_date_time" */
  class_date_time: Array<Class_Date_Time>;
  /** fetch aggregated fields from the table: "class_date_time" */
  class_date_time_aggregate: Class_Date_Time_Aggregate;
  /** fetch data from the table: "class_date_time" using primary key columns */
  class_date_time_by_pk?: Maybe<Class_Date_Time>;
  /** fetch data from the table: "class_event" */
  class_event: Array<Class_Event>;
  /** fetch aggregated fields from the table: "class_event" */
  class_event_aggregate: Class_Event_Aggregate;
  /** fetch data from the table: "class_event" using primary key columns */
  class_event_by_pk?: Maybe<Class_Event>;
  /** fetch data from the table: "class_level" */
  class_level: Array<Class_Level>;
  /** fetch aggregated fields from the table: "class_level" */
  class_level_aggregate: Class_Level_Aggregate;
  /** fetch data from the table: "class_level" using primary key columns */
  class_level_by_pk?: Maybe<Class_Level>;
  /** fetch data from the table: "class_picture" */
  class_picture: Array<Class_Picture>;
  /** fetch aggregated fields from the table: "class_picture" */
  class_picture_aggregate: Class_Picture_Aggregate;
  /** fetch data from the table: "class_picture" using primary key columns */
  class_picture_by_pk?: Maybe<Class_Picture>;
  /** fetch data from the table: "class_recurrence" */
  class_recurrence: Array<Class_Recurrence>;
  /** fetch aggregated fields from the table: "class_recurrence" */
  class_recurrence_aggregate: Class_Recurrence_Aggregate;
  /** fetch data from the table: "class_recurrence" using primary key columns */
  class_recurrence_by_pk?: Maybe<Class_Recurrence>;
  /** fetch data from the table: "class_review" */
  class_review: Array<Class_Review>;
  /** fetch aggregated fields from the table: "class_review" */
  class_review_aggregate: Class_Review_Aggregate;
  /** fetch data from the table: "class_review" using primary key columns */
  class_review_by_pk?: Maybe<Class_Review>;
  /** fetch data from the table: "class_safety_guideline" */
  class_safety_guideline: Array<Class_Safety_Guideline>;
  /** fetch aggregated fields from the table: "class_safety_guideline" */
  class_safety_guideline_aggregate: Class_Safety_Guideline_Aggregate;
  /** fetch data from the table: "class_safety_guideline" using primary key columns */
  class_safety_guideline_by_pk?: Maybe<Class_Safety_Guideline>;
  /** fetch data from the table: "class_setting" */
  class_setting: Array<Class_Setting>;
  /** fetch aggregated fields from the table: "class_setting" */
  class_setting_aggregate: Class_Setting_Aggregate;
  /** fetch data from the table: "class_setting" using primary key columns */
  class_setting_by_pk?: Maybe<Class_Setting>;
  /** fetch data from the table: "favorite_class" */
  favorite_class: Array<Favorite_Class>;
  /** fetch aggregated fields from the table: "favorite_class" */
  favorite_class_aggregate: Favorite_Class_Aggregate;
  /** fetch data from the table: "favorite_class" using primary key columns */
  favorite_class_by_pk?: Maybe<Favorite_Class>;
  /** fetch data from the table: "insurance_document" */
  insurance_document: Array<Insurance_Document>;
  /** fetch aggregated fields from the table: "insurance_document" */
  insurance_document_aggregate: Insurance_Document_Aggregate;
  /** fetch data from the table: "insurance_document" using primary key columns */
  insurance_document_by_pk?: Maybe<Insurance_Document>;
  /** fetch data from the table: "insurance_document_date_filter" */
  insurance_document_date_filter: Array<Insurance_Document_Date_Filter>;
  /** fetch aggregated fields from the table: "insurance_document_date_filter" */
  insurance_document_date_filter_aggregate: Insurance_Document_Date_Filter_Aggregate;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "preferences" */
  preferences: Array<Preferences>;
  /** fetch aggregated fields from the table: "preferences" */
  preferences_aggregate: Preferences_Aggregate;
  /** fetch data from the table: "preferences" using primary key columns */
  preferences_by_pk?: Maybe<Preferences>;
  /** fetch data from the table: "profile_entity" */
  profile_entity: Array<Profile_Entity>;
  /** fetch aggregated fields from the table: "profile_entity" */
  profile_entity_aggregate: Profile_Entity_Aggregate;
  /** fetch data from the table: "profile_entity" using primary key columns */
  profile_entity_by_pk?: Maybe<Profile_Entity>;
  /** fetch data from the table: "profile_person" */
  profile_person: Array<Profile_Person>;
  /** fetch aggregated fields from the table: "profile_person" */
  profile_person_aggregate: Profile_Person_Aggregate;
  /** fetch data from the table: "profile_person" using primary key columns */
  profile_person_by_pk?: Maybe<Profile_Person>;
  /** fetch data from the table: "safety_guideline" */
  safety_guideline: Array<Safety_Guideline>;
  /** fetch aggregated fields from the table: "safety_guideline" */
  safety_guideline_aggregate: Safety_Guideline_Aggregate;
  /** fetch data from the table: "safety_guideline" using primary key columns */
  safety_guideline_by_pk?: Maybe<Safety_Guideline>;
  /** fetch data from the table: "searching_history" */
  searching_history: Array<Searching_History>;
  /** fetch aggregated fields from the table: "searching_history" */
  searching_history_aggregate: Searching_History_Aggregate;
  /** fetch data from the table: "searching_history" using primary key columns */
  searching_history_by_pk?: Maybe<Searching_History>;
  /** fetch data from the table: "setting_notification" */
  setting_notification: Array<Setting_Notification>;
  /** fetch aggregated fields from the table: "setting_notification" */
  setting_notification_aggregate: Setting_Notification_Aggregate;
  /** fetch data from the table: "setting_notification" using primary key columns */
  setting_notification_by_pk?: Maybe<Setting_Notification>;
  /** fetch data from the table: "setting_notification_category" */
  setting_notification_category: Array<Setting_Notification_Category>;
  /** fetch aggregated fields from the table: "setting_notification_category" */
  setting_notification_category_aggregate: Setting_Notification_Category_Aggregate;
  /** fetch data from the table: "setting_notification_category" using primary key columns */
  setting_notification_category_by_pk?: Maybe<Setting_Notification_Category>;
  /** fetch data from the table: "setting_notification_type" */
  setting_notification_type: Array<Setting_Notification_Type>;
  /** fetch aggregated fields from the table: "setting_notification_type" */
  setting_notification_type_aggregate: Setting_Notification_Type_Aggregate;
  /** fetch data from the table: "setting_notification_type" using primary key columns */
  setting_notification_type_by_pk?: Maybe<Setting_Notification_Type>;
  /** fetch data from the table: "social_media_type" */
  social_media_type: Array<Social_Media_Type>;
  /** fetch aggregated fields from the table: "social_media_type" */
  social_media_type_aggregate: Social_Media_Type_Aggregate;
  /** fetch data from the table: "social_media_type" using primary key columns */
  social_media_type_by_pk?: Maybe<Social_Media_Type>;
  /** fetch data from the table: "studio_picture" */
  studio_picture: Array<Studio_Picture>;
  /** fetch aggregated fields from the table: "studio_picture" */
  studio_picture_aggregate: Studio_Picture_Aggregate;
  /** fetch data from the table: "studio_picture" using primary key columns */
  studio_picture_by_pk?: Maybe<Studio_Picture>;
  /** fetch data from the table: "studio_review" */
  studio_review: Array<Studio_Review>;
  /** fetch aggregated fields from the table: "studio_review" */
  studio_review_aggregate: Studio_Review_Aggregate;
  /** fetch data from the table: "studio_review" using primary key columns */
  studio_review_by_pk?: Maybe<Studio_Review>;
  /** fetch data from the table: "teacher" */
  teacher: Array<Teacher>;
  /** fetch aggregated fields from the table: "teacher" */
  teacher_aggregate: Teacher_Aggregate;
  /** fetch data from the table: "teacher" using primary key columns */
  teacher_by_pk?: Maybe<Teacher>;
  /** fetch data from the table: "teacher_review" */
  teacher_review: Array<Teacher_Review>;
  /** fetch aggregated fields from the table: "teacher_review" */
  teacher_review_aggregate: Teacher_Review_Aggregate;
  /** fetch data from the table: "teacher_review" using primary key columns */
  teacher_review_by_pk?: Maybe<Teacher_Review>;
  /** fetch data from the table: "temp_coming_soon_student" */
  temp_coming_soon_student: Array<Temp_Coming_Soon_Student>;
  /** fetch aggregated fields from the table: "temp_coming_soon_student" */
  temp_coming_soon_student_aggregate: Temp_Coming_Soon_Student_Aggregate;
  /** fetch data from the table: "temp_coming_soon_student" using primary key columns */
  temp_coming_soon_student_by_pk?: Maybe<Temp_Coming_Soon_Student>;
  /** fetch data from the table: "tip" */
  tip: Array<Tip>;
  /** fetch aggregated fields from the table: "tip" */
  tip_aggregate: Tip_Aggregate;
  /** fetch data from the table: "tip" using primary key columns */
  tip_by_pk?: Maybe<Tip>;
  /** fetch data from the table: "tip_current" */
  tip_current: Array<Tip_Current>;
  /** fetch aggregated fields from the table: "tip_current" */
  tip_current_aggregate: Tip_Current_Aggregate;
  /** fetch data from the table: "tip_current" using primary key columns */
  tip_current_by_pk?: Maybe<Tip_Current>;
  /** fetch data from the table: "tip_source" */
  tip_source: Array<Tip_Source>;
  /** fetch aggregated fields from the table: "tip_source" */
  tip_source_aggregate: Tip_Source_Aggregate;
  /** fetch data from the table: "tip_source" using primary key columns */
  tip_source_by_pk?: Maybe<Tip_Source>;
  /** fetch data from the table: "yoga_type" */
  yoga_type: Array<Yoga_Type>;
  /** fetch aggregated fields from the table: "yoga_type" */
  yoga_type_aggregate: Yoga_Type_Aggregate;
  /** fetch data from the table: "yoga_type" using primary key columns */
  yoga_type_by_pk?: Maybe<Yoga_Type>;
};


export type Subscription_RootAccountArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Subscription_RootAccount_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Order_By>>;
  where?: Maybe<Account_Bool_Exp>;
};


export type Subscription_RootAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAccount_CertificationArgs = {
  distinct_on?: Maybe<Array<Account_Certification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Certification_Order_By>>;
  where?: Maybe<Account_Certification_Bool_Exp>;
};


export type Subscription_RootAccount_Certification_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Certification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Certification_Order_By>>;
  where?: Maybe<Account_Certification_Bool_Exp>;
};


export type Subscription_RootAccount_Certification_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAccount_LinkArgs = {
  distinct_on?: Maybe<Array<Account_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Link_Order_By>>;
  where?: Maybe<Account_Link_Bool_Exp>;
};


export type Subscription_RootAccount_Link_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Link_Order_By>>;
  where?: Maybe<Account_Link_Bool_Exp>;
};


export type Subscription_RootAccount_Link_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAccount_RoleArgs = {
  distinct_on?: Maybe<Array<Account_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Role_Order_By>>;
  where?: Maybe<Account_Role_Bool_Exp>;
};


export type Subscription_RootAccount_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Role_Order_By>>;
  where?: Maybe<Account_Role_Bool_Exp>;
};


export type Subscription_RootAccount_Role_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAccount_Social_MediaArgs = {
  distinct_on?: Maybe<Array<Account_Social_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Social_Media_Order_By>>;
  where?: Maybe<Account_Social_Media_Bool_Exp>;
};


export type Subscription_RootAccount_Social_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Social_Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Social_Media_Order_By>>;
  where?: Maybe<Account_Social_Media_Bool_Exp>;
};


export type Subscription_RootAccount_Social_Media_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAccount_Yoga_TypeArgs = {
  distinct_on?: Maybe<Array<Account_Yoga_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Yoga_Type_Order_By>>;
  where?: Maybe<Account_Yoga_Type_Bool_Exp>;
};


export type Subscription_RootAccount_Yoga_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Yoga_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Yoga_Type_Order_By>>;
  where?: Maybe<Account_Yoga_Type_Bool_Exp>;
};


export type Subscription_RootAccount_Yoga_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootBlogArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};


export type Subscription_RootBlog_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Order_By>>;
  where?: Maybe<Blog_Bool_Exp>;
};


export type Subscription_RootBlog_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBlog_TypeArgs = {
  distinct_on?: Maybe<Array<Blog_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Type_Order_By>>;
  where?: Maybe<Blog_Type_Bool_Exp>;
};


export type Subscription_RootBlog_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Blog_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blog_Type_Order_By>>;
  where?: Maybe<Blog_Type_Bool_Exp>;
};


export type Subscription_RootBlog_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootClassArgs = {
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Subscription_RootClass_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Order_By>>;
  where?: Maybe<Class_Bool_Exp>;
};


export type Subscription_RootClass_BookArgs = {
  distinct_on?: Maybe<Array<Class_Book_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Book_Order_By>>;
  where?: Maybe<Class_Book_Bool_Exp>;
};


export type Subscription_RootClass_Book_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Book_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Book_Order_By>>;
  where?: Maybe<Class_Book_Bool_Exp>;
};


export type Subscription_RootClass_Book_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClass_Book_CancellationArgs = {
  distinct_on?: Maybe<Array<Class_Book_Cancellation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Book_Cancellation_Order_By>>;
  where?: Maybe<Class_Book_Cancellation_Bool_Exp>;
};


export type Subscription_RootClass_Book_Cancellation_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Book_Cancellation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Book_Cancellation_Order_By>>;
  where?: Maybe<Class_Book_Cancellation_Bool_Exp>;
};


export type Subscription_RootClass_Book_Cancellation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClass_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClass_Cost_TypeArgs = {
  distinct_on?: Maybe<Array<Class_Cost_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Cost_Type_Order_By>>;
  where?: Maybe<Class_Cost_Type_Bool_Exp>;
};


export type Subscription_RootClass_Cost_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Cost_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Cost_Type_Order_By>>;
  where?: Maybe<Class_Cost_Type_Bool_Exp>;
};


export type Subscription_RootClass_Cost_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootClass_Date_TimeArgs = {
  distinct_on?: Maybe<Array<Class_Date_Time_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Date_Time_Order_By>>;
  where?: Maybe<Class_Date_Time_Bool_Exp>;
};


export type Subscription_RootClass_Date_Time_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Date_Time_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Date_Time_Order_By>>;
  where?: Maybe<Class_Date_Time_Bool_Exp>;
};


export type Subscription_RootClass_Date_Time_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClass_EventArgs = {
  distinct_on?: Maybe<Array<Class_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Event_Order_By>>;
  where?: Maybe<Class_Event_Bool_Exp>;
};


export type Subscription_RootClass_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Event_Order_By>>;
  where?: Maybe<Class_Event_Bool_Exp>;
};


export type Subscription_RootClass_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClass_LevelArgs = {
  distinct_on?: Maybe<Array<Class_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Level_Order_By>>;
  where?: Maybe<Class_Level_Bool_Exp>;
};


export type Subscription_RootClass_Level_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Level_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Level_Order_By>>;
  where?: Maybe<Class_Level_Bool_Exp>;
};


export type Subscription_RootClass_Level_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootClass_PictureArgs = {
  distinct_on?: Maybe<Array<Class_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Picture_Order_By>>;
  where?: Maybe<Class_Picture_Bool_Exp>;
};


export type Subscription_RootClass_Picture_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Picture_Order_By>>;
  where?: Maybe<Class_Picture_Bool_Exp>;
};


export type Subscription_RootClass_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClass_RecurrenceArgs = {
  distinct_on?: Maybe<Array<Class_Recurrence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Recurrence_Order_By>>;
  where?: Maybe<Class_Recurrence_Bool_Exp>;
};


export type Subscription_RootClass_Recurrence_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Recurrence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Recurrence_Order_By>>;
  where?: Maybe<Class_Recurrence_Bool_Exp>;
};


export type Subscription_RootClass_Recurrence_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootClass_ReviewArgs = {
  distinct_on?: Maybe<Array<Class_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Review_Order_By>>;
  where?: Maybe<Class_Review_Bool_Exp>;
};


export type Subscription_RootClass_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Review_Order_By>>;
  where?: Maybe<Class_Review_Bool_Exp>;
};


export type Subscription_RootClass_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClass_Safety_GuidelineArgs = {
  distinct_on?: Maybe<Array<Class_Safety_Guideline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Safety_Guideline_Order_By>>;
  where?: Maybe<Class_Safety_Guideline_Bool_Exp>;
};


export type Subscription_RootClass_Safety_Guideline_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Safety_Guideline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Safety_Guideline_Order_By>>;
  where?: Maybe<Class_Safety_Guideline_Bool_Exp>;
};


export type Subscription_RootClass_Safety_Guideline_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClass_SettingArgs = {
  distinct_on?: Maybe<Array<Class_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Setting_Order_By>>;
  where?: Maybe<Class_Setting_Bool_Exp>;
};


export type Subscription_RootClass_Setting_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Setting_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Class_Setting_Order_By>>;
  where?: Maybe<Class_Setting_Bool_Exp>;
};


export type Subscription_RootClass_Setting_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFavorite_ClassArgs = {
  distinct_on?: Maybe<Array<Favorite_Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Favorite_Class_Order_By>>;
  where?: Maybe<Favorite_Class_Bool_Exp>;
};


export type Subscription_RootFavorite_Class_AggregateArgs = {
  distinct_on?: Maybe<Array<Favorite_Class_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Favorite_Class_Order_By>>;
  where?: Maybe<Favorite_Class_Bool_Exp>;
};


export type Subscription_RootFavorite_Class_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInsurance_DocumentArgs = {
  distinct_on?: Maybe<Array<Insurance_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Document_Order_By>>;
  where?: Maybe<Insurance_Document_Bool_Exp>;
};


export type Subscription_RootInsurance_Document_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Document_Order_By>>;
  where?: Maybe<Insurance_Document_Bool_Exp>;
};


export type Subscription_RootInsurance_Document_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootInsurance_Document_Date_FilterArgs = {
  distinct_on?: Maybe<Array<Insurance_Document_Date_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Document_Date_Filter_Order_By>>;
  where?: Maybe<Insurance_Document_Date_Filter_Bool_Exp>;
};


export type Subscription_RootInsurance_Document_Date_Filter_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Document_Date_Filter_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Document_Date_Filter_Order_By>>;
  where?: Maybe<Insurance_Document_Date_Filter_Bool_Exp>;
};


export type Subscription_RootLocationArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};


export type Subscription_RootLocation_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};


export type Subscription_RootLocation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNotificationArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Order_By>>;
  where?: Maybe<Notification_Bool_Exp>;
};


export type Subscription_RootNotification_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Notification_Order_By>>;
  where?: Maybe<Notification_Bool_Exp>;
};


export type Subscription_RootNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPreferencesArgs = {
  distinct_on?: Maybe<Array<Preferences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Preferences_Order_By>>;
  where?: Maybe<Preferences_Bool_Exp>;
};


export type Subscription_RootPreferences_AggregateArgs = {
  distinct_on?: Maybe<Array<Preferences_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Preferences_Order_By>>;
  where?: Maybe<Preferences_Bool_Exp>;
};


export type Subscription_RootPreferences_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProfile_EntityArgs = {
  distinct_on?: Maybe<Array<Profile_Entity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Entity_Order_By>>;
  where?: Maybe<Profile_Entity_Bool_Exp>;
};


export type Subscription_RootProfile_Entity_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Entity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Entity_Order_By>>;
  where?: Maybe<Profile_Entity_Bool_Exp>;
};


export type Subscription_RootProfile_Entity_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProfile_PersonArgs = {
  distinct_on?: Maybe<Array<Profile_Person_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Person_Order_By>>;
  where?: Maybe<Profile_Person_Bool_Exp>;
};


export type Subscription_RootProfile_Person_AggregateArgs = {
  distinct_on?: Maybe<Array<Profile_Person_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Profile_Person_Order_By>>;
  where?: Maybe<Profile_Person_Bool_Exp>;
};


export type Subscription_RootProfile_Person_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSafety_GuidelineArgs = {
  distinct_on?: Maybe<Array<Safety_Guideline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Safety_Guideline_Order_By>>;
  where?: Maybe<Safety_Guideline_Bool_Exp>;
};


export type Subscription_RootSafety_Guideline_AggregateArgs = {
  distinct_on?: Maybe<Array<Safety_Guideline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Safety_Guideline_Order_By>>;
  where?: Maybe<Safety_Guideline_Bool_Exp>;
};


export type Subscription_RootSafety_Guideline_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootSearching_HistoryArgs = {
  distinct_on?: Maybe<Array<Searching_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Searching_History_Order_By>>;
  where?: Maybe<Searching_History_Bool_Exp>;
};


export type Subscription_RootSearching_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Searching_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Searching_History_Order_By>>;
  where?: Maybe<Searching_History_Bool_Exp>;
};


export type Subscription_RootSearching_History_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSetting_NotificationArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Order_By>>;
  where?: Maybe<Setting_Notification_Bool_Exp>;
};


export type Subscription_RootSetting_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Order_By>>;
  where?: Maybe<Setting_Notification_Bool_Exp>;
};


export type Subscription_RootSetting_Notification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSetting_Notification_CategoryArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Category_Order_By>>;
  where?: Maybe<Setting_Notification_Category_Bool_Exp>;
};


export type Subscription_RootSetting_Notification_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Category_Order_By>>;
  where?: Maybe<Setting_Notification_Category_Bool_Exp>;
};


export type Subscription_RootSetting_Notification_Category_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSetting_Notification_TypeArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Type_Order_By>>;
  where?: Maybe<Setting_Notification_Type_Bool_Exp>;
};


export type Subscription_RootSetting_Notification_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Setting_Notification_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Setting_Notification_Type_Order_By>>;
  where?: Maybe<Setting_Notification_Type_Bool_Exp>;
};


export type Subscription_RootSetting_Notification_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootSocial_Media_TypeArgs = {
  distinct_on?: Maybe<Array<Social_Media_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Media_Type_Order_By>>;
  where?: Maybe<Social_Media_Type_Bool_Exp>;
};


export type Subscription_RootSocial_Media_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Social_Media_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Social_Media_Type_Order_By>>;
  where?: Maybe<Social_Media_Type_Bool_Exp>;
};


export type Subscription_RootSocial_Media_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootStudio_PictureArgs = {
  distinct_on?: Maybe<Array<Studio_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Studio_Picture_Order_By>>;
  where?: Maybe<Studio_Picture_Bool_Exp>;
};


export type Subscription_RootStudio_Picture_AggregateArgs = {
  distinct_on?: Maybe<Array<Studio_Picture_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Studio_Picture_Order_By>>;
  where?: Maybe<Studio_Picture_Bool_Exp>;
};


export type Subscription_RootStudio_Picture_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStudio_ReviewArgs = {
  distinct_on?: Maybe<Array<Studio_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Studio_Review_Order_By>>;
  where?: Maybe<Studio_Review_Bool_Exp>;
};


export type Subscription_RootStudio_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Studio_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Studio_Review_Order_By>>;
  where?: Maybe<Studio_Review_Bool_Exp>;
};


export type Subscription_RootStudio_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeacherArgs = {
  distinct_on?: Maybe<Array<Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Order_By>>;
  where?: Maybe<Teacher_Bool_Exp>;
};


export type Subscription_RootTeacher_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Order_By>>;
  where?: Maybe<Teacher_Bool_Exp>;
};


export type Subscription_RootTeacher_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTeacher_ReviewArgs = {
  distinct_on?: Maybe<Array<Teacher_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Review_Order_By>>;
  where?: Maybe<Teacher_Review_Bool_Exp>;
};


export type Subscription_RootTeacher_Review_AggregateArgs = {
  distinct_on?: Maybe<Array<Teacher_Review_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Teacher_Review_Order_By>>;
  where?: Maybe<Teacher_Review_Bool_Exp>;
};


export type Subscription_RootTeacher_Review_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTemp_Coming_Soon_StudentArgs = {
  distinct_on?: Maybe<Array<Temp_Coming_Soon_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Coming_Soon_Student_Order_By>>;
  where?: Maybe<Temp_Coming_Soon_Student_Bool_Exp>;
};


export type Subscription_RootTemp_Coming_Soon_Student_AggregateArgs = {
  distinct_on?: Maybe<Array<Temp_Coming_Soon_Student_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Temp_Coming_Soon_Student_Order_By>>;
  where?: Maybe<Temp_Coming_Soon_Student_Bool_Exp>;
};


export type Subscription_RootTemp_Coming_Soon_Student_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTipArgs = {
  distinct_on?: Maybe<Array<Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Order_By>>;
  where?: Maybe<Tip_Bool_Exp>;
};


export type Subscription_RootTip_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Order_By>>;
  where?: Maybe<Tip_Bool_Exp>;
};


export type Subscription_RootTip_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTip_CurrentArgs = {
  distinct_on?: Maybe<Array<Tip_Current_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Current_Order_By>>;
  where?: Maybe<Tip_Current_Bool_Exp>;
};


export type Subscription_RootTip_Current_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Current_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Current_Order_By>>;
  where?: Maybe<Tip_Current_Bool_Exp>;
};


export type Subscription_RootTip_Current_By_PkArgs = {
  good_for: Scalars['String'];
};


export type Subscription_RootTip_SourceArgs = {
  distinct_on?: Maybe<Array<Tip_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Source_Order_By>>;
  where?: Maybe<Tip_Source_Bool_Exp>;
};


export type Subscription_RootTip_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Source_Order_By>>;
  where?: Maybe<Tip_Source_Bool_Exp>;
};


export type Subscription_RootTip_Source_By_PkArgs = {
  source: Scalars['String'];
};


export type Subscription_RootYoga_TypeArgs = {
  distinct_on?: Maybe<Array<Yoga_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Yoga_Type_Order_By>>;
  where?: Maybe<Yoga_Type_Bool_Exp>;
};


export type Subscription_RootYoga_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Yoga_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Yoga_Type_Order_By>>;
  where?: Maybe<Yoga_Type_Bool_Exp>;
};


export type Subscription_RootYoga_Type_By_PkArgs = {
  value: Scalars['String'];
};

/** columns and relationships of "teacher" */
export type Teacher = {
  __typename?: 'teacher';
  /** An object relationship */
  account?: Maybe<Account>;
  account_id?: Maybe<Scalars['uuid']>;
  bio: Scalars['String'];
  cover_picture?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  partOfAccount?: Maybe<Account>;
  part_of_account_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  person: Profile_Person;
  person_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "teacher" */
export type Teacher_Aggregate = {
  __typename?: 'teacher_aggregate';
  aggregate?: Maybe<Teacher_Aggregate_Fields>;
  nodes: Array<Teacher>;
};

/** aggregate fields of "teacher" */
export type Teacher_Aggregate_Fields = {
  __typename?: 'teacher_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Teacher_Max_Fields>;
  min?: Maybe<Teacher_Min_Fields>;
};


/** aggregate fields of "teacher" */
export type Teacher_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "teacher". All fields are combined with a logical 'AND'. */
export type Teacher_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Bool_Exp>>;
  _not?: Maybe<Teacher_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  bio?: Maybe<String_Comparison_Exp>;
  cover_picture?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  partOfAccount?: Maybe<Account_Bool_Exp>;
  part_of_account_id?: Maybe<Uuid_Comparison_Exp>;
  person?: Maybe<Profile_Person_Bool_Exp>;
  person_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "teacher" */
export enum Teacher_Constraint {
  /** unique or primary key constraint */
  TeacherPkey = 'teacher_pkey'
}

/** input type for inserting data into table "teacher" */
export type Teacher_Insert_Input = {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  bio?: Maybe<Scalars['String']>;
  cover_picture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  partOfAccount?: Maybe<Account_Obj_Rel_Insert_Input>;
  part_of_account_id?: Maybe<Scalars['uuid']>;
  person?: Maybe<Profile_Person_Obj_Rel_Insert_Input>;
  person_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Teacher_Max_Fields = {
  __typename?: 'teacher_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  bio?: Maybe<Scalars['String']>;
  cover_picture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  part_of_account_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Teacher_Min_Fields = {
  __typename?: 'teacher_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  bio?: Maybe<Scalars['String']>;
  cover_picture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  part_of_account_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "teacher" */
export type Teacher_Mutation_Response = {
  __typename?: 'teacher_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher>;
};

/** input type for inserting object relation for remote table "teacher" */
export type Teacher_Obj_Rel_Insert_Input = {
  data: Teacher_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Teacher_On_Conflict>;
};

/** on conflict condition type for table "teacher" */
export type Teacher_On_Conflict = {
  constraint: Teacher_Constraint;
  update_columns?: Array<Teacher_Update_Column>;
  where?: Maybe<Teacher_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher". */
export type Teacher_Order_By = {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  bio?: Maybe<Order_By>;
  cover_picture?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  partOfAccount?: Maybe<Account_Order_By>;
  part_of_account_id?: Maybe<Order_By>;
  person?: Maybe<Profile_Person_Order_By>;
  person_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: teacher */
export type Teacher_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "teacher_review" */
export type Teacher_Review = {
  __typename?: 'teacher_review';
  /** An object relationship */
  account: Account;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  teacher_id: Scalars['uuid'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  value: Scalars['Int'];
};

/** aggregated selection of "teacher_review" */
export type Teacher_Review_Aggregate = {
  __typename?: 'teacher_review_aggregate';
  aggregate?: Maybe<Teacher_Review_Aggregate_Fields>;
  nodes: Array<Teacher_Review>;
};

/** aggregate fields of "teacher_review" */
export type Teacher_Review_Aggregate_Fields = {
  __typename?: 'teacher_review_aggregate_fields';
  avg?: Maybe<Teacher_Review_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Teacher_Review_Max_Fields>;
  min?: Maybe<Teacher_Review_Min_Fields>;
  stddev?: Maybe<Teacher_Review_Stddev_Fields>;
  stddev_pop?: Maybe<Teacher_Review_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Teacher_Review_Stddev_Samp_Fields>;
  sum?: Maybe<Teacher_Review_Sum_Fields>;
  var_pop?: Maybe<Teacher_Review_Var_Pop_Fields>;
  var_samp?: Maybe<Teacher_Review_Var_Samp_Fields>;
  variance?: Maybe<Teacher_Review_Variance_Fields>;
};


/** aggregate fields of "teacher_review" */
export type Teacher_Review_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Teacher_Review_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Teacher_Review_Avg_Fields = {
  __typename?: 'teacher_review_avg_fields';
  value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "teacher_review". All fields are combined with a logical 'AND'. */
export type Teacher_Review_Bool_Exp = {
  _and?: Maybe<Array<Teacher_Review_Bool_Exp>>;
  _not?: Maybe<Teacher_Review_Bool_Exp>;
  _or?: Maybe<Array<Teacher_Review_Bool_Exp>>;
  account?: Maybe<Account_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  teacher_id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  value?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "teacher_review" */
export enum Teacher_Review_Constraint {
  /** unique or primary key constraint */
  TeacherReviewPkey = 'teacher_review_pkey'
}

/** input type for incrementing numeric columns in table "teacher_review" */
export type Teacher_Review_Inc_Input = {
  value?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "teacher_review" */
export type Teacher_Review_Insert_Input = {
  account?: Maybe<Account_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Teacher_Review_Max_Fields = {
  __typename?: 'teacher_review_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Teacher_Review_Min_Fields = {
  __typename?: 'teacher_review_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "teacher_review" */
export type Teacher_Review_Mutation_Response = {
  __typename?: 'teacher_review_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Teacher_Review>;
};

/** on conflict condition type for table "teacher_review" */
export type Teacher_Review_On_Conflict = {
  constraint: Teacher_Review_Constraint;
  update_columns?: Array<Teacher_Review_Update_Column>;
  where?: Maybe<Teacher_Review_Bool_Exp>;
};

/** Ordering options when selecting data from "teacher_review". */
export type Teacher_Review_Order_By = {
  account?: Maybe<Account_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  teacher_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: teacher_review */
export type Teacher_Review_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "teacher_review" */
export enum Teacher_Review_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "teacher_review" */
export type Teacher_Review_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  teacher_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Teacher_Review_Stddev_Fields = {
  __typename?: 'teacher_review_stddev_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Teacher_Review_Stddev_Pop_Fields = {
  __typename?: 'teacher_review_stddev_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Teacher_Review_Stddev_Samp_Fields = {
  __typename?: 'teacher_review_stddev_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Teacher_Review_Sum_Fields = {
  __typename?: 'teacher_review_sum_fields';
  value?: Maybe<Scalars['Int']>;
};

/** update columns of table "teacher_review" */
export enum Teacher_Review_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  TeacherId = 'teacher_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Teacher_Review_Var_Pop_Fields = {
  __typename?: 'teacher_review_var_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Teacher_Review_Var_Samp_Fields = {
  __typename?: 'teacher_review_var_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Teacher_Review_Variance_Fields = {
  __typename?: 'teacher_review_variance_fields';
  value?: Maybe<Scalars['Float']>;
};

/** select columns of table "teacher" */
export enum Teacher_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Bio = 'bio',
  /** column name */
  CoverPicture = 'cover_picture',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PartOfAccountId = 'part_of_account_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "teacher" */
export type Teacher_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  bio?: Maybe<Scalars['String']>;
  cover_picture?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  part_of_account_id?: Maybe<Scalars['uuid']>;
  person_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "teacher" */
export enum Teacher_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Bio = 'bio',
  /** column name */
  CoverPicture = 'cover_picture',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PartOfAccountId = 'part_of_account_id',
  /** column name */
  PersonId = 'person_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "temp_coming_soon_student" */
export type Temp_Coming_Soon_Student = {
  __typename?: 'temp_coming_soon_student';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "temp_coming_soon_student" */
export type Temp_Coming_Soon_Student_Aggregate = {
  __typename?: 'temp_coming_soon_student_aggregate';
  aggregate?: Maybe<Temp_Coming_Soon_Student_Aggregate_Fields>;
  nodes: Array<Temp_Coming_Soon_Student>;
};

/** aggregate fields of "temp_coming_soon_student" */
export type Temp_Coming_Soon_Student_Aggregate_Fields = {
  __typename?: 'temp_coming_soon_student_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Temp_Coming_Soon_Student_Max_Fields>;
  min?: Maybe<Temp_Coming_Soon_Student_Min_Fields>;
};


/** aggregate fields of "temp_coming_soon_student" */
export type Temp_Coming_Soon_Student_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Temp_Coming_Soon_Student_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "temp_coming_soon_student". All fields are combined with a logical 'AND'. */
export type Temp_Coming_Soon_Student_Bool_Exp = {
  _and?: Maybe<Array<Temp_Coming_Soon_Student_Bool_Exp>>;
  _not?: Maybe<Temp_Coming_Soon_Student_Bool_Exp>;
  _or?: Maybe<Array<Temp_Coming_Soon_Student_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "temp_coming_soon_student" */
export enum Temp_Coming_Soon_Student_Constraint {
  /** unique or primary key constraint */
  TempComingSoonStudentPkey = 'temp_coming_soon_student_pkey'
}

/** input type for inserting data into table "temp_coming_soon_student" */
export type Temp_Coming_Soon_Student_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Temp_Coming_Soon_Student_Max_Fields = {
  __typename?: 'temp_coming_soon_student_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Temp_Coming_Soon_Student_Min_Fields = {
  __typename?: 'temp_coming_soon_student_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "temp_coming_soon_student" */
export type Temp_Coming_Soon_Student_Mutation_Response = {
  __typename?: 'temp_coming_soon_student_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Temp_Coming_Soon_Student>;
};

/** on conflict condition type for table "temp_coming_soon_student" */
export type Temp_Coming_Soon_Student_On_Conflict = {
  constraint: Temp_Coming_Soon_Student_Constraint;
  update_columns?: Array<Temp_Coming_Soon_Student_Update_Column>;
  where?: Maybe<Temp_Coming_Soon_Student_Bool_Exp>;
};

/** Ordering options when selecting data from "temp_coming_soon_student". */
export type Temp_Coming_Soon_Student_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: temp_coming_soon_student */
export type Temp_Coming_Soon_Student_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "temp_coming_soon_student" */
export enum Temp_Coming_Soon_Student_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "temp_coming_soon_student" */
export type Temp_Coming_Soon_Student_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "temp_coming_soon_student" */
export enum Temp_Coming_Soon_Student_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}



/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type Timetz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timetz']>;
  _gt?: Maybe<Scalars['timetz']>;
  _gte?: Maybe<Scalars['timetz']>;
  _in?: Maybe<Array<Scalars['timetz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timetz']>;
  _lte?: Maybe<Scalars['timetz']>;
  _neq?: Maybe<Scalars['timetz']>;
  _nin?: Maybe<Array<Scalars['timetz']>>;
};

/** columns and relationships of "tip" */
export type Tip = {
  __typename?: 'tip';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  currentTips: Array<Tip_Current>;
  /** An aggregate relationship */
  currentTips_aggregate: Tip_Current_Aggregate;
  good_for: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  sources: Array<Tip_Source>;
  /** An aggregate relationship */
  sources_aggregate: Tip_Source_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "tip" */
export type TipCurrentTipsArgs = {
  distinct_on?: Maybe<Array<Tip_Current_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Current_Order_By>>;
  where?: Maybe<Tip_Current_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipCurrentTips_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Current_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Current_Order_By>>;
  where?: Maybe<Tip_Current_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipSourcesArgs = {
  distinct_on?: Maybe<Array<Tip_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Source_Order_By>>;
  where?: Maybe<Tip_Source_Bool_Exp>;
};


/** columns and relationships of "tip" */
export type TipSources_AggregateArgs = {
  distinct_on?: Maybe<Array<Tip_Source_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tip_Source_Order_By>>;
  where?: Maybe<Tip_Source_Bool_Exp>;
};

/** aggregated selection of "tip" */
export type Tip_Aggregate = {
  __typename?: 'tip_aggregate';
  aggregate?: Maybe<Tip_Aggregate_Fields>;
  nodes: Array<Tip>;
};

/** aggregate fields of "tip" */
export type Tip_Aggregate_Fields = {
  __typename?: 'tip_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tip_Max_Fields>;
  min?: Maybe<Tip_Min_Fields>;
};


/** aggregate fields of "tip" */
export type Tip_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tip_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tip". All fields are combined with a logical 'AND'. */
export type Tip_Bool_Exp = {
  _and?: Maybe<Array<Tip_Bool_Exp>>;
  _not?: Maybe<Tip_Bool_Exp>;
  _or?: Maybe<Array<Tip_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  currentTips?: Maybe<Tip_Current_Bool_Exp>;
  good_for?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  sources?: Maybe<Tip_Source_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tip" */
export enum Tip_Constraint {
  /** unique or primary key constraint */
  TipPkey = 'tip_pkey'
}

/** columns and relationships of "tip_current" */
export type Tip_Current = {
  __typename?: 'tip_current';
  good_for: Scalars['String'];
  /** An object relationship */
  tip: Tip;
  tip_id: Scalars['uuid'];
};

/** aggregated selection of "tip_current" */
export type Tip_Current_Aggregate = {
  __typename?: 'tip_current_aggregate';
  aggregate?: Maybe<Tip_Current_Aggregate_Fields>;
  nodes: Array<Tip_Current>;
};

/** aggregate fields of "tip_current" */
export type Tip_Current_Aggregate_Fields = {
  __typename?: 'tip_current_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tip_Current_Max_Fields>;
  min?: Maybe<Tip_Current_Min_Fields>;
};


/** aggregate fields of "tip_current" */
export type Tip_Current_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tip_Current_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tip_current" */
export type Tip_Current_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tip_Current_Max_Order_By>;
  min?: Maybe<Tip_Current_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tip_current" */
export type Tip_Current_Arr_Rel_Insert_Input = {
  data: Array<Tip_Current_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Tip_Current_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tip_current". All fields are combined with a logical 'AND'. */
export type Tip_Current_Bool_Exp = {
  _and?: Maybe<Array<Tip_Current_Bool_Exp>>;
  _not?: Maybe<Tip_Current_Bool_Exp>;
  _or?: Maybe<Array<Tip_Current_Bool_Exp>>;
  good_for?: Maybe<String_Comparison_Exp>;
  tip?: Maybe<Tip_Bool_Exp>;
  tip_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tip_current" */
export enum Tip_Current_Constraint {
  /** unique or primary key constraint */
  TipCurrentPkey = 'tip_current_pkey'
}

/** input type for inserting data into table "tip_current" */
export type Tip_Current_Insert_Input = {
  good_for?: Maybe<Scalars['String']>;
  tip?: Maybe<Tip_Obj_Rel_Insert_Input>;
  tip_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tip_Current_Max_Fields = {
  __typename?: 'tip_current_max_fields';
  good_for?: Maybe<Scalars['String']>;
  tip_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tip_current" */
export type Tip_Current_Max_Order_By = {
  good_for?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tip_Current_Min_Fields = {
  __typename?: 'tip_current_min_fields';
  good_for?: Maybe<Scalars['String']>;
  tip_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tip_current" */
export type Tip_Current_Min_Order_By = {
  good_for?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "tip_current" */
export type Tip_Current_Mutation_Response = {
  __typename?: 'tip_current_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tip_Current>;
};

/** on conflict condition type for table "tip_current" */
export type Tip_Current_On_Conflict = {
  constraint: Tip_Current_Constraint;
  update_columns?: Array<Tip_Current_Update_Column>;
  where?: Maybe<Tip_Current_Bool_Exp>;
};

/** Ordering options when selecting data from "tip_current". */
export type Tip_Current_Order_By = {
  good_for?: Maybe<Order_By>;
  tip?: Maybe<Tip_Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** primary key columns input for table: tip_current */
export type Tip_Current_Pk_Columns_Input = {
  good_for: Scalars['String'];
};

/** select columns of table "tip_current" */
export enum Tip_Current_Select_Column {
  /** column name */
  GoodFor = 'good_for',
  /** column name */
  TipId = 'tip_id'
}

/** input type for updating data in table "tip_current" */
export type Tip_Current_Set_Input = {
  good_for?: Maybe<Scalars['String']>;
  tip_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "tip_current" */
export enum Tip_Current_Update_Column {
  /** column name */
  GoodFor = 'good_for',
  /** column name */
  TipId = 'tip_id'
}

/** input type for inserting data into table "tip" */
export type Tip_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  currentTips?: Maybe<Tip_Current_Arr_Rel_Insert_Input>;
  good_for?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  sources?: Maybe<Tip_Source_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tip_Max_Fields = {
  __typename?: 'tip_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  good_for?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Tip_Min_Fields = {
  __typename?: 'tip_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  good_for?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "tip" */
export type Tip_Mutation_Response = {
  __typename?: 'tip_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tip>;
};

/** input type for inserting object relation for remote table "tip" */
export type Tip_Obj_Rel_Insert_Input = {
  data: Tip_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Tip_On_Conflict>;
};

/** on conflict condition type for table "tip" */
export type Tip_On_Conflict = {
  constraint: Tip_Constraint;
  update_columns?: Array<Tip_Update_Column>;
  where?: Maybe<Tip_Bool_Exp>;
};

/** Ordering options when selecting data from "tip". */
export type Tip_Order_By = {
  created_at?: Maybe<Order_By>;
  currentTips_aggregate?: Maybe<Tip_Current_Aggregate_Order_By>;
  good_for?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sources_aggregate?: Maybe<Tip_Source_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tip */
export type Tip_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tip" */
export enum Tip_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GoodFor = 'good_for',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tip" */
export type Tip_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  good_for?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "tip_source" */
export type Tip_Source = {
  __typename?: 'tip_source';
  id: Scalars['uuid'];
  order: Scalars['numeric'];
  source: Scalars['String'];
  tip_id: Scalars['uuid'];
};

/** aggregated selection of "tip_source" */
export type Tip_Source_Aggregate = {
  __typename?: 'tip_source_aggregate';
  aggregate?: Maybe<Tip_Source_Aggregate_Fields>;
  nodes: Array<Tip_Source>;
};

/** aggregate fields of "tip_source" */
export type Tip_Source_Aggregate_Fields = {
  __typename?: 'tip_source_aggregate_fields';
  avg?: Maybe<Tip_Source_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tip_Source_Max_Fields>;
  min?: Maybe<Tip_Source_Min_Fields>;
  stddev?: Maybe<Tip_Source_Stddev_Fields>;
  stddev_pop?: Maybe<Tip_Source_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tip_Source_Stddev_Samp_Fields>;
  sum?: Maybe<Tip_Source_Sum_Fields>;
  var_pop?: Maybe<Tip_Source_Var_Pop_Fields>;
  var_samp?: Maybe<Tip_Source_Var_Samp_Fields>;
  variance?: Maybe<Tip_Source_Variance_Fields>;
};


/** aggregate fields of "tip_source" */
export type Tip_Source_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tip_Source_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tip_source" */
export type Tip_Source_Aggregate_Order_By = {
  avg?: Maybe<Tip_Source_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Tip_Source_Max_Order_By>;
  min?: Maybe<Tip_Source_Min_Order_By>;
  stddev?: Maybe<Tip_Source_Stddev_Order_By>;
  stddev_pop?: Maybe<Tip_Source_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Tip_Source_Stddev_Samp_Order_By>;
  sum?: Maybe<Tip_Source_Sum_Order_By>;
  var_pop?: Maybe<Tip_Source_Var_Pop_Order_By>;
  var_samp?: Maybe<Tip_Source_Var_Samp_Order_By>;
  variance?: Maybe<Tip_Source_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tip_source" */
export type Tip_Source_Arr_Rel_Insert_Input = {
  data: Array<Tip_Source_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Tip_Source_On_Conflict>;
};

/** aggregate avg on columns */
export type Tip_Source_Avg_Fields = {
  __typename?: 'tip_source_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tip_source" */
export type Tip_Source_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tip_source". All fields are combined with a logical 'AND'. */
export type Tip_Source_Bool_Exp = {
  _and?: Maybe<Array<Tip_Source_Bool_Exp>>;
  _not?: Maybe<Tip_Source_Bool_Exp>;
  _or?: Maybe<Array<Tip_Source_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Numeric_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  tip_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tip_source" */
export enum Tip_Source_Constraint {
  /** unique or primary key constraint */
  TipSourcePkey = 'tip_source_pkey'
}

/** input type for incrementing numeric columns in table "tip_source" */
export type Tip_Source_Inc_Input = {
  order?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "tip_source" */
export type Tip_Source_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['numeric']>;
  source?: Maybe<Scalars['String']>;
  tip_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tip_Source_Max_Fields = {
  __typename?: 'tip_source_max_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['numeric']>;
  source?: Maybe<Scalars['String']>;
  tip_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tip_source" */
export type Tip_Source_Max_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tip_Source_Min_Fields = {
  __typename?: 'tip_source_min_fields';
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['numeric']>;
  source?: Maybe<Scalars['String']>;
  tip_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tip_source" */
export type Tip_Source_Min_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "tip_source" */
export type Tip_Source_Mutation_Response = {
  __typename?: 'tip_source_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tip_Source>;
};

/** on conflict condition type for table "tip_source" */
export type Tip_Source_On_Conflict = {
  constraint: Tip_Source_Constraint;
  update_columns?: Array<Tip_Source_Update_Column>;
  where?: Maybe<Tip_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "tip_source". */
export type Tip_Source_Order_By = {
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  tip_id?: Maybe<Order_By>;
};

/** primary key columns input for table: tip_source */
export type Tip_Source_Pk_Columns_Input = {
  source: Scalars['String'];
};

/** select columns of table "tip_source" */
export enum Tip_Source_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Source = 'source',
  /** column name */
  TipId = 'tip_id'
}

/** input type for updating data in table "tip_source" */
export type Tip_Source_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['numeric']>;
  source?: Maybe<Scalars['String']>;
  tip_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Tip_Source_Stddev_Fields = {
  __typename?: 'tip_source_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tip_source" */
export type Tip_Source_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tip_Source_Stddev_Pop_Fields = {
  __typename?: 'tip_source_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tip_source" */
export type Tip_Source_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tip_Source_Stddev_Samp_Fields = {
  __typename?: 'tip_source_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tip_source" */
export type Tip_Source_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Tip_Source_Sum_Fields = {
  __typename?: 'tip_source_sum_fields';
  order?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "tip_source" */
export type Tip_Source_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "tip_source" */
export enum Tip_Source_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Source = 'source',
  /** column name */
  TipId = 'tip_id'
}

/** aggregate var_pop on columns */
export type Tip_Source_Var_Pop_Fields = {
  __typename?: 'tip_source_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tip_source" */
export type Tip_Source_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tip_Source_Var_Samp_Fields = {
  __typename?: 'tip_source_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tip_source" */
export type Tip_Source_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Tip_Source_Variance_Fields = {
  __typename?: 'tip_source_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tip_source" */
export type Tip_Source_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "tip" */
export enum Tip_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GoodFor = 'good_for',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "yoga_type" */
export type Yoga_Type = {
  __typename?: 'yoga_type';
  value: Scalars['String'];
};

/** aggregated selection of "yoga_type" */
export type Yoga_Type_Aggregate = {
  __typename?: 'yoga_type_aggregate';
  aggregate?: Maybe<Yoga_Type_Aggregate_Fields>;
  nodes: Array<Yoga_Type>;
};

/** aggregate fields of "yoga_type" */
export type Yoga_Type_Aggregate_Fields = {
  __typename?: 'yoga_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Yoga_Type_Max_Fields>;
  min?: Maybe<Yoga_Type_Min_Fields>;
};


/** aggregate fields of "yoga_type" */
export type Yoga_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Yoga_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "yoga_type". All fields are combined with a logical 'AND'. */
export type Yoga_Type_Bool_Exp = {
  _and?: Maybe<Array<Yoga_Type_Bool_Exp>>;
  _not?: Maybe<Yoga_Type_Bool_Exp>;
  _or?: Maybe<Array<Yoga_Type_Bool_Exp>>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "yoga_type" */
export enum Yoga_Type_Constraint {
  /** unique or primary key constraint */
  YogaTypePkey = 'yoga_type_pkey'
}

export enum Yoga_Type_Enum {
  Aerial = 'aerial',
  Ashtanga = 'ashtanga',
  Bikram = 'bikram',
  Hatha = 'hatha',
  Iyengar = 'iyengar',
  Kundalini = 'kundalini',
  Nidra = 'nidra',
  Other = 'other',
  Postnatal = 'postnatal',
  Power = 'power',
  Prenatal = 'prenatal',
  Vinyasa = 'vinyasa',
  Yin = 'yin'
}

/** Boolean expression to compare columns of type "yoga_type_enum". All fields are combined with logical 'AND'. */
export type Yoga_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Yoga_Type_Enum>;
  _in?: Maybe<Array<Yoga_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Yoga_Type_Enum>;
  _nin?: Maybe<Array<Yoga_Type_Enum>>;
};

/** input type for inserting data into table "yoga_type" */
export type Yoga_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Yoga_Type_Max_Fields = {
  __typename?: 'yoga_type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Yoga_Type_Min_Fields = {
  __typename?: 'yoga_type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "yoga_type" */
export type Yoga_Type_Mutation_Response = {
  __typename?: 'yoga_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Yoga_Type>;
};

/** on conflict condition type for table "yoga_type" */
export type Yoga_Type_On_Conflict = {
  constraint: Yoga_Type_Constraint;
  update_columns?: Array<Yoga_Type_Update_Column>;
  where?: Maybe<Yoga_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "yoga_type". */
export type Yoga_Type_Order_By = {
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: yoga_type */
export type Yoga_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "yoga_type" */
export enum Yoga_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "yoga_type" */
export type Yoga_Type_Set_Input = {
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "yoga_type" */
export enum Yoga_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type DeleteTipMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteTipMutation = (
  { __typename?: 'mutation_root' }
  & { delete_tip_by_pk?: Maybe<(
    { __typename?: 'tip' }
    & Pick<Tip, 'id'>
  )> }
);

export type InsertTipMutationVariables = Exact<{
  object: Tip_Insert_Input;
}>;


export type InsertTipMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tip_one?: Maybe<(
    { __typename?: 'tip' }
    & Pick<Tip, 'id'>
  )> }
);

export type UpdateBlogMutationVariables = Exact<{
  id: Scalars['uuid'];
  set: Blog_Set_Input;
}>;


export type UpdateBlogMutation = (
  { __typename?: 'mutation_root' }
  & { update_blog_by_pk?: Maybe<(
    { __typename?: 'blog' }
    & Pick<Blog, 'id'>
  )> }
);

export type UploadPictureMutationVariables = Exact<{
  format: Scalars['String'];
  base64String: Scalars['String'];
}>;


export type UploadPictureMutation = (
  { __typename?: 'mutation_root' }
  & { uploadPicture?: Maybe<(
    { __typename?: 'UploadPictureOutput' }
    & Pick<UploadPictureOutput, 'url'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
}>;


export type LoginMutation = (
  { __typename?: 'mutation_root' }
  & { login?: Maybe<(
    { __typename?: 'LoginOutput' }
    & Pick<LoginOutput, 'expiresIn'>
  )> }
);

export type LoginVerifyMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type LoginVerifyMutation = (
  { __typename?: 'mutation_root' }
  & { loginVerify?: Maybe<(
    { __typename?: 'LoginVerifyOutput' }
    & Pick<LoginVerifyOutput, 'token' | 'expiresAt' | 'account'>
  )> }
);

export type BlogsTotalQueryVariables = Exact<{ [key: string]: never; }>;


export type BlogsTotalQuery = (
  { __typename?: 'query_root' }
  & { blog_aggregate: (
    { __typename?: 'blog_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'blog_aggregate_fields' }
      & Pick<Blog_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type TipsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: Maybe<Tip_Bool_Exp>;
}>;


export type TipsQuery = (
  { __typename?: 'query_root' }
  & { tip: Array<(
    { __typename?: 'tip' }
    & Pick<Tip, 'id' | 'good_for' | 'name'>
    & { sources: Array<(
      { __typename?: 'tip_source' }
      & Pick<Tip_Source, 'id' | 'order' | 'source'>
    )>, currentTips: Array<(
      { __typename?: 'tip_current' }
      & Pick<Tip_Current, 'tip_id'>
    )> }
  )> }
);

export type UpcomingClassesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: Maybe<Class_Event_Bool_Exp>;
}>;


export type UpcomingClassesQuery = (
  { __typename?: 'query_root' }
  & { class_event: Array<(
    { __typename?: 'class_event' }
    & Pick<Class_Event, 'id' | 'end_timestamp' | 'start_timestamp' | 'meeting_link'>
    & { class: (
      { __typename?: 'class' }
      & Pick<Class, 'id' | 'name' | 'yogaType' | 'level' | 'online' | 'setting' | 'costType' | 'price' | 'picture' | 'recurrence'>
      & { location?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'name' | 'address' | 'addressSecondary' | 'city' | 'state' | 'zipCode' | 'country'>
      )>, owner: (
        { __typename?: 'account' }
        & Pick<Account, 'id' | 'type' | 'active' | 'verified' | 'username'>
        & { person?: Maybe<(
          { __typename?: 'profile_person' }
          & Pick<Profile_Person, 'firstName' | 'lastName'>
        )>, entity?: Maybe<(
          { __typename?: 'profile_entity' }
          & Pick<Profile_Entity, 'name'>
        )> }
      ) }
    ), class_books_aggregate: (
      { __typename?: 'class_book_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'class_book_aggregate_fields' }
        & Pick<Class_Book_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type UsersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  active?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Account_Role_Enum>;
  source?: Maybe<Scalars['String']>;
}>;


export type UsersQuery = (
  { __typename?: 'query_root' }
  & { account: Array<(
    { __typename?: 'account' }
    & Pick<Account, 'id' | 'created_at' | 'active' | 'stripe_account_id' | 'stripe_customer_id' | 'terms_accepted' | 'type' | 'username' | 'verified' | 'source' | 'extra'>
    & { person?: Maybe<(
      { __typename?: 'profile_person' }
      & Pick<Profile_Person, 'firstName' | 'lastName' | 'phoneNumber' | 'birthday' | 'email' | 'id' | 'picture'>
    )>, entity?: Maybe<(
      { __typename?: 'profile_entity' }
      & Pick<Profile_Entity, 'email' | 'id' | 'name' | 'phoneNumber' | 'picture'>
    )>, account_social_media: Array<(
      { __typename?: 'account_social_media' }
      & Pick<Account_Social_Media, 'type' | 'url'>
    )> }
  )> }
);

export type BlogByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type BlogByPkQuery = (
  { __typename?: 'query_root' }
  & { blog_by_pk?: Maybe<(
    { __typename?: 'blog' }
    & Pick<Blog, 'id' | 'created_at' | 'url' | 'picture' | 'title' | 'authorName' | 'authorPicture' | 'published_at' | 'minsToRead' | 'type' | 'published' | 'source' | 'external_id' | 'not_published_reason' | 'verified'>
  )> }
);

export type BlogPostsQueryVariables = Exact<{
  where?: Maybe<Blog_Bool_Exp>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type BlogPostsQuery = (
  { __typename?: 'query_root' }
  & { blog: Array<(
    { __typename?: 'blog' }
    & Pick<Blog, 'id' | 'created_at' | 'url' | 'picture' | 'title' | 'authorName' | 'authorPicture' | 'published_at' | 'minsToRead' | 'type' | 'published' | 'source' | 'external_id' | 'not_published_reason' | 'verified'>
  )> }
);


export const DeleteTipDocument = gql`
    mutation DeleteTip($id: uuid!) {
  delete_tip_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteTipMutation() {
  return Urql.useMutation<DeleteTipMutation, DeleteTipMutationVariables>(DeleteTipDocument);
};
export const InsertTipDocument = gql`
    mutation InsertTip($object: tip_insert_input!) {
  insert_tip_one(object: $object) {
    id
  }
}
    `;

export function useInsertTipMutation() {
  return Urql.useMutation<InsertTipMutation, InsertTipMutationVariables>(InsertTipDocument);
};
export const UpdateBlogDocument = gql`
    mutation UpdateBlog($id: uuid!, $set: blog_set_input!) {
  update_blog_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;

export function useUpdateBlogMutation() {
  return Urql.useMutation<UpdateBlogMutation, UpdateBlogMutationVariables>(UpdateBlogDocument);
};
export const UploadPictureDocument = gql`
    mutation UploadPicture($format: String!, $base64String: String!) {
  uploadPicture(format: $format, base64String: $base64String) {
    url
  }
}
    `;

export function useUploadPictureMutation() {
  return Urql.useMutation<UploadPictureMutation, UploadPictureMutationVariables>(UploadPictureDocument);
};
export const LoginDocument = gql`
    mutation Login($email: String, $phoneNumber: String) {
  login(email: $email, phoneNumber: $phoneNumber) {
    expiresIn
  }
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const LoginVerifyDocument = gql`
    mutation LoginVerify($code: String!) {
  loginVerify(code: $code) {
    token
    expiresAt
    account
  }
}
    `;

export function useLoginVerifyMutation() {
  return Urql.useMutation<LoginVerifyMutation, LoginVerifyMutationVariables>(LoginVerifyDocument);
};
export const BlogsTotalDocument = gql`
    query BlogsTotal {
  blog_aggregate {
    aggregate {
      count
    }
  }
}
    `;

export function useBlogsTotalQuery(options: Omit<Urql.UseQueryArgs<BlogsTotalQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BlogsTotalQuery>({ query: BlogsTotalDocument, ...options });
};
export const TipsDocument = gql`
    query Tips($limit: Int!, $offset: Int!, $where: tip_bool_exp) {
  tip(limit: $limit, offset: $offset, where: $where) {
    id
    good_for
    name
    sources {
      id
      order
      source
    }
    currentTips {
      tip_id
    }
  }
}
    `;

export function useTipsQuery(options: Omit<Urql.UseQueryArgs<TipsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TipsQuery>({ query: TipsDocument, ...options });
};
export const UpcomingClassesDocument = gql`
    query UpcomingClasses($limit: Int!, $offset: Int!, $where: class_event_bool_exp) {
  class_event(
    limit: $limit
    offset: $offset
    order_by: {start_timestamp: asc}
    where: $where
  ) {
    id
    end_timestamp
    start_timestamp
    meeting_link
    class {
      id
      name
      yogaType
      level
      online
      setting
      costType
      price
      picture
      recurrence
      location {
        name
        address
        addressSecondary
        city
        state
        zipCode
        country
      }
      owner {
        id
        type
        active
        verified
        username
        person {
          firstName
          lastName
        }
        entity {
          name
        }
      }
    }
    class_books_aggregate {
      aggregate {
        count(columns: id)
      }
    }
  }
}
    `;

export function useUpcomingClassesQuery(options: Omit<Urql.UseQueryArgs<UpcomingClassesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UpcomingClassesQuery>({ query: UpcomingClassesDocument, ...options });
};
export const UsersDocument = gql`
    query Users($limit: Int!, $offset: Int!, $active: Boolean, $verified: Boolean, $type: account_role_enum, $source: String) {
  account(
    limit: $limit
    offset: $offset
    where: {active: {_eq: $active}, verified: {_eq: $verified}, type: {_eq: $type}, source: {_eq: $source}}
  ) {
    id
    created_at
    active
    stripe_account_id
    stripe_customer_id
    terms_accepted
    type
    username
    verified
    source
    person {
      firstName
      lastName
      phoneNumber
      birthday
      email
      id
      picture
    }
    entity {
      email
      id
      name
      phoneNumber
      picture
    }
    extra
    account_social_media {
      type
      url
    }
  }
}
    `;

export function useUsersQuery(options: Omit<Urql.UseQueryArgs<UsersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UsersQuery>({ query: UsersDocument, ...options });
};
export const BlogByPkDocument = gql`
    query BlogByPK($id: uuid!) {
  blog_by_pk(id: $id) {
    id
    created_at
    url
    picture
    title
    authorName
    authorPicture
    published_at
    minsToRead
    type
    published
    source
    external_id
    not_published_reason
    verified
  }
}
    `;

export function useBlogByPkQuery(options: Omit<Urql.UseQueryArgs<BlogByPkQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BlogByPkQuery>({ query: BlogByPkDocument, ...options });
};
export const BlogPostsDocument = gql`
    query BlogPosts($where: blog_bool_exp, $limit: Int!, $offset: Int!) {
  blog(
    where: $where
    limit: $limit
    offset: $offset
    order_by: {published_at: desc}
  ) {
    id
    created_at
    url
    picture
    title
    authorName
    authorPicture
    published_at
    minsToRead
    type
    published
    source
    external_id
    not_published_reason
    verified
  }
}
    `;

export function useBlogPostsQuery(options: Omit<Urql.UseQueryArgs<BlogPostsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BlogPostsQuery>({ query: BlogPostsDocument, ...options });
};