import cc from "classcat";
import { forwardRef } from "react";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  error?: string;
};

const FormField = forwardRef(
  ({ className, error, label, ...props }: Props, ref: any) => {
    return (
      <>
        {label && <label className="block pb-1">{label}</label>}
        <input
          {...props}
          ref={ref}
          className={cc(["w-full border border-gray-400 px-3 py-2", className])}
        />
        {error && <div className="text-red-500 pt-1">{error}</div>}
      </>
    );
  }
);

export default FormField;
