import cc from "classcat";
import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import { DeleteTipDocument, TipsQuery, useTipsQuery } from "generated/graphql";
import { useMemo, useState } from "react";
import { useTable } from "react-table";
import Container from "components/Container";
import { useClient } from "urql";
import AutoCopyText from "components/AutoCopyText";
import Button from "components/Button";
import { useHistory } from "react-router";
import { sortBy } from "lodash/fp";

const LIMIT = 10;

const Tips = () => {
  const [limit] = useState(LIMIT);
  const [page, setPage] = useState(0);
  const [state, refetchTip] = useTipsQuery({
    requestPolicy: "network-only",
    variables: {
      limit,
      offset: limit * page,
      where: {},
    },
  });
  const history = useHistory();

  return (
    <>
      <div className="flex justify-between items-center pb-4">
        <div>Filters here</div>
        <div>
          <Button
            onClick={() => {
              history.push("/tips/add");
            }}
          >
            Add Tip
          </Button>
        </div>
      </div>

      <Container className="py-12 flex justify-between items-center">
        {page > 0 ? (
          <button
            className="border border-primary p-2"
            onClick={() => {
              setPage((current) => current - 1);
            }}
          >
            Prev
          </button>
        ) : (
          <div />
        )}
        <div>Page {page + 1} </div>
        {state.data?.tip.length === limit ? (
          <button
            className="border border-primary p-2"
            onClick={() => {
              setPage((current) => current + 1);
            }}
          >
            Next
          </button>
        ) : (
          <div />
        )}
      </Container>

      {/* <ListTips state={state} /> */}

      {state.fetching ? (
        <Loading />
      ) : state.error ? (
        <ErrorMessage>{state.error.message}</ErrorMessage>
      ) : !state.data || state.data.tip.length === 0 ? (
        <div>There are no tips</div>
      ) : (
        <>
          <div>Viewing: {state.data.tip.length}</div>
          <Table data={state.data.tip} refetch={refetchTip} />
        </>
      )}
    </>
  );
};

export default Tips;

const Table = ({ data, refetch }: { data: TipsQuery["tip"]; refetch: any }) => {
  const client = useClient();
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ value }: any) => <AutoCopyText>{value}</AutoCopyText>,
      },
      {
        Header: "Good For",
        accessor: "good_for",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Sources",
        accessor: "sources",
        Cell: ({ value }: any) => (
          <ul>
            {sortBy("order", value).map(({ source }) => (
              <li key={source} className="inline-block">
                <img className="block" src={source} alt={source} width="150" />
              </li>
            ))}
          </ul>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }: any) => {
          return (
            <button
              className="bg-red-500 p-2 text-white"
              onClick={async () => {
                if (
                  window.confirm(
                    `Are you sure you want to delete "${row.original.name}"`
                  )
                ) {
                  const result = await client
                    .mutation(DeleteTipDocument, {
                      id: row.original.id,
                    })
                    .toPromise();

                  console.log("--result", result);

                  if (result.error) {
                    alert(result.error);
                  } else if (!result.data) {
                    alert("Data is empty, something went wrong");
                  } else {
                    refetch();
                  }
                }
              }}
            >
              Delete
            </button>
          );
        },
      },
    ],
    []
  );
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } =
    useTable({
      columns: columns as any,
      data,
    });

  return (
    <table
      className="w-full table-auto border border-gray-500"
      {...getTableProps()}
    >
      <thead className="bg-primary text-white">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className="p-2 border border-white"
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="bg-white" {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                return (
                  <td
                    className={cc([
                      "p-2 border border-gray-500",
                      {
                        "bg-red-200": row.original.currentTips.length > 0,
                      },
                    ])}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
