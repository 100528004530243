import Button from "components/Button";
import Container from "components/Container";
import ErrorMessage from "components/ErrorMessage";
import FormField from "components/FormField";
import FormGroup from "components/FormGroup";
import Loading from "components/Loading";
import {
  BlogByPkQuery,
  useBlogByPkQuery,
  useUpdateBlogMutation,
} from "generated/graphql";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const BlogEdit = () => {
  const { id } = useParams<{ id: string }>();
  const [{ fetching, error, data }] = useBlogByPkQuery({
    variables: {
      id,
    },
  });

  if (fetching) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  if (!data?.blog_by_pk) {
    return <div>Blog not found</div>;
  }

  return (
    <Container>
      <Link to="/blog">Back</Link>
      <h1 className="font-title text-xl my-10">ID: {id}</h1>

      <Form id={id} data={data.blog_by_pk} />
    </Container>
  );
};

const Form = ({
  id,
  data,
}: {
  id: string;
  data: BlogByPkQuery["blog_by_pk"];
}) => {
  const [updateState, updateBlog] = useUpdateBlogMutation();
  const { register, watch, handleSubmit } = useForm({
    defaultValues: {
      title: data?.title,
      url: data?.url,
      picture: data?.picture,
      authorName: data?.authorName,
      authorPicture: data?.authorPicture,
      minsToRead: data?.minsToRead,
      not_published_reason: data?.not_published_reason,
      published: data?.published,
    },
  });
  const watchPublished = watch("published");
  const watchPicture = watch("picture");
  const watchAuthorPicture = watch("authorPicture");

  return (
    <>
      {updateState.data && (
        <div className="mb-4 p-4 bg-primary text-white">
          Blog post has been updated!
        </div>
      )}

      <form
        onSubmit={handleSubmit(async (values) => {
          console.log(values);
          const result = await updateBlog({
            id,
            set: {
              ...values,
              verified: true,
            },
          });

          console.log("--result", result);
        })}
      >
        <FormGroup>
          <FormField label="Title" {...register("title")} required />

          <FormField label="URL" {...register("url")} required />
          <a
            href={data?.url}
            target="_blank"
            rel="noreferrer"
            className="block text-primery underline -mt-4"
          >
            Open
          </a>

          {watchPicture && (
            <div className="relative inline-block">
              <img src={watchPicture} style={{ width: 300 }} alt="Picture" />
              <div
                className="absolute left-0 top-1/2 w-full"
                style={{
                  height: 125,
                  border: "dashed 2px white",
                  transform: "translateY(-50%)",
                }}
              />
            </div>
          )}
          <FormField label="Picture" {...register("picture")} />

          <FormField label="Author Name" {...register("authorName")} required />

          {watchAuthorPicture && (
            <img
              src={watchAuthorPicture}
              style={{ width: 70 }}
              alt="Author Picture"
            />
          )}
          <FormField label="Author Picture" {...register("authorPicture")} />

          <FormField label="Mins to Read" {...register("minsToRead")} />

          <div>
            <input id="published" type="checkbox" {...register("published")} />
            <label htmlFor="published" className="pl-2 select-none">
              Published
            </label>
          </div>
          {!watchPublished && (
            <FormField
              label="Reason we will not publish it"
              {...register("not_published_reason")}
            />
          )}

          {updateState.error && (
            <ErrorMessage>{updateState.error.message}</ErrorMessage>
          )}

          <Button>Save</Button>
        </FormGroup>
      </form>
    </>
  );
};

export default BlogEdit;
