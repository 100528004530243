import { createContext, useContext, useState } from "react";

type Account = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

type Auth = {
  token: string;
  expiresAt: number;
  account: Account;
};

type Store = {
  auth: Auth;
};

const Context = createContext<[Store | null, (newStore: Store | null) => void]>(
  [null, () => undefined]
);

const getStore = (): Store | null => {
  try {
    const store = localStorage.getItem("global-store");

    return store ? JSON.parse(store) : null;
  } catch (error) {
    console.error("Invalid store", error);
    return null;
  }
};

export const GlobalStoreProvider = ({ children }: any) => {
  const [store, setStoreState] = useState<Store | null>(getStore());

  const setStore = (newStore: Store | null) => {
    localStorage.setItem("global-store", JSON.stringify(newStore));
    setStoreState(newStore);
  };

  return (
    <Context.Provider value={[store, setStore]}>{children}</Context.Provider>
  );
};

export const useGlobalStore = () => {
  return useContext(Context);
};
