import { createClient, Provider, dedupExchange, fetchExchange } from "urql";
import { useGlobalStore } from "stores/global.store";
import { cacheExchange } from "@urql/exchange-graphcache";

const GraphqlClientProvider = ({ children }: any) => {
  const [store] = useGlobalStore();

  const cache = cacheExchange({
    updates: {
      Mutation: {},
    },
  });

  const client = createClient({
    url: process.env.REACT_APP_GRAPHQL_URL as string,
    fetchOptions: () => {
      if (store && store.auth) {
        return {
          headers: {
            authorization: `Bearer ${store.auth.token}`,
          },
        };
      }

      return {};
    },
    exchanges: [dedupExchange, cache, fetchExchange],
  });

  // TODO: Handle errors + jwt expiration

  return <Provider value={client}>{children}</Provider>;
};

export default GraphqlClientProvider;
