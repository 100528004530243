import { createContext, useContext } from "react";
import { useGlobalStore } from "../stores/global.store";

const Context = createContext<{
  isAuthenticated: () => boolean;
}>({
  isAuthenticated: () => false,
});

export const AuthProvider = ({ children }: any) => {
  const [store] = useGlobalStore();

  const isAuthenticated = () => !!store && !!store.auth;

  return (
    <Context.Provider
      value={{
        isAuthenticated,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAuth = () => {
  return useContext(Context);
};
