export type PhotoBase64 = string;
export type MimeType = string; // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers

type ImageFile = {
  base64String: PhotoBase64;
  mime: string;
};

const readFile = (file: File): Promise<ImageFile> =>
  new Promise((resolve) => {
    const reader = new FileReader();

    const handler = () => {
      const b64 = reader.result as string;

      resolve({
        base64String: b64,
        mime: file.type,
      });

      reader.removeEventListener("load", handler);
    };

    reader.addEventListener("load", handler);

    reader.readAsDataURL(file);
  });

const FilePicker = (
  mimeType: MimeType,
  multiple?: boolean
): Promise<ImageFile[]> =>
  new Promise((resolve) => {
    let input = document.getElementById(
      "_influexer-file-picker-input"
    ) as HTMLInputElement;

    const cleanup = () => {
      input.parentNode?.removeChild(input);
    };

    if (!input) {
      input = document.createElement("input") as HTMLInputElement;
      input.id = "_influexer-file-picker-input";
      input.type = "file";
      input.accept = mimeType;
      input.style.position = "fixed";
      input.style.top = "-1000%";
      input.multiple = !!multiple;

      document.body.appendChild(input);

      input.addEventListener("change", async (_e: any) => {
        const files: File[] = [];

        if (input.files) {
          for (let i = 0; i < input.files.length; i++) {
            files.push(input.files[i]);
          }
        }

        resolve(await Promise.all(files.map(readFile)));

        cleanup();
      });
    }

    input.click();
  });

export default FilePicker;
