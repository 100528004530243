import { useForm } from "react-hook-form";
import Button from "components/Button";
import FormField from "components/FormField";
import FormGroup from "components/FormGroup";
import {
  Account_Role_Enum,
  useLoginMutation,
  useLoginVerifyMutation,
} from "generated/graphql";
import ErrorMessage from "components/ErrorMessage";
import { useState } from "react";
import { useGlobalStore } from "stores/global.store";

const Login = () => {
  const [screen, setScreen] = useState<"login" | "verify">("login");

  if (screen === "verify") {
    return (
      <WhiteBox>
        <VerifyForm
          onCancel={() => {
            setScreen("login");
          }}
        />
      </WhiteBox>
    );
  }

  return (
    <WhiteBox>
      <EmailOrPhoneNumberForm
        onSubmit={() => {
          setScreen("verify");
        }}
      />
    </WhiteBox>
  );
};

const WhiteBox = ({ children }: any) => {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="bg-white p-10">{children}</div>
    </div>
  );
};

const EmailOrPhoneNumberForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const [loginState, login] = useLoginMutation();
  const { handleSubmit, register, formState } = useForm({
    defaultValues: {
      emailOrPhoneNumber: "",
    },
  });
  const { errors } = formState;

  console.log("--errors", errors);

  return (
    <form
      onSubmit={handleSubmit(async ({ emailOrPhoneNumber, ...rest }) => {
        const isEmail = emailOrPhoneNumber.includes("@");
        const parsedEmailOrPhoneNumber = isEmail
          ? emailOrPhoneNumber.trim().toLowerCase()
          : (emailOrPhoneNumber || "").replace(/[^0-9]+/g, "");

        const response = await login(
          isEmail
            ? {
                email: parsedEmailOrPhoneNumber,
              }
            : {
                phoneNumber: parsedEmailOrPhoneNumber,
              }
        );

        if (response.data?.login?.expiresIn) {
          onSubmit();
        }
      })}
    >
      <FormGroup>
        <FormField
          className="w-72"
          type="text"
          placeholder="Email or Phone Number"
          {...register("emailOrPhoneNumber")}
          required
          error={errors.emailOrPhoneNumber?.message}
        />

        {loginState.error && (
          <ErrorMessage>{loginState.error.message}</ErrorMessage>
        )}

        <Button $fluid loading={loginState.fetching}>
          Login
        </Button>
      </FormGroup>
    </form>
  );
};

const VerifyForm = ({ onCancel }: { onCancel: () => void }) => {
  const [, setGlobalStore] = useGlobalStore();
  const [verifyState, verify] = useLoginVerifyMutation();
  const { handleSubmit, register, formState, setError } = useForm({
    defaultValues: {
      code: "",
    },
  });
  const { errors } = formState;

  return (
    <form
      onSubmit={handleSubmit(async ({ code }) => {
        const response = await verify({ code });

        if (response.data?.loginVerify) {
          const { token, expiresAt, account } = response.data.loginVerify;

          if (account.type !== Account_Role_Enum.Controller) {
            setError("code", {
              message: "Invalid Account",
            });
          } else {
            setGlobalStore({
              auth: {
                token,
                expiresAt,
                account,
              },
            });
          }
        }
      })}
    >
      <FormGroup>
        <FormField
          className="w-72"
          type="tel"
          placeholder="Verifycation Code"
          {...register("code")}
          required
          error={errors.code?.message}
          max={6}
        />

        {verifyState.error && (
          <ErrorMessage>{verifyState.error.message}</ErrorMessage>
        )}

        <Button $fluid loading={verifyState.fetching}>
          Login
        </Button>
        <Button
          $fluid
          $type="secondary"
          type="button"
          onClick={() => {
            onCancel();
          }}
          disabled={verifyState.fetching}
        >
          Cancel
        </Button>
      </FormGroup>
    </form>
  );
};

export default Login;
