import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import moment from "moment";
import {
  UpcomingClassesQuery,
  useUpcomingClassesQuery,
} from "generated/graphql";
import { useMemo, useState, useLayoutEffect } from "react";
import { useTable } from "react-table";
import Container from "components/Container";
import { UseQueryState } from "urql";
import AutoCopyText from "components/AutoCopyText";

moment.locale("en", {
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    lastWeek: "[last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "dddd L [at] LT",
  },
});

const LIMIT = 10;

const UpcomingClasses = () => {
  const [timestamp, setTimestamp] = useState<string | null>(null);

  useLayoutEffect(() => {
    setTimestamp(moment.utc().toISOString());
  }, []);

  if (!timestamp) {
    return null;
  }

  return <UpcomingClassesWithTimestamp timestamp={timestamp} />;
};

export default UpcomingClasses;

const UpcomingClassesWithTimestamp = ({ timestamp }: { timestamp: string }) => {
  const [limit] = useState(LIMIT);
  const [page, setPage] = useState(0);
  const [active, setActive] = useState<null | boolean>(true);
  const [verified, setVerified] = useState<null | boolean>(true);
  const [state] = useUpcomingClassesQuery({
    // requestPolicy: "network-only",
    variables: {
      limit,
      offset: limit * page,
      where: {
        start_timestamp: { _gte: timestamp },
        class: {
          owner: {
            active:
              active != null
                ? {
                    _eq: active,
                  }
                : undefined,
            verified:
              verified != null
                ? {
                    _eq: verified,
                  }
                : undefined,
          },
        },
      },
    },
  });

  console.log("--state", state);

  return (
    <>
      <div className="pb-4">
        {/* Active */}
        <div className="inline-block">
          <label className="block">Active</label>
          <select
            onChange={(e) => {
              const { value } = e.target;

              if (value === "1") {
                setActive(true);
              } else if (value === "0") {
                setActive(false);
              } else {
                setActive(null);
              }
            }}
            defaultValue={active === true ? "1" : active === false ? "0" : "-1"}
          >
            <option value="-1">All</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        {/* Verified */}
        <div className="inline-block pl-8">
          <label className="block">Verified</label>
          <select
            onChange={(e) => {
              const { value } = e.target;

              if (value === "1") {
                setVerified(true);
              } else if (value === "0") {
                setVerified(false);
              } else {
                setVerified(null);
              }
            }}
            defaultValue={
              verified === true ? "1" : verified === false ? "0" : "-1"
            }
          >
            <option value="-1">All</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>
      </div>
      <Container className="py-12 flex justify-between items-center">
        {page > 0 ? (
          <button
            className="border border-primary p-2"
            onClick={() => {
              setPage((current) => current - 1);
            }}
          >
            Prev
          </button>
        ) : (
          <div />
        )}
        <div>Page {page + 1} </div>
        {state.data?.class_event.length === limit ? (
          <button
            className="border border-primary p-2"
            onClick={() => {
              setPage((current) => current + 1);
            }}
          >
            Next
          </button>
        ) : (
          <div />
        )}
      </Container>

      <ListUsers state={state} />
    </>
  );
};

const ListUsers = ({
  state,
}: {
  state: UseQueryState<UpcomingClassesQuery, object>;
}) => {
  const { fetching, error, data } = state;

  if (fetching) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  if (!data || data.class_event.length === 0) {
    return <div>There are no upcoming classes</div>;
  }

  return (
    <>
      <div>Viewing: {data.class_event.length}</div>
      <Table data={data.class_event} />
    </>
  );
};

const Table = ({ data }: { data: UpcomingClassesQuery["class_event"] }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Schedule",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            Cell: ({ value }: any) => <AutoCopyText>{value}</AutoCopyText>,
          },
          {
            Header: "Starts",
            accessor: "start_timestamp",
            Cell: ({ value }: any) => moment(value).calendar(),
          },
          {
            Header: "Ends",
            accessor: "end_timestamp",
            Cell: ({ value }: any) => moment(value).calendar(),
          },
          {
            Header: "Bookings",
            accessor: "class_books_aggregate.aggregate.count",
          },
        ],
      },
      {
        Header: "Class Details",
        columns: [
          {
            Header: "Name",
            accessor: "class.name",
          },
          {
            Header: "Type",
            accessor: "class.yogaType",
          },
          {
            Header: "Level",
            accessor: "class.level",
          },
          {
            Header: "Setting",
            accessor: "class.setting",
            Cell: ({ value }: any) => value || "N/A",
          },
          {
            Header: "Cost Type",
            accessor: "class.costType",
          },
          {
            Header: "Price",
            accessor: "class.price",
            Cell: ({ value }: any) => `$${value}`,
          },
          {
            Header: "Recurrence",
            accessor: "class.recurrence",
          },
          {
            Header: "Location",
            Cell: ({ row }: any) =>
              row.original.class.online
                ? "VIRTUAL"
                : [
                    `"${row.original.class.location.name}"`,
                    row.original.class.location.address,
                    row.original.class.location.addressSecondary,
                    row.original.class.location.city,
                    row.original.class.location.state,
                    row.original.class.location.zipCode,
                    row.original.class.location.country,
                  ]
                    .filter(Boolean)
                    .join(", "),
          },
        ],
      },
      {
        Header: "Owner",
        columns: [
          {
            Header: "ID",
            accessor: "class.owner.id",
          },
          {
            Header: "Type",
            accessor: "class.owner.type",
          },
          {
            Header: "Active",
            accessor: "class.owner.active",
            Cell: ({ value }: any) => (value ? "✅" : "❌"),
          },
          {
            Header: "Verified",
            accessor: "class.owner.verified",
            Cell: ({ value }: any) => (value ? "✅" : "❌"),
          },
          {
            Header: "Username",
            accessor: "class.owner.username",
          },
          {
            Header: "Name",
            Cell: ({ row }: any) =>
              row.original.class.owner.type === "studio"
                ? row.original.class.owner.entity.name
                : [
                    row.original.class.owner.person.firstName,
                    row.original.class.owner.person.lastName,
                  ]
                    .filter(Boolean)
                    .join(" "),
          },
        ],
      },
    ],
    []
  );
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } =
    useTable({
      columns: columns as any,
      data,
    });

  return (
    <table
      className="w-full table-auto border border-gray-500"
      {...getTableProps()}
    >
      <thead className="bg-primary text-white">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className="p-2 border border-white"
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="bg-white" {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                return (
                  <td
                    className="p-2 border border-gray-500"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
