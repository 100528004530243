import cc from "classcat";
import menu from "assets/menu.svg";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const MENU_LINKS = [
  {
    to: "/blog",
    text: "Blog Posts",
  },
  {
    to: "/users",
    text: "Manage Users",
  },
  {
    to: "/upcoming-classes",
    text: "Upcoming Classes",
  },
  {
    to: "/tips",
    text: "Tips",
  },
];

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();

  return (
    <>
      <button
        className="relative z-10"
        onClick={() => {
          setIsOpen((current) => !current);
        }}
      >
        <img src={menu} className="w-8" alt="Menu" />
      </button>

      {isOpen && (
        <div className="z-9 fixed h-full top-0 left-0 w-72 bg-white p-4 pt-24 shadow-lg flex flex-col justify-between">
          <ul>
            {MENU_LINKS.map(({ to, text }, index) => (
              <li key={`menu-link-${index}`}>
                <Link
                  to={to}
                  className={cc([
                    "hover:bg-gray-200 block py-2 px-3",
                    {
                      "text-primary bg-gray-200 border-b border-primary":
                        pathname === to,
                    },
                  ])}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  {text}
                </Link>
              </li>
            ))}
          </ul>

          <Link to="/logout" className="p-3 block text-red-700">
            Logout
          </Link>
        </div>
      )}
    </>
  );
};

export default Menu;
