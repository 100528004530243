import Button from "components/Button";
import Container from "components/Container";
import FormField from "components/FormField";
import FormGroup from "components/FormGroup";
import { capitalize } from "lodash";
import { useFieldArray, useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import FilePicker from "utils/FilePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorMessage from "components/ErrorMessage";
import { useState } from "react";
import { useClient } from "urql";
import {
  InsertTipDocument,
  InsertTipMutation,
  UploadPictureDocument,
  UploadPictureMutation,
} from "generated/graphql";

const TipsAddEdit = () => {
  // TODO: Add Edit functionality
  // const { id } = useParams<{ id: string }>();
  // const [{ fetching, error, data }] = useBlogByPkQuery({
  //   variables: {
  //     id,
  //   },
  // });

  return (
    <Container>
      <Link to="/blog">Back</Link>
      <h1 className="font-title text-3xl my-10">New Tip</h1>
      <hr className="border-gray-500" />

      <div className="pt-10">
        <Form />
      </div>
    </Container>
  );
};

export default TipsAddEdit;

const Form = () => {
  const { control, register, handleSubmit, formState } = useForm<{
    name: string;
    goodFor: string[];
    images: { format: string; value: string }[];
  }>({
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required(),
        goodFor: yup.array().of(yup.string()).required().min(1),
        images: yup
          .array()
          .of(
            yup.object().shape({
              format: yup.string().required(),
              value: yup.string().required(),
            })
          )
          .required()
          .min(1),
      })
    ),
    defaultValues: {
      goodFor: [],
      images: [],
    },
  });
  const { fields, move, append, remove } = useFieldArray({
    control,
    name: "images",
  });
  const { errors } = formState;
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [uploading, setUploading] = useState<[number, number] | null>(null);
  const client = useClient();
  const history = useHistory();

  console.log("---errors", errors);

  return (
    <>
      <form
        onSubmit={handleSubmit(async ({ name, goodFor, images }) => {
          setFetching(true);

          try {
            const uploadPics = async (
              index = 0,
              uploadedPics: string[] = []
            ): Promise<string[]> => {
              const image = images[index];

              if (!image) {
                return uploadedPics;
              }

              setUploading([index + 1, images.length]);

              const { format, value } = image;

              const result = await client
                .mutation<UploadPictureMutation>(UploadPictureDocument, {
                  format,
                  base64String: value.split(",")[1],
                })
                .toPromise();

              if (result.error || !result.data) {
                throw new Error(result.error?.message || "Missing data.");
              }

              return uploadPics(index + 1, [
                ...uploadedPics,
                result.data.uploadPicture?.url!,
              ]);
            };

            const uploadedPics = await uploadPics();

            setUploading(null);

            const result = await client
              .mutation<InsertTipMutation>(InsertTipDocument, {
                object: {
                  name: name.trim(),
                  good_for: goodFor.join(","),
                  sources: {
                    data: uploadedPics.map((source, index) => ({
                      order: index + 1,
                      source,
                    })),
                  },
                },
              })
              .toPromise();

            if (result.error || !result.data) {
              throw new Error(result.error?.message || "Missing data.");
            }

            history.push("/tips");
          } catch (error) {
            setError(error.message);
          }

          setFetching(false);
        })}
      >
        <FormGroup>
          <h2 className="font-title text-xl">Images</h2>

          <div>
            {fields.length ? (
              <ul className="space-x-6">
                {fields.map((field, index) => (
                  <li key={field.id} className="inline-block text-center">
                    <div className="relative">
                      <img
                        src={field.value}
                        width="200"
                        alt={`Tip ${field.id}`}
                        className="border border-primary"
                      />
                      <button
                        className="absolute top-0 right-0 bg-red-500 text-white p-2 text-xs"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                    <div className="space-x-2 pt-2">
                      {index !== 0 && (
                        <Button
                          type="button"
                          $type="secondary"
                          $size="sm"
                          onClick={() => {
                            move(index, index - 1);
                          }}
                        >
                          {"<"}
                        </Button>
                      )}
                      {index !== fields.length - 1 && (
                        <Button
                          type="button"
                          $type="secondary"
                          $size="sm"
                          onClick={() => {
                            move(index, index + 1);
                          }}
                        >
                          {">"}
                        </Button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div>No images yet</div>
            )}
            {errors.images && !Array.isArray(errors.images) && (
              <ErrorMessage>{(errors.images as any).message}</ErrorMessage>
            )}
            {uploading && (
              <div className="pt-4">
                Uploading {uploading[0]} of {uploading[1]}...
              </div>
            )}
          </div>

          <Button
            disabled={fetching}
            type="button"
            $type="secondary"
            onClick={async () => {
              const newImages = await FilePicker("image/*", true);

              newImages.forEach((image) => {
                append({
                  format: image.mime,
                  value: image.base64String,
                });
              });
            }}
          >
            Add Images
          </Button>

          <FormField
            {...register("name")}
            error={errors.name?.message}
            label="Name"
            placeholder="Describe the tip"
          />

          <div>
            <ul className="space-x-8">
              {["student", "teacher", "studio"].map((type) => (
                <li className="inline-flex space-x-2" key={`good-for-${type}`}>
                  <input
                    id={`good-for-${type}`}
                    type="checkbox"
                    {...register("goodFor")}
                    value={type}
                    className="w-6 h-6"
                  />
                  <label htmlFor={`good-for-${type}`}>{capitalize(type)}</label>
                </li>
              ))}
            </ul>
            {errors.goodFor && !Array.isArray(errors.goodFor) && (
              <ErrorMessage>{(errors.goodFor as any).message}</ErrorMessage>
            )}
          </div>

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <div className="pt-8">
            <Button loading={fetching}>Add Tip</Button>
          </div>
        </FormGroup>
      </form>
    </>
  );
};
