import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import moment from "moment";
import {
  Account_Role_Enum,
  UsersQuery,
  useUsersQuery,
} from "generated/graphql";
import { useMemo, useState } from "react";
import { useTable } from "react-table";
import Container from "components/Container";
import { UseQueryState } from "urql";
import AutoCopyText from "components/AutoCopyText";

const LIMIT = 10;

const getSocialMediaDomain = (type: string) => `https://${type}.com`;

const Users = () => {
  const [limit, setLimit] = useState(LIMIT);
  const [page, setPage] = useState(0);
  const [active, setActive] = useState<null | boolean>(null);
  const [verified, setVerified] = useState<null | boolean>(null);
  const [type, setType] = useState<null | Account_Role_Enum>(null);
  const [source, setSource] = useState<null | string>(null);
  const [state] = useUsersQuery({
    // requestPolicy: "network-only",
    variables: {
      limit,
      offset: limit * page,
      active,
      verified,
      type,
      source,
    },
  });

  return (
    <>
      <div className="pb-4">
        <div className="inline-block">
          <label className="block">Active</label>
          <select
            onChange={(e) => {
              const { value } = e.target;

              if (value === "1") {
                setActive(true);
              } else if (value === "0") {
                setActive(false);
              } else {
                setActive(null);
              }
            }}
            defaultValue={active ? active.toString() : "-1"}
          >
            <option value="-1">All</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div className="inline-block pl-8">
          <label className="block">Verified</label>
          <select
            onChange={(e) => {
              const { value } = e.target;

              if (value === "1") {
                setVerified(true);
              } else if (value === "0") {
                setVerified(false);
              } else {
                setVerified(null);
              }
            }}
            defaultValue={verified ? verified.toString() : "-1"}
          >
            <option value="-1">All</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div className="inline-block pl-8">
          <label className="block">Type</label>
          <select
            onChange={(e) => {
              const { value } = e.target;

              if (value === "student") {
                setType(Account_Role_Enum.Student);
              } else if (value === "teacher") {
                setType(Account_Role_Enum.Teacher);
              } else if (value === "studio") {
                setType(Account_Role_Enum.Studio);
              } else if (value === "controller") {
                setType(Account_Role_Enum.Controller);
              } else {
                setType(null);
              }
            }}
            defaultValue={type ? type.toString() : "-1"}
          >
            <option value="-1">All</option>
            <option value="student">Student</option>
            <option value="teacher">Teacher</option>
            <option value="studio">Studio</option>
            <option value="controller">Controller</option>
          </select>
        </div>

        <div className="inline-block pl-8">
          <label className="block">Source</label>
          <select
            onChange={(e) => {
              const { value } = e.target;

              if (value === "-1") {
                setSource(null);
              } else {
                setSource(value);
              }
            }}
            defaultValue={source || "-1"}
          >
            <option value="-1">All</option>
            <option value="mobile-app">Mobile App</option>
            <option value="yogaalliance">Yoga Alliance</option>
          </select>
        </div>

        {/* <div className="inline-block pl-8">
          <label className="block">Verified</label>
          <select
            onChange={(e) => {
              const { value } = e.target;

              if (value === "1") {
                setVerified(true);
              }
              if (value === "0") {
                setVerified(false);
              }
            }}
            defaultValue={verified ? "1" : "0"}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div> */}

        <div className="inline-block pl-8">
          <label className="block">Limit</label>
          <select
            onChange={(e) => {
              const { value } = e.target;

              setLimit(parseInt(value));
            }}
            // defaultValue={verified ? "1" : "0"}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        </div>
      </div>

      <Container className="py-12 flex justify-between items-center">
        {page > 0 ? (
          <button
            className="border border-primary p-2"
            onClick={() => {
              setPage((current) => current - 1);
            }}
          >
            Prev
          </button>
        ) : (
          <div />
        )}
        <div>Page {page + 1} </div>
        {state.data?.account.length === limit ? (
          <button
            className="border border-primary p-2"
            onClick={() => {
              setPage((current) => current + 1);
            }}
          >
            Next
          </button>
        ) : (
          <div />
        )}
      </Container>

      <ListUsers state={state} />
    </>
  );
};

export default Users;

const ListUsers = ({ state }: { state: UseQueryState<UsersQuery, object> }) => {
  const { fetching, error, data } = state;

  if (fetching) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  if (!data || data.account.length === 0) {
    return <div>No accounts found</div>;
  }

  return (
    <>
      <div>Viewing: {data.account.length}</div>
      <Table data={data.account} />
    </>
  );
};

const Table = ({ data }: { data: UsersQuery["account"] }) => {
  const columns = useMemo(
    () => [
      // {
      //   Header: "",
      //   accessor: "id",
      //   Cell: ({ value }: any) => (
      //     <Link
      //       to={`/blog/${value}`}
      //       className="bg-primary text-white py-1 px-3"
      //     >
      //       Edit
      //     </Link>
      //   ),
      // },
      {
        Header: "Created at",
        accessor: "created_at",
        Cell: ({ value }: any) => moment(value).calendar(),
      },
      {
        Header: "Picture",
        Cell: ({ row }: any) => {
          const value = row.original.entity
            ? row.original.entity.picture
            : row.original.person.picture;

          return value ? (
            <div
              style={{
                width: 100,
                height: 100,
                borderRadius: 100,
                backgroundImage: `url(${value})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          ) : (
            "N/A"
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ value }: any) => <AutoCopyText>{value}</AutoCopyText>,
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Source",
        accessor: "source",
      },
      {
        Header: "Active",
        accessor: "active",
        Cell: ({ value }: any) => (value ? "✅" : "❌"),
      },
      {
        Header: "Verified",
        accessor: "verified",
        Cell: ({ value }: any) => (value ? "✅" : "❌"),
      },
      {
        Header: "Name",
        Cell: ({ row }: any) => {
          return row.original.entity
            ? row.original.entity.name
            : [row.original.person.firstName, row.original.person.lastName]
                .filter(Boolean)
                .join(" ");
        },
      },
      {
        Header: "Username",
        accessor: "username",
        Cell: ({ value }: any) =>
          value ? <AutoCopyText>{value}</AutoCopyText> : "N/A",
      },
      {
        Header: "Email",
        Cell: ({ row }: any) => {
          const value =
            row.original.entity?.email || row.original.person?.email;

          return value ? <AutoCopyText>{value}</AutoCopyText> : "N/A";
        },
      },
      {
        Header: "Phone Number",
        Cell: ({ row }: any) => {
          const value =
            row.original.entity?.phoneNumber ||
            row.original.person?.phoneNumber;

          return value ? <AutoCopyText>{value}</AutoCopyText> : "N/A";
        },
      },
      {
        Header: "Stripe Account ID",
        accessor: "stripe_account_id",
        Cell: ({ value }: any) =>
          value ? <AutoCopyText>{value}</AutoCopyText> : "N/A",
      },
      {
        Header: "Stripe Customer ID",
        accessor: "stripe_customer_id",
        Cell: ({ value }: any) =>
          value ? <AutoCopyText>{value}</AutoCopyText> : "N/A",
      },
      {
        Header: "Location",
        Cell: ({ row }) => {
          const extra = row.original.extra || {};
          return extra.location || "N/A";
        },
      },
      {
        Header: "Social Media",
        Cell: ({ row }) => {
          const extra = row.original.extra || {};
          const socialMedia = row.original.account_social_media;

          if (
            socialMedia &&
            Array.isArray(socialMedia) &&
            socialMedia.length > 0
          ) {
            return (
              <ul>
                {socialMedia
                  .filter((x) => x.url)
                  .map(({ type, url }, index) => (
                    <>
                      <a
                        href={`${getSocialMediaDomain(type)}/${url}`}
                        key={`${index}-${type}`}
                        target="_blank"
                        className="underline text-green"
                        rel="noreferrer"
                      >
                        {type}
                      </a>
                      <br />
                    </>
                  ))}
              </ul>
            );
          }

          console.log(extra);

          return "N/A";
        },
      },
      // {
      //   Header: "URL",
      //   accessor: "url",
      //   Cell: ({ value }: any) => (
      //     <a
      //       className="text-primary underline"
      //       href={value}
      //       target="_blank"
      //       rel="noreferrer"
      //     >
      //       {value}
      //     </a>
      //   ),
      // },
      // {
      //   Header: "Author",
      //   columns: [
      //     {
      //       Header: "Name",
      //       accessor: "authorName",
      //     },
      //     {
      //       Header: "Picture",
      //       accessor: "authorPicture",
      //       Cell: ({ value }: any) =>
      //         value ? (
      //           <img src={value} style={{ width: 70 }} alt="Author Picture" />
      //         ) : (
      //           "N/A"
      //         ),
      //     },
      //   ],
      // },
      // {
      //   Header: "Mins to Read",
      //   accessor: "minsToRead",
      // },
      // {
      //   Header: "Reason",
      //   accessor: "not_published_reason",
      // },
    ],
    []
  );
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } =
    useTable({
      columns: columns as any,
      data,
    });

  return (
    <table
      className="w-full table-auto border border-gray-500"
      {...getTableProps()}
    >
      <thead className="bg-primary text-white">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className="p-2 border border-white"
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="bg-white" {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                return (
                  <td
                    className="p-2 border border-gray-500"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
