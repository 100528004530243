import Header from "components/Header";
import Blog from "pages/Blog";
import BlogEdit from "pages/BlogEdit";
import Dashboard from "pages/Dashboard";
import Logout from "pages/Logout";
import NotFound from "pages/NotFound";
import Tips from "pages/Tips";
import TipsAddEdit from "pages/TipsAddEdit";
import UpcomingClasses from "pages/UpcomingClasses";
import Users from "pages/Users";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider, useAuth } from "./clients/auth.client";
import GraphqlClientProvider from "./clients/graphql.client";
import Login from "./pages/Login";
import { GlobalStoreProvider } from "./stores/global.store";

const composeProviders = (
  providers: React.FC<any>[],
  children: React.ReactElement
): React.ReactElement =>
  providers
    .reverse()
    .reduce((acc, Provider) => <Provider>{acc}</Provider>, children);

const App = () => {
  return (
    <>
      {composeProviders(
        [GlobalStoreProvider, GraphqlClientProvider, AuthProvider],
        <AppContent />
      )}
    </>
  );
};

const AppContent = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated()) {
    return <LoggedInRouter />;
  }

  return <AnonymousRouter />;
};

const AnonymousRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="*">
          <Login />
        </Route>
      </Switch>
    </Router>
  );
};

const LoggedInRouter = () => {
  return (
    <>
      <Router>
        <Header />

        <main className="px-5 py-10 m-auto">
          <Switch>
            <Route exact path="/">
              <Dashboard />
            </Route>
            <Route path="/blog/:id">
              <BlogEdit />
            </Route>
            <Route path="/blog">
              <Blog />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/upcoming-classes">
              <UpcomingClasses />
            </Route>
            <Route path="/tips/add">
              <TipsAddEdit />
            </Route>
            <Route path="/tips">
              <Tips />
            </Route>
            <Route path="/logout">
              <Logout />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </main>
      </Router>
    </>
  );
};

export default App;
