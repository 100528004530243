import { useEffect } from "react";
import { useHistory } from "react-router";
import { useGlobalStore } from "stores/global.store";

const Logout = () => {
  const [, setGlobalStore] = useGlobalStore();
  const history = useHistory();

  useEffect(() => {
    setGlobalStore(null);
    history.push("/");
  }, []);

  return <div>Logging out...</div>;
};

export default Logout;
