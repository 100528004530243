import ms from 'ms'
import { useState, useEffect } from 'react';
import {useMountedState} from 'react-use';
import {CopyToClipboard} from 'react-copy-to-clipboard';

type Props = {
  children: string
}

const AutoCopyText = ({children}: Props) => {
  const [copied, setCopied] = useState(false)
  const isMounted = useMountedState()

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        if (isMounted()) {
          setCopied(false)
        }
      }, ms('3s'))
    }
  }, [copied])

  return <span className="relative"><CopyToClipboard text={children} onCopy={() => {
    setCopied(true)
  }}>
    <span>{children}</span>
  </CopyToClipboard>

    {copied && <div className="text-sm font-bold p-1 bg-purple text-white bg-opacity-70 absolute top-1/2 right-0">copied!</div>}
  </span>
}

export default AutoCopyText