import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import moment from "moment";
import { BlogPostsQuery, useBlogPostsQuery, useBlogsTotalQuery } from "generated/graphql";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTable } from "react-table";
import Container from "components/Container";
import { UseQueryState } from "urql";

const LIMIT = 10;

const Blog = () => {
  const [limit, setLimit] = useState(LIMIT);
  const [published, setPublished] = useState(false);
  const [verified, setVerified] = useState(false);
  const [page, setPage] = useState(0);
  const [state] = useBlogPostsQuery({
    requestPolicy: "network-only",
    variables: {
      limit,
      offset: limit * page,
      where: {
        published: {
          _eq: published,
        },
        verified: {
          _eq: verified,
        },
      },
    },
  });

  return (
    <>
      <div className="pb-4">
        <div className="inline-block">
          <label className="block">Published</label>
          <select
            onChange={(e) => {
              const { value } = e.target;

              if (value === "1") {
                setPublished(true);
              }
              if (value === "0") {
                setPublished(false);
              }
            }}
            defaultValue={published ? "1" : "0"}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div className="inline-block pl-8">
          <label className="block">Verified</label>
          <select
            onChange={(e) => {
              const { value } = e.target;

              if (value === "1") {
                setVerified(true);
              }
              if (value === "0") {
                setVerified(false);
              }
            }}
            defaultValue={verified ? "1" : "0"}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div className="inline-block pl-8">
          <label className="block">Limit</label>
          <select
            onChange={(e) => {
              const { value } = e.target;

              setLimit(parseInt(value));
            }}
            defaultValue={verified ? "1" : "0"}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </select>
        </div>
      </div>

      <Container className="py-12 flex justify-between items-center">
        {page > 0 ? (
          <button
            className="border border-primary p-2"
            onClick={() => {
              setPage((current) => current - 1);
            }}
          >
            Prev
          </button>
        ) : (
          <div />
        )}
        <div>Page {page + 1} <TotalBlogs /></div>
        {state.data?.blog.length === limit ? (
          <button
            className="border border-primary p-2"
            onClick={() => {
              setPage((current) => current + 1);
            }}
          >
            Next
          </button>
        ) : (
          <div />
        )}
      </Container>

      <ListPosts state={state} />
    </>
  );
};

const ListPosts = ({
  state,
}: {
  state: UseQueryState<BlogPostsQuery, object>;
}) => {
  const { fetching, error, data } = state;

  if (fetching) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  if (!data || data.blog.length === 0) {
    return <div>No blog posts found</div>;
  }

  return (
    <>
      <div>Viewing: {data.blog.length}</div>
      <Table data={data.blog} />
    </>
  );
};

const TotalBlogs = () => {
  const [totalState] = useBlogsTotalQuery()

  if (totalState.data?.blog_aggregate.aggregate?.count != null) {
    return <span>of {Math.ceil(totalState.data.blog_aggregate.aggregate.count / LIMIT)}</span>
  }

  return null;
}

const Table = ({ data }: any) => {
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }: any) => (
          <Link
            to={`/blog/${value}`}
            className="bg-primary text-white py-1 px-3"
          >
            Edit
          </Link>
        ),
      },
      {
        Header: "Published at",
        accessor: "published_at",
        Cell: ({ value }: any) => moment(value).calendar(),
      },
      {
        Header: "Picture",
        accessor: "picture",
        Cell: ({ value }: any) =>
          value ? (
            <img src={value} style={{ width: 200 }} alt="Picture" />
          ) : (
            "N/A"
          ),
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "URL",
        accessor: "url",
        Cell: ({ value }: any) => (
          <a
            className="text-primary underline"
            href={value}
            target="_blank"
            rel="noreferrer"
          >
            {value}
          </a>
        ),
      },
      {
        Header: "Author",
        columns: [
          {
            Header: "Name",
            accessor: "authorName",
          },
          {
            Header: "Picture",
            accessor: "authorPicture",
            Cell: ({ value }: any) =>
              value ? (
                <img src={value} style={{ width: 70 }} alt="Author Picture" />
              ) : (
                "N/A"
              ),
          },
        ],
      },
      {
        Header: "Mins to Read",
        accessor: "minsToRead",
      },
      {
        Header: "Reason",
        accessor: "not_published_reason",
      },
    ],
    []
  );
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table
      className="w-full table-auto border border-gray-500"
      {...getTableProps()}
    >
      <thead className="bg-primary text-white">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className="p-2 border border-white"
                {...column.getHeaderProps()}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="bg-white" {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                return (
                  <td
                    className="p-2 border border-gray-500"
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Blog;
