import cc from "classcat";
import { FC } from "react";

type Props = {
  children: any;
  className?: string;
};

const Container = ({ children, className }: Props) => (
  <div className={cc(["max-w-screen-xl m-auto", className])}>{children}</div>
);

export default Container;

export function withContainer<P = unknown>(Component: FC<P>) {
  return (props: P) => (
    <Container>
      <Component {...props} />
    </Container>
  );
}
