import logo from "assets/logo-symbol.svg";
import Menu from "components/Menu";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="bg-white p-4">
      <div className="flex items-center">
        <Menu />

        <Link to="/" className="flex items-center z-10 ml-4">
          <img src={logo} alt="Ohmunity" className="w-12" />
          <span className="font-mono uppercase text-xl pl-2 text-primary">
            Admin
          </span>
        </Link>
      </div>
    </header>
  );
};

export default Header;
